import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import FontAwesome from "react-fontawesome";
import HeaderNotifications from "./HeaderNotifications";
import HeaderInviteUser from "./HeaderInviteUser";
import Popup from "../common/Popup";
import { findDOMNode } from "react-dom";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOpen: "",
      miniDropDownOpen: "",
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMiniMenuClick = this.onMiniMenuClick.bind(this);
  }

  componentDidMount() {
    if (document) {
      document.addEventListener("click", this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener("click", this.handleClickOutside);
    }
  }

  handleClickOutside(event) {
    if (this.state.dropDownOpen) {
      let area = findDOMNode(this.refs[this.state.dropDownOpen]);

      if (area && !area.contains(event.target)) {
        this.setState({ dropDownOpen: "", miniDropDownOpen: "" });
      }
    }
  }

  onClick(activeMenu) {
    this.setState({ dropDownOpen: activeMenu });
  }

  handleMenuItemClick(menu, event) {
    if (event) {
      //icon clicked, stop it from reopening the menu
      event.stopPropagation();
    }
    this.setState({
      dropDownOpen: this.state.dropDownOpen === menu ? "" : menu,
      miniDropDownOpen: "",
    });
  }

  onMiniMenuClick(activeMenu) {
    this.setState({ miniDropDownOpen: activeMenu });
  }

  handleMiniMenuItemClick(menu, event) {
    if (event) {
      //icon clicked, stop it from reopening the menu
      event.stopPropagation();
    }
    this.setState({
      miniDropDownOpen: this.state.miniDropDownOpen === menu ? "" : menu,
    });
  }

  render() {
    let { user, role } = this.props;
    let { dropDownOpen, miniDropDownOpen } = this.state;
    let name = user.firstName + " " + user.lastName;
    let initials = name ? name.replace(/\W*(\w)\w*/g, "$1") : "?";
    return (
      <div id="header" className="mui-row">
        <div className="sidebar-left">
          <a
            className="mobile-logo-link"
            href={process.env.REACT_APP_MAIN_SITE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/logo.png" alt="" className="header-logo" />
          </a>
          <div className="menu-large-item notifications-mobile">
            <HeaderNotifications />
          </div>
          <div className="menu-xs">
            <div
              ref="menuDropdownMobile"
              className={
                dropDownOpen === "menuDropdownMobile"
                  ? "menu-large-item header-add dropdown-active"
                  : "menu-large-item menu-add"
              }
              onClick={this.onClick.bind(null, "menuDropdownMobile")}
            >
              <FontAwesome
                name="bars"
                onClick={this.handleMenuItemClick.bind(
                  this,
                  "menuDropdownMobile",
                )}
              />
              <div className="mobile-menu-dropdown-box mui--z2">
                <div className="arrow-up"></div>
                <div className="header-dropdown-content">
                  <ul>
                    <li>
                      <Link
                        to={`/accountSettings`}
                        onClick={this.handleMenuItemClick.bind(
                          this,
                          "menuDropdownMobile",
                        )}
                      >
                        <i className="material-icons">account_circle</i>Account
                        Settings
                      </Link>
                    </li>
                    <li
                      ref="invite"
                      className={
                        miniDropDownOpen === "invite"
                          ? "invite-menu submenu-menu-active"
                          : "invite-menu"
                      }
                      onClick={this.onMiniMenuClick.bind(null, "invite")}
                    >
                      <div
                        onClick={this.handleMiniMenuItemClick.bind(
                          this,
                          "invite",
                        )}
                      >
                        <FontAwesome name="envelope" /> Invite to ZivugTech
                      </div>
                      <HeaderInviteUser
                        miniMode={true}
                        closeInvite={this.handleMiniMenuItemClick.bind(
                          this,
                          "invite",
                        )}
                      />
                    </li>
                    <li>
                      <div onClick={this.props.actions.logOut}>
                        <FontAwesome name="power-off" />
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-main">
          <div className="header-menu-main">
            <ul>
              {role === "single" && (
                <li>
                  <NavLink to={`/myResume`} activeClassName="active">
                    My Resume
                  </NavLink>
                </li>
              )}
              {role !== "admin" && (
                <li>
                  <NavLink to={`/inbox`} activeClassName="active">
                    Inbox
                    {this.props.unreadConversations.length ? (
                      <span className="count"></span>
                    ) : null}
                  </NavLink>
                </li>
              )}
              {role === "shadchan" && (
                <li>
                  <NavLink to={`/ideas`} activeClassName="active">
                    My Matches
                  </NavLink>
                </li>
              )}
              {role === "shadchan" && (
                <li>
                  <NavLink to={`/myShadchanProfile`} activeClassName="active">
                    My Shadchan Profile
                  </NavLink>
                </li>
              )}
              {role !== "shadchan" && (
                <li>
                  <NavLink to={`/Shadchanim`} activeClassName="active">
                    Shadchanim
                  </NavLink>
                </li>
              )}
              {role === "admin" && (
                <li>
                  <NavLink
                    to={`/admin/pendingShadchanim`}
                    activeClassName="active"
                  >
                    Pending Shadchanim
                  </NavLink>
                </li>
              )}
              {role === "admin" && (
                <li>
                  <NavLink to={`/admin/users`} activeClassName="active">
                    Users
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="sidebar-content">
          <div className="header-right">
            <div className="menu-large">
              <div className="menu-large-item">
                <HeaderNotifications />
              </div>
              <div
                ref="menuDropdownInvite"
                className={
                  dropDownOpen === "menuDropdownInvite"
                    ? "menu-large-item header-dropdown dropdown-active"
                    : "menu-large-item header-dropdown"
                }
                onClick={this.onClick.bind(null, "menuDropdownInvite")}
              >
                <Popup
                  trigger={
                    <FontAwesome
                      name="envelope"
                      className="header-icon header-icon-resize"
                      onClick={this.handleMenuItemClick.bind(
                        this,
                        "menuDropdownInvite",
                      )}
                    />
                  }
                  content="Spread the Zivug Zone love! Send a single or shadchan an invite to join Zivug Zone"
                />

                <div className="header-dropdown-box header-invite-dropdown-box mui--z2">
                  <div className="arrow-up"></div>
                  <div className="header-dropdown-content-header">
                    <h4 className="header-dropdown-content-title">
                      Invite to Zivug Zone
                    </h4>
                  </div>
                  <HeaderInviteUser
                    closeInvite={() => this.setState({ dropDownOpen: "" })}
                  />
                </div>
              </div>

              <div
                ref="menuDropdownAccount"
                className={
                  dropDownOpen === "menuDropdownAccount"
                    ? "menu-large-item header-dropdown dropdown-active"
                    : "menu-large-item header-dropdown"
                }
                onClick={this.onClick.bind(null, "menuDropdownAccount")}
              >
                <Popup
                  trigger={
                    <i
                      className="material-icons header-icon"
                      onClick={this.handleMenuItemClick.bind(
                        this,
                        "menuDropdownAccount",
                      )}
                    >
                      account_circle
                    </i>
                  }
                  content="Click here to view your 'Account Settings', or simple logout! "
                />

                <div ref="dropDownOpen" className="header-dropdown-box mui--z2">
                  <div className="arrow-up"></div>
                  <div className="my-account-header-section mui-row">
                    <span className="initials">{initials}</span>
                    <h3>
                      {user.firstName} {user.lastName}
                      {user.approved ? (
                        <Popup
                          trigger={<FontAwesome name="certificate" />}
                          content={`Verified User`}
                        />
                      ) : (
                        ""
                      )}
                    </h3>
                    <p className="my-account-email">{user.email}</p>
                  </div>
                  <div className="my-account-footer-section mui-row">
                    <ul onClick={this.handleMenuItemClick.bind(this, "")}>
                      <li>
                        <Link to={`/accountSettings`}>
                          <FontAwesome name="cog" /> Account Settings
                        </Link>
                      </li>
                      <li onClick={this.props.actions.logOut}>
                        <FontAwesome name="power-off" />
                        Logout
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { user, role },
    conversations: { unreadConversations },
  } = state;
  return {
    user,
    role,
    unreadConversations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
