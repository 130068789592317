import "./styles/index.scss";
import "react-widgets/dist/css/react-widgets.css";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import routes from "./routes";
import history from "./lib/history";
import { Provider } from "react-redux";
import { store } from "./store/";
import { getLoggedInUser } from "./actions/userActions";
import ModalContainer from "./components/common/ModalContainer";
import ReduxToastr from "react-redux-toastr";
import ResizeContainer from "./components/common/resizeContainer";
import axios from "axios";
import browserUpdate from "browser-update";

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (axios.isCancel(error)) {
      //if request is cancelled - do not throw error
      return Promise.resolve({ ...error, cancelled: true });
    }
    return Promise.reject(error);
  },
);

// import Raven from "raven-js";
// if (process.env.NODE_ENV === "production") {
//   Raven.config(
//     "https://e006935fcc164355aad96a7a63a96fc8@sentry.io/143845"
//   ).install();
// }

browserUpdate({
  notify: { i: -3, f: -3, o: -0.01, s: -1, c: -4 },
  insecure: true,
  unsupported: true,
  api: 5,
  reminder: 0,
  reminderClosed: 48,
});

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (axios.isCancel(error)) {
      //if request is cancelled - do not throw error
      return Promise.resolve(error);
    }
    return Promise.reject(error);
  },
);

// const store = configureStore();
store.dispatch(getLoggedInUser());

const app = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <div className="container-app">
      <ModalContainer />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />

      <ResizeContainer>
        <Router history={history}>{routes}</Router>
      </ResizeContainer>
    </div>
  </Provider>,
  app,
);

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
