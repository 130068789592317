import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminActions from "../../actions/adminActions";
const columns = [
  "first name",
  "last name",
  "email",
  "phone",
  "about",
  "start age",
  "end age",
  "reference #1",
  "reference #2",
  "approve",
  "deny",
];

function PendingShadchanim(props) {
  const { actions, pendingShadchanim } = props;

  useEffect(() => {
    actions.getPendingShadchanim();
  }, [actions]);

  const approveShadchan = id => {
    actions.approveShadchan(id);
  };

  const denyShadchan = id => {
    actions.denyShadchan(id);
  };

  return (
    <div className="shadchan-table">
      <div className="container">
        <table>
          <thead>
            <tr>
              {columns.map(c => {
                return <th key={c}>{c}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {pendingShadchanim.map(s => {
              const {
                id,
                firstName,
                lastName,
                email,
                phone,
                ageStart,
                ageEnd,
                meta,
                about,
              } = s;
              const refs = JSON.parse(meta);
              const {
                r1FirstName,
                r1LastName,
                r1Title,
                r1Country,
                r1Phone,
                r1Email,
                r2FirstName,
                r2LastName,
                r2Title,
                r2Country,
                r2Phone,
                r2Email,
              } = refs;
              return (
                <tr key={id}>
                  <td>{firstName}</td>
                  <td>{lastName}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td className="about">{about}</td>
                  <td>{ageStart}</td>
                  <td>{ageEnd}</td>
                  <td>
                    <div>{`${r1Title} ${r1FirstName} ${r1LastName}`}</div>
                    <div>{`${r1Country}`}</div>
                    <div>{`${r1Phone}`}</div>
                    <div>{`${r1Email}`}</div>
                  </td>
                  <td>
                    <div>{`${r2Title} ${r2FirstName} ${r2LastName}`}</div>
                    <div>{`${r2Country}`}</div>
                    <div>{`${r2Phone}`}</div>
                    <div>{`${r2Email}`}</div>
                  </td>
                  <td>
                    <span
                      className="approve"
                      onClick={() => approveShadchan(id)}
                    >
                      Approve
                    </span>
                  </td>
                  <td>
                    <span className="deny" onClick={() => denyShadchan(id)}>
                      Deny
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { pendingShadchanim } = state.admin;
  return {
    pendingShadchanim,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(adminActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingShadchanim);
