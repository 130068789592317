import { combineReducers } from "redux";
import modals from "./modalReducer";
import { USER_LOGOUT } from "../actionTypes/userActionTypes";
import user from "./userReducer";
import resumes from "./resumeReducer";
import conversations from "./conversationReducer";
import singleConversation from "./singleConversationReducer";
import constants from "./constantsReducer";
import notifications from "./notificationsReducer";
import settings from "./settingsReducer";
import shadchan from "./shadchanReducer";
import myMatches from "./myMatchesReducer";
import admin from "./adminReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

const appReducer = combineReducers({
  modals,
  user,
  resumes,
  conversations,
  singleConversation,
  constants,
  notifications,
  settings,
  toastr: toastrReducer,
  shadchan,
  myMatches,
  admin,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
