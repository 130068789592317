import React from "react";
import moment from "moment-timezone";
// import Popup from "../common/Popup";
import FontAwesome from "react-fontawesome";

export default class SingleConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageText: "",
      participantName: props.participantName,
      messageCounter: 0,
      replyFocused: props.replyFocused,
      viewAllMessages: false,
      errorMessageEmpty: false,
      firstMessageToDisplay: null,
      firstNewMessage: null,
    };
    this.timezone = moment.tz.guess();
  }

  componentWillReceiveProps(nextProps) {
    this.setConversationData(nextProps.conversation);

    if (nextProps.replyFocused) {
      this.setState({ replyFocused: true });
      this.refs.messageReplyArea.focus();
    }
  }

  componentDidMount() {
    this.setConversationData(this.props.conversation);
  }

  setConversationData(convo) {
    if (convo) {
      let lastReceivedMessage = 0;
      let firstNewMessage = null;

      if (convo.messages) {
        const { messages, lastViewedByMe } = convo;
        for (let i = messages.length - 3; i >= 0; i--) {
          //start from the second to last message and find the previous message received
          if (!messages[i].isMine) {
            lastReceivedMessage = i;
            break;
          }
        }

        for (let i = 0; i < messages.length; i++) {
          //look for the first unread message
          if (!messages[i].isMine && lastViewedByMe < messages[i].dateSent) {
            firstNewMessage = messages[i].id;
            break;
          }
        }
      }

      this.setState({
        participantName: convo.participantName,
        firstMessageToDisplay: lastReceivedMessage,
        firstNewMessage: firstNewMessage,
      });
    }
  }

  viewAll() {
    this.setState({ viewAllMessages: true });
  }

  createMessage = () => {
    if (this.refs.replyBox) {
      this.setState({ replyFocused: true });
    }
  };

  cancelFocus() {
    if (this.state.errorMessageEmpty) {
      this.setState({ errorMessageEmpty: false });
    }

    if (this.refs.replyBox) {
      this.setState({ replyFocused: false });
    }

    if (this.props.unfocusReply) {
      this.props.unfocusReply();
    }
  }

  messageTextChanged = e => {
    if (this.state.errorMessageEmpty) {
      this.setState({ errorMessageEmpty: false });
    }
    this.setState({ messageText: e.target.value });
  };

  handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage();
    }
  };

  sendMessage() {
    const text = this.state.messageText;
    if (!text) this.setState({ errorMessageEmpty: true });
    else {
      this.setState({ messageText: "", replyFocused: false });
      this.props.sendMessage({
        text,
        conversationId: this.props.conversation.id,
      });
    }
  }

  messagesRow(message) {
    let date = moment.tz(message.dateSent, this.timezone).fromNow();
    return (
      <div key={message.id}>
        {message.id === this.state.firstNewMessage ? (
          <div className="inbox-new-messages-container">
            <div className="inbox-new-messages">
              <p>new messages</p>
            </div>
          </div>
        ) : null}
        <div
          className={
            message.isMine
              ? "inbox-message inbox-message-mine"
              : "inbox-message"
          }
        >
          <div className="mui-row">
            <div className="mui-col-md-12">
              {message.isMine ? (
                ""
              ) : (
                <p className="message-sender convo-participant">
                  {this.state.participantName}
                </p>
              )}
            </div>
            <div className="mui-col-md-12">
              <p className="inbox-message-content">{message.text}</p>
            </div>
            <div className="mui-col-md-12">
              <p className="time-received">{date}</p>
            </div>
          </div>
          <div className="mui-row"></div>
        </div>
      </div>
    );
  }

  render() {
    let convo = this.props.conversation;

    return (
      <div className="inbox-container-max">
        <div className="inbox-header content-padding"></div>
        <div className="content-padding">
          <div className="inbox-messages">
            {!this.state.viewAllMessages &&
            convo.messages.length > 2 &&
            this.state.firstMessageToDisplay ? (
              <div className="inbox-messages-older">
                <p onClick={this.viewAll.bind(this)}>
                  View
                  <span className="circle">
                    {this.state.firstMessageToDisplay}
                  </span>{" "}
                  older message
                  {this.state.firstMessageToDisplay > 1 ? (
                    <span>s</span>
                  ) : null}{" "}
                  <FontAwesome name="arrow-right" />
                </p>
              </div>
            ) : null}
            {convo.messages.map((m, i) => {
              if (
                this.state.viewAllMessages ||
                i >= this.state.firstMessageToDisplay
              ) {
                return this.messagesRow(m);
              }
              return null;
            })}
          </div>
        </div>
        <div className="message-reply" ref="replyBox">
          <div className="content-padding">
            {this.state.replyFocused ? (
              <div className="message-reply-header">
                <p>
                  Replying to{" "}
                  <span className="message-reply-header-name">
                    {convo.participantName}
                  </span>
                </p>
              </div>
            ) : null}

            <textarea
              ref="messageReplyArea"
              value={this.state.messageText}
              placeholder="Type to reply..."
              onFocus={this.createMessage}
              onChange={this.messageTextChanged}
              onKeyDown={this.handleKeyPress}
              className="form-textarea"
            ></textarea>
            {this.state.errorMessageEmpty ? (
              <span className="form-error-message">
                Please enter a message...
              </span>
            ) : (
              ""
            )}

            {this.state.replyFocused ? (
              <div className="message-reply-footer">
                <button
                  onClick={this.cancelFocus.bind(this)}
                  className="btn-flat btn-reset"
                >
                  Cancel
                </button>
                <button
                  onClick={this.sendMessage.bind(this)}
                  className="btn-flat btn-success"
                >
                  Send
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
