import * as types from "../actionTypes/myMatchesActionTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function getMyIdeas() {
  return async dispatch => {
    let result = await apiAuthRequest("get", "/myMatches/getMyIdeas");
    if (result.success) {
      dispatch({
        type: types.GET_MY_IDEAS_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function sendIdea(idea) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/myMatches/sendIdea", idea);
    if (result.success) {
      dispatch({
        type: types.GET_MY_IDEAS_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function createIdea(idea) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/myMatches/createIdea", idea);
    if (result.success) {
      dispatch({
        type: types.CREATE_IDEA_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function editIdea(idea) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/myMatches/editIdea", idea);
    if (result.success) {
      dispatch({
        type: types.EDIT_IDEA_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function deleteIdea(ideaId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/myMatches/archiveIdea", {
      id: ideaId,
    });
    if (result.success) {
      dispatch({
        type: types.DELETE_IDEA_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function rejectIdea(ideaInfo) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "post",
      "/myMatches/rejectIdea",
      ideaInfo,
    );
    if (result.success) {
      dispatch({
        type: types.REJECT_IDEA_SUCCESS,
        conversation: result.data,
      });
      return true;
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function socketIoIdeaUpdate(ideas, dispatch) {
  dispatch({ type: types.SOCKET_IO_IDEA_UPDATE, ideas });
}

export function acceptIdea(ideaInfo) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "post",
      "/myMatches/acceptIdea",
      ideaInfo,
    );
    if (result.success) {
      dispatch({
        type: types.ACCEPT_IDEA_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
