import FlagModal from "./flagModal";
import IdeaModal from "../myMatches/ideaModal";
import ResumeModal from "../myMatches/ResumeModal";
import InboxResumeModal from "../inbox/InboxResumeModal";
import MemberResumeModal from "../shadchanim/components/MemberResumeModal";

export default {
  FLAG: FlagModal,
  IDEA: IdeaModal,
  RESUME: ResumeModal,
  INBOX_RESUME: InboxResumeModal,
  MEMBER_RESUME_MODAL: MemberResumeModal,
};
