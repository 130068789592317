export function convertToString(Constants, int) {
  if (Constants) {
    let result = Constants.find(item => {
      return item.id === parseInt(int);
    });
    if (result) {
      return result.text;
    }
  }
  return "";
}

export function heightToString(height) {
  let feet = Math.floor(height / 12);
  let inches = height % 12;
  return feet + "'" + inches;
}

export default {
  convertToString,
  heightToString,
};
