import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import * as userActions from "../../../actions/userActions";
import * as constActions from "../../../actions/constsActions";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Form from "react-formal";
import SelectList from "react-widgets/lib/SelectList";
import LocationInput from "../../common/locationInput";
import { Helmet } from "react-helmet";
import MultiSelect from "react-widgets/lib/Multiselect";
import _ from "lodash";
import { validPhoneRegex } from "../../../zivugZoneUtils";
import { ContactUsBanner } from "../ContactUsBanner";
const baseURL = process.env.REACT_APP_API_URL;

const registerSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  gender: yup.mixed().required("Required"),
  languages: yup.array().of(yup.object()),
  shadchanEthnicityId: yup.number().required("Required"),
  shadchanTypeId: yup.number().required("Required"),
  phone: yup
    .string()
    .matches(validPhoneRegex, "Phone number is not valid")
    .required("Required"),
  location: yup.string(),
  email: yup
    .string()
    .email("Invalid email")
    .required("Required")
    .test("userExists", "User with this email already exists.", function(
      email,
    ) {
      return axios
        .get(`${baseURL}/users/checkUserExists`, { params: { email } })
        .then(response => !response.data);
    }),
  password: yup
    .string()
    .required("Required")
    .min(6, "Password is too short"),
  confirmPassword: yup
    .mixed()
    .test("match", "Passwords do not match", function(confirmPassword) {
      return confirmPassword === this.parent.password;
    }),
  specialties: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      text: yup.string(),
    }),
  ),
  types: yup.array().of(yup.object()),
  ageStart: yup.number(),
  ageEnd: yup.number(),
  r1FirstName: yup.string().required("Required"),
  r1LastName: yup.string().required("Required"),
  r1Title: yup.string().required("Required"),
  r1Email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  r1Country: yup.string().required("Required"),
  r1Phone: yup.string().required("Required"),
  r2FirstName: yup.string().required("Required"),
  r2LastName: yup.string().required("Required"),
  r2Title: yup.string().required("Required"),
  r2Email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  r2Country: yup.string().required("Required"),
  r2Phone: yup.string().required("Required"),
});

class ShadchanRegistrationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: {
        firstName: "",
        lastName: "",
        gender: null,
        email: "",
        password: "",
        phone: "",
        location: "",
        confirmPassword: "",
        languages: [],
        types: [],
        specialties: [],
      },
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.constActions.loadConsts();
  }

  componentWillUnmount() {
    this.props.actions.clearRegisterPage();
  }

  handleChange(model) {
    this.setState({ newUser: model, submitted: false });
  }

  submit(values) {
    this.setState({ submitted: true });
    delete values.confirmPassword;
    this.props.actions.registerShadchanUser(values);
  }

  resendConfirmationEmail() {
    this.setState({ resentConfirmationEmail: true });
    this.props.actions.resendConfirmationEmail(
      this.props.user.unconfirmedUserId,
    );
  }

  render() {
    const {
      user: { submitting, errorMessage, isRegistered, confirmationResent },
      constants,
    } = this.props;

    if (isRegistered) {
      return (
        <Fragment>
          <div className="registration-page">
            <div className="registration-container">
              <Link to={"/login"}>
                <img
                  src="/images/login-logo.png"
                  alt=""
                  className="page-account-logo"
                />
              </Link>

              <div id="form" className="registration-content mui--z1">
                <h3>Welcome to Zivug Zone</h3>
                <p>We sent an email to {this.state.newUser.email}</p>
                <p>
                  Please click the 'confirm' link in that email to continue.
                </p>
                <p className="margin-bottom">
                  If you've already confirmed your email,{" "}
                  <Link to={`login`}>click here</Link> to login
                </p>

                <span
                  onClick={this.resendConfirmationEmail.bind(this)}
                  className="btn btn-flat btn-cancel btn-confirmation"
                >
                  Resend the email
                </span>

                {this.state.resentConfirmationEmail && confirmationResent && (
                  <p className="form-message-bottom text-align-center">
                    Confirmation resent. Please check your email
                  </p>
                )}
              </div>
            </div>
          </div>
          <ContactUsBanner />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="registration-page">
          <Helmet>
            <title>Register | Zivug Zone</title>
          </Helmet>
          <div className="parallax">
            <div className="registration-container">
              <Link to={"/login"}>
                <img
                  src="/images/login-logo.png"
                  alt=""
                  className="page-account-logo"
                />
              </Link>

              <Form
                onSubmit={this.submit}
                id="form"
                schema={registerSchema}
                value={this.state.newUser}
                onChange={this.handleChange}
                className="registration-content mui--z1"
              >
                <h3>Personal Info</h3>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      First Name
                      <Form.Message className="has-error" for="firstName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="firstName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      Last Name
                      <Form.Message className="has-error" for="lastName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="lastName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>

                <div className="container">
                  <div className="form-inline-half phone">
                    <legend>
                      Mobile Phone
                      <Form.Message className="has-error" for="phone" />
                    </legend>
                    <Form.Field
                      name="phone"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half register-radios">
                    <legend>
                      Gender
                      <Form.Message className="has-error" for="gender" />
                    </legend>
                    <Form.Field
                      as={SelectList}
                      data={[
                        { id: 1, text: "Male" },
                        { id: 2, text: "Female" },
                      ]}
                      mapFromValue={gender => gender.id}
                      valueField="id"
                      textField="text"
                      name="gender"
                      className="form-input-margin-bottom form-inline inline-radio"
                    />
                  </div>
                </div>
                <div className="container form-input-margin-bottom clear">
                  <legend>
                    Location
                    <Form.Message className="has-error" for="location" />
                  </legend>
                  <Form.Field
                    name="location"
                    placeholder="City, State"
                    className="form-input-margin-bottom"
                    as={LocationInput}
                  />
                </div>
                <div className="container">
                  <legend>
                    Email
                    <Form.Message className="has-error" for="email" />
                  </legend>
                  <Form.Field
                    placeholder="Email"
                    name="email"
                    className="form-input-margin-bottom"
                  />
                  <div className="container">
                    <legend>
                      Languages{" "}
                      <Form.Message className="has-error" for="languages" />
                    </legend>
                    <Form.Field
                      as={MultiSelect}
                      className="form-input-margin-bottom"
                      data={constants.languages}
                      valueField="id"
                      textField="text"
                      name="languages"
                    />
                  </div>

                  <div className="container">
                    <legend>
                      Ethnicity
                      <Form.Message
                        className="has-error"
                        for="shadchanEthnicityId"
                      />
                    </legend>
                    <Form.Field
                      as={SelectList}
                      data={constants.ethnicity}
                      mapFromValue={item => item.id}
                      valueField="id"
                      textField="text"
                      name="shadchanEthnicityId"
                      className="form-input-margin-bottom form-inline inline-radio radioBtn"
                    />
                  </div>

                  <div className="container">
                    <legend>
                      Religious Background
                      <Form.Message
                        className="has-error"
                        for="shadchanTypeId"
                      />
                    </legend>
                    <Form.Field
                      as={SelectList}
                      data={constants.types}
                      mapFromValue={item => item.id}
                      valueField="id"
                      textField="text"
                      name="shadchanTypeId"
                      className="form-input-margin-bottom form-inline inline-radio"
                    />
                  </div>

                  <div className="form-inline-half">
                    <legend>
                      Password
                      <Form.Message className="has-error" for="password" />
                    </legend>
                    <Form.Field
                      placeholder="Password"
                      type="password"
                      validates={
                        this.state.newUser.confirmPassword
                          ? ["confirmPassword"]
                          : []
                      }
                      name="password"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      Confirm password
                      <Form.Message
                        className="has-error"
                        for="confirmPassword"
                      />
                    </legend>
                    <Form.Field
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>

                <h3>specialties</h3>
                <div className="container">
                  <legend>
                    Types
                    <Form.Message className="has-error" for="types" />
                  </legend>
                  <Form.Field
                    style={{ height: "42px" }}
                    as={MultiSelect}
                    className="form-input-margin-bottom"
                    data={constants.types}
                    valueField="id"
                    textField="text"
                    name="types"
                  />
                </div>
                <div className="container">
                  <legend>
                    Specialties
                    <Form.Message className="has-error" for="specialties" />
                  </legend>
                  <Form.Field
                    style={{ height: "42px" }}
                    as={MultiSelect}
                    data={constants.specialties}
                    valueField="id"
                    textField="text"
                    name="specialties"
                    className="form-input-margin-bottom"
                  />
                </div>

                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      age range start
                      <Form.Message className="has-error" for="ageStart" />
                    </legend>
                    <Form.Field
                      style={{ height: "42px" }}
                      name="ageStart"
                      className="form-input-margin-bottom"
                      as="select"
                    >
                      <option value="">Please choose a start age</option>
                      {_.range(18, 99).map(x => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Field>
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      age range end
                      <Form.Message className="has-error" for="ageEnd" />
                    </legend>
                    <Form.Field
                      style={{ height: "42px" }}
                      name="ageEnd"
                      className="form-input-margin-bottom"
                      as="select"
                    >
                      <option value="">Please choose a end age</option>
                      {_.range(18, 99).map(x => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Field>
                  </div>
                </div>
                <h3>References</h3>
                <h4>Reference #1</h4>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      First Name
                      <Form.Message className="has-error" for="r1FirstName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r1FirstName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      Last Name
                      <Form.Message className="has-error" for="r1LastName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r1LastName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      Title
                      <Form.Message className="has-error" for="r1Title" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r1Title"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      country
                      <Form.Message className="has-error" for="r1Country" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r1Country"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      phone
                      <Form.Message className="has-error" for="r1Phone" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r1Phone"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      email
                      <Form.Message className="has-error" for="r1Email" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r1Email"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>

                <h4>Reference #2</h4>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      First Name
                      <Form.Message className="has-error" for="r2FirstName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r2FirstName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      Last Name
                      <Form.Message className="has-error" for="r2LastName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r2LastName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      Title
                      <Form.Message className="has-error" for="r2Title" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r2Title"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      country
                      <Form.Message className="has-error" for="r2Country" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r2Country"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      phone
                      <Form.Message className="has-error" for="r2Phone" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r2Phone"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      email
                      <Form.Message className="has-error" for="r2Email" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="r2Email"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>

                {errorMessage && this.state.submitted && (
                  <span className="has-error">{errorMessage}</span>
                )}

                {(this.state.submitted && !errorMessage) || submitting ? (
                  <div className="btn-flat btn-primaryAccent text-align-center btn-loader">
                    <div className="loader-small"></div>
                  </div>
                ) : (
                  <Form.Submit className="btn-flat btn-primaryAccent btn-loader text-align-center">
                    Register Now
                  </Form.Submit>
                )}

                <p className="account-terms">
                  By clicking "Register" I agree to Zivug Zone's{" "}
                  <Link to={`terms`} className="account-terms-link">
                    Terms and Conditions.
                  </Link>
                </p>

                <Link to={`login`} className="login-link">
                  Already have an account? Login
                </Link>
              </Form>
            </div>
          </div>
        </div>
        <ContactUsBanner />
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    constActions: bindActionCreators(constActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    constants: state.constants,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShadchanRegistrationPage);
