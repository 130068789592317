import * as types from "../actionTypes/shadchanActionTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function getShadchanById(id) {
  return async dispatch => {
    dispatch({ type: types.GET_SHADCHAN_START });
    let result = await apiAuthRequest("get", "/shadchanim/getShadchanById", {
      id,
    });
    if (result.success) {
      dispatch({
        type: types.GET_SHADCHAN_SUCCESS,
        shadchan: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function clearSelectedShadchan() {
  return { type: types.CLEAR_SELECTED_SHADCHAN };
}

export function loadShadchanim(params) {
  return async dispatch => {
    dispatch({ type: types.LOAD_SHADCHANIM_START, search: params });

    let result = await apiAuthRequest(
      "get",
      "/shadchanim/getAllShadchanim",
      params,
    );
    if (result.success) {
      dispatch({
        type: types.LOAD_SHADCHANIM_SUCCESS,
        shadchanim: result.data,
      });
    } else {
      // dispatch({
      //   type: types.LOAD_SHADCHANIM_FAIL,
      //   errorMessage: result.data
      // });
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function getMyShadchanProfile() {
  return async dispatch => {
    dispatch({ type: types.GET_MY_SHADCHAN_PROFILE_START });
    const result = await apiAuthRequest(
      "get",
      "/shadchanim/getMyShadchanProfile",
    );
    if (result.success) {
      dispatch({
        type: types.GET_MY_SHADCHAN_PROFILE_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function editShadchanProfile(info) {
  return async dispatch => {
    //dispatch({ type: types.GET_MY_SHADCHAN_PROFILE_START });
    const result = await apiAuthRequest(
      "post",
      "/shadchanim/editMyShadchanProfile",
      info,
    );
    if (result.success) {
      return true;
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function acceptMemberRequest(memberId) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "post",
      "/shadchanim/acceptMemberRequest",
      { id: memberId },
    );
    if (result.success) {
      dispatch({
        type: types.UPDATE_SHADCHAN_MEMBERS_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function declineMemberRequest(memberId) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "post",
      "/shadchanim/declineMemberRequest",
      { id: memberId },
    );
    if (result.success) {
      dispatch({
        type: types.UPDATE_SHADCHAN_MEMBERS_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function removeMember(memberId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/shadchanim/removeMember", {
      id: memberId,
    });
    if (result.success) {
      dispatch({
        type: types.UPDATE_ACCEPTED_SHADCHAN_MEMBERS_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function requestMembership(shadchanId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/shadchanim/requestMembership", {
      id: shadchanId,
    });
    if (result.success) {
      dispatch({
        type: types.GET_SHADCHAN_SUCCESS,
        shadchan: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function adminGetShadchanMembers(shadchanId) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "post",
      "/shadchanim/adminGetShadchanMembers",
      { id: shadchanId },
    );
    if (result.success) {
      dispatch({
        type: types.UPDATE_SHADCHAN_MEMBERS_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function socketIoRequestedMembersUpdate(requestedMembers, dispatch) {
  dispatch({
    type: types.SOCKET_IO_REQUESTED_MEMBERS_UPDATE,
    requestedMembers,
  });
}
