import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as userActions from "../../../actions/userActions";
import * as yup from "yup";
import Form from "react-formal";
import { Helmet } from "react-helmet";
import { getNextUrlFromParams } from "../../common/utils";
import { getRedirectUrl } from "../../../zivugZoneUtils";
import { ContactUsBanner } from "../ContactUsBanner";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .transform(string => string.trim())
    .email("Invalid email")
    .required("Required"),
  password: yup.string().required("Required"),
});

class _LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      login: {},
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.checkAuth(this.props);
  }

  componentDidUpdate(prevProps) {
    const user = this.props.user;
    if (user !== prevProps.user && user.isAuthenticated) {
      this.checkAuth(this.props);
    }
  }

  checkAuth(props) {
    const { isAuthenticated, role, hasResume } = props.user;
    if (isAuthenticated) {
      this.setState({ submitted: true });
      let nextRoute = getNextUrlFromParams(props.location.search);
      this.props.history.push(getRedirectUrl(nextRoute, role, hasResume));
    }
  }

  handleChange(model) {
    this.setState({ login: model, submitted: false });
  }

  submit(values) {
    this.setState({ submitted: true });
    let nextPath = getNextUrlFromParams(this.props.location.search);
    let nextRoute = nextPath ? "/" + nextPath : null;
    this.props.actions.loginUser(values, nextRoute);
  }

  resendConfirmationEmail() {
    this.setState({ resentConfirmationEmail: true });
    this.props.actions.resendConfirmationEmail(
      this.props.user.unconfirmedUserId,
    );
  }

  render() {
    let {
      isLoggingIn,
      errorMessage,
      unconfirmedUserId,
      confirmationResent,
      userConfirmed,
    } = this.props.user;
    return (
      <Fragment>
        <div className="page-account">
          <Helmet>
            <title>Login | Zivug Zone</title>
          </Helmet>
          <img src="/images/BSD.png" alt="" className="bsd-image" />
          <div className="page-account-container">
            <Link to={"/login"}>
              <img
                src="/images/login-logo.png"
                alt=""
                className="page-account-logo"
              />
            </Link>

            {userConfirmed ? (
              <div className="account-message">User confirmed successfully</div>
            ) : null}

            <Form
              onSubmit={this.submit}
              id="form"
              schema={loginSchema}
              value={this.state.login}
              onChange={this.handleChange}
              className="page-account-content mui--z1"
            >
              <h3>Login</h3>

              <legend>
                Email
                <Form.Message className="has-error" for="email" />
              </legend>
              <Form.Field
                placeholder="Email"
                name="email"
                className="form-input-margin-bottom"
              />
              <legend>
                Password
                <Form.Message className="has-error" for="password" />
              </legend>
              <Form.Field
                placeholder="Password"
                type="password"
                name="password"
                className="form-input-margin-bottom"
              />
              <p className="page-account-text-link-under-input text-align-right">
                <Link to={`forgotPassword`}>Forgot Password?</Link>
              </p>

              {isLoggingIn ? (
                <div className="btn-flat btn-primaryAccent text-align-center btn-loader">
                  <div className="loader-small"></div>
                </div>
              ) : (
                <Form.Submit
                  type="submit"
                  className="btn-flat btn-primaryAccent btn-loader text-align-center"
                >
                  Login
                </Form.Submit>
              )}
              {errorMessage && this.state.submitted && (
                <p className="form-message-bottom text-align-center">
                  {errorMessage}
                  {unconfirmedUserId && !confirmationResent ? (
                    <span
                      onClick={this.resendConfirmationEmail.bind(this)}
                      className="form-message-bottom-resend"
                    >
                      Click here to resend the email
                    </span>
                  ) : null}
                </p>
              )}
              {this.state.resentConfirmationEmail && confirmationResent && (
                <p className="form-message-bottom text-align-center">
                  Confirmation resent. Please check your email
                </p>
              )}

              <Link to={"/register"} className="register-link">
                Not a user yet? Register Here
              </Link>
            </Form>
            <div className="s-register-wrapper">
              <Link
                to="/shadchanRegistration"
                className="shadchan-register-link"
              >
                Shadchan Account
              </Link>
            </div>

            {/* <div className="powered-by">
            <a
              href={"https://bitbean.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                Built with <FontAwesome name="heart" /> by{" "}
              </span>{" "}
              bitbean.com
            </a>
          </div> */}
          </div>
        </div>
        <ContactUsBanner />
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_LoginPage);
