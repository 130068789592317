import * as types from "../actionTypes/userActionTypes";
import history from "../lib/history";
import { loadConsts } from "./constsActions";
import { loadNotifications } from "./notificationActions";
import { loadUnreadConversations } from "./inboxPageActions";
import cookie from "cookie";
import { apiRequest, apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";
import { getRedirectUrl } from "../zivugZoneUtils";
import { setupSocketConnection, closeConnection } from "../socketIO";

export function registerSuccess(unconfirmedUserId) {
  return {
    type: types.REGISTER_USER_SUCCESS,
    unconfirmedUserId,
  };
}

export function userNotLoggedIn() {
  return {
    type: types.USER_NOT_LOGGED_IN,
  };
}

export function confirmUserSuccess() {
  return {
    type: types.CONFIRM_USER_SUCCESS,
  };
}

export function loginUser(loginInfo, nextRoute) {
  return async dispatch => {
    dispatch({ type: types.USER_LOGIN_START });

    let result = await apiRequest("post", "/users/login", {
      email: loginInfo.email,
      password: loginInfo.password,
    });
    if (result.success && result.data.success) {
      const {
        user,
        user: { role, hasResume },
        token,
      } = result.data;
      updateCookie(token, 2);
      dispatch({ type: types.USER_LOGIN_SUCCESS, user });
      getUserData(dispatch, token);
      history.push(getRedirectUrl(nextRoute, role, hasResume));
    } else if (result.success) {
      dispatch({
        type: types.USER_LOGIN_ERROR,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function getLoggedInUser() {
  return async dispatch => {
    let token = cookie.parse(document.cookie).zivugtechToken;
    if (!token) {
      return dispatch(userNotLoggedIn());
    }

    let result = await apiAuthRequest("get", "/users/getCurrentUser");
    if (result.success) {
      dispatch({ type: types.USER_LOGIN_SUCCESS, user: result.data });
      getUserData(dispatch, token);
      updateCookie(token, 2);
    } else {
      console.error(result.data);
      toastr.error(result.data);
      updateCookie("", -1);
      dispatch(userNotLoggedIn());
    }
  };
}

export function getUserData(dispatch, token) {
  dispatch(loadConsts());
  dispatch(loadUnreadConversations());
  dispatch(loadNotifications());
  setupSocketConnection(token);
}

export function registerUser(newUser) {
  return async dispatch => {
    dispatch({ type: types.REGISTER_USER_START });
    let result = await apiRequest("post", "/users/register", newUser);
    if (result.success) {
      dispatch(registerSuccess(result.data.unconfirmedUserId));
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.REGISTER_USER_ERROR,
        errorMessage: result.data,
      });
    }
  };
}

export function registerShadchanUser(newUser) {
  return async dispatch => {
    dispatch({ type: types.REGISTER_USER_START });
    let result = await apiRequest("post", "/users/shadchanRegister", newUser);
    if (result.success) {
      dispatch(registerSuccess(result.data.unconfirmedUserId));
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.REGISTER_USER_ERROR,
        errorMessage: result.data,
      });
    }
  };
}
//shadchanRegister

export function clearRegisterPage() {
  return { type: types.REGISTER_USER_DONE };
}

export function confirmUser(params) {
  return async dispatch => {
    let result = await apiRequest("post", "/users/confirmUser", params);
    if (result.success && result.data.confirmed) {
      dispatch(confirmUserSuccess());
      setTimeout(() => history.push("/login"), 1000);
    } else {
      console.error(result.data);
      toastr.error(result.data ? result.data.message : "unknown error");
      dispatch({
        type: types.CONFIRM_USER_FAIL,
        data: result.data,
      });
    }
  };
}

export function resendConfirmationEmail(userId) {
  return async dispatch => {
    let result = await apiRequest("post", "/users/resendConfirmationEmail", {
      userId,
    });
    if (result.success) {
      dispatch({
        type: types.RESEND_CONFIRMATION_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.CONFIRM_USER_FAIL,
        data: result.data,
      });
    }
  };
}

export function updateUser(params) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/users/editUser", params);
    if (result.success) {
      dispatch({
        type: types.ACCOUNT_SETTINGS_SAVED,
        user: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.ACCOUNT_SETTINGS_ERROR,
        errorMessage: result.data,
      });
    }
  };
}

export function updatePassword(params) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/users/changePassword", params);
    if (result.success) {
      dispatch({ type: types.SECURITY_SETTINGS_SAVED });
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.SECURITY_SETTINGS_ERROR,
        errorMessage: result.data,
      });
    }
  };
}

export function addEmail(params) {
  //email, password
  return async dispatch => {
    let result = await apiAuthRequest("post", "/users/addEmail", params);
    if (result.success) {
      dispatch({
        type: types.ADD_USER_EMAIL_SUCCESS,
        userEmail: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.ADD_USER_EMAIL_FAIL,
        errorMessage: result.data,
      });
    }
  };
}

export function removeEmail(userEmailId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/users/removeEmail", {
      id: userEmailId,
    });
    if (result.success) {
      dispatch({
        type: types.REMOVE_USER_EMAIL_SUCCESS,
        userEmailId: userEmailId,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.REMOVE_USER_EMAIL_FAIL,
        errorMessage: result.data,
      });
    }
  };
}

export function resendEmailVerification(userEmailId) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "post",
      "/users/resendEmailVerification",
      { userEmailId },
    );
    if (result.success) {
      dispatch({
        type: types.RESEND_EMAIL_VERIFICATION_SUCCESS,
        userEmailId: userEmailId,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
      dispatch({
        type: types.RESEND_EMAIL_VERIFICATION_FAIL,
        errorMessage: result.data,
      });
    }
  };
}

export function inviteUser(email) {
  return async dispatch => {
    dispatch({ type: types.INVITE_USER_START });
    let result = await apiAuthRequest("post", "/users/inviteUser", { email });
    if (result.success) {
      setTimeout(() => dispatch({ type: types.INVITE_USER_SUCCESS }), 3000);
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function updateCookie(value, hours) {
  let expires = "";
  if (hours) {
    let date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = "zivugtechToken=" + value + expires + "; path=/";
}

export function logOut() {
  history.push("/login");
  closeConnection();
  updateCookie("", -1);
  return {
    type: types.USER_LOGOUT,
  };
}
