import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import * as userActions from "../../../actions/userActions";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Form from "react-formal";
import SelectList from "react-widgets/lib/SelectList";
import LocationInput from "../../common/locationInput";
import { Helmet } from "react-helmet";
import { validPhoneRegex } from "../../../zivugZoneUtils";
import { ContactUsBanner } from "../ContactUsBanner";
const baseURL = process.env.REACT_APP_API_URL;

const registerSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  gender: yup.mixed().required("Required"),
  phone: yup
    .string()
    .matches(validPhoneRegex, "Phone number is not valid")
    .required("Required"),
  location: yup.string(),
  email: yup
    .string()
    .email("Invalid email")
    .required("Required")
    .test("userExists", "User with this email already exists.", function(
      email,
    ) {
      return axios
        .get(`${baseURL}/users/checkUserExists`, { params: { email } })
        .then(response => !response.data);
    }),
  password: yup
    .string()
    .required("Required")
    .min(6, "Password is too short"),
  confirmPassword: yup
    .mixed()
    .test("match", "Passwords do not match", function(confirmPassword) {
      return confirmPassword === this.parent.password;
    }),
});

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: {
        firstName: "",
        lastName: "",
        gender: null,
        email: "",
        password: "",
        phone: "",
        location: "",
        confirmPassword: "",
      },
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.clearRegisterPage();
  }

  handleChange(model) {
    this.setState({ newUser: model, submitted: false });
  }

  submit(values) {
    this.setState({ submitted: true });
    delete values.confirmPassword;
    this.props.actions.registerUser(values);
  }

  resendConfirmationEmail() {
    this.setState({ resentConfirmationEmail: true });
    this.props.actions.resendConfirmationEmail(
      this.props.user.unconfirmedUserId,
    );
  }

  render() {
    let {
      submitting,
      errorMessage,
      isRegistered,
      confirmationResent,
    } = this.props.user;

    if (isRegistered) {
      return (
        <Fragment>
          <div className="registration-page">
            <div className="parallax">
              <div className="registration-container">
                <Link to={"/login"}>
                  <img
                    src="/images/login-logo.png"
                    alt=""
                    className="page-account-logo"
                  />
                </Link>

                <div id="form" className="registration-content mui--z1">
                  <h3>Welcome to Zivug Zone</h3>
                  <p>We sent an email to {this.state.newUser.email}</p>
                  <p>
                    Please click the 'confirm' link in that email to continue.
                  </p>
                  <p className="margin-bottom">
                    If you've already confirmed your email,{" "}
                    <Link to={`login`}>click here</Link> to login
                  </p>

                  <span
                    onClick={this.resendConfirmationEmail.bind(this)}
                    className="btn btn-flat btn-cancel btn-confirmation"
                  >
                    Resend the email
                  </span>

                  {this.state.resentConfirmationEmail && confirmationResent && (
                    <p className="form-message-bottom text-align-center">
                      Confirmation resent. Please check your email
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ContactUsBanner />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="registration-page">
          <Helmet>
            <title>Register | Zivug Zone</title>
          </Helmet>
          <div className="parallax">
            <div className="registration-container">
              <Link to={"/login"}>
                <img
                  src="/images/login-logo.png"
                  alt=""
                  className="page-account-logo"
                />
              </Link>

              <Form
                onSubmit={this.submit}
                id="form"
                schema={registerSchema}
                value={this.state.newUser}
                onChange={this.handleChange}
                className="registration-content mui--z1"
              >
                <h3>Register Account</h3>
                <div className="container">
                  <div className="form-inline-half">
                    <legend>
                      First Name
                      <Form.Message className="has-error" for="firstName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="firstName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      Last Name
                      <Form.Message className="has-error" for="lastName" />
                    </legend>
                    <Form.Field
                      placeholder=""
                      name="lastName"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>

                <div className="container">
                  <div className="form-inline-half phone">
                    <legend>
                      Mobile Phone
                      <Form.Message className="has-error" for="phone" />
                    </legend>
                    <Form.Field
                      name="phone"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half register-radios">
                    <legend>
                      Gender
                      <Form.Message className="has-error" for="gender" />
                    </legend>
                    <Form.Field
                      as={SelectList}
                      data={[
                        { id: 1, text: "Male" },
                        { id: 2, text: "Female" },
                      ]}
                      mapFromValue={gender => gender.id}
                      valueField="id"
                      textField="text"
                      name="gender"
                      className="form-input-margin-bottom form-inline inline-radio"
                    />
                  </div>
                </div>
                <div className="container form-input-margin-bottom clear">
                  <legend>
                    Location
                    <Form.Message className="has-error" for="location" />
                  </legend>
                  <Form.Field
                    name="location"
                    placeholder="City, State"
                    className="form-input-margin-bottom"
                    as={LocationInput}
                  />
                </div>
                <div className="container">
                  <legend>
                    Email
                    <Form.Message className="has-error" for="email" />
                  </legend>
                  <Form.Field
                    placeholder="Email"
                    name="email"
                    className="form-input-margin-bottom"
                  />

                  <div className="form-inline-half">
                    <legend>
                      Password
                      <Form.Message className="has-error" for="password" />
                    </legend>
                    <Form.Field
                      placeholder="Password"
                      type="password"
                      validates={
                        this.state.newUser.confirmPassword
                          ? ["confirmPassword"]
                          : []
                      }
                      name="password"
                      className="form-input-margin-bottom"
                    />
                  </div>
                  <div className="form-inline-half">
                    <legend>
                      Confirm password
                      <Form.Message
                        className="has-error"
                        for="confirmPassword"
                      />
                    </legend>
                    <Form.Field
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      className="form-input-margin-bottom"
                    />
                  </div>
                </div>

                {errorMessage && this.state.submitted && (
                  <span className="has-error">{errorMessage}</span>
                )}

                {(this.state.submitted && !errorMessage) || submitting ? (
                  <div className="btn-flat btn-primaryAccent text-align-center btn-loader">
                    <div className="loader-small"></div>
                  </div>
                ) : (
                  <Form.Submit className="btn-flat btn-primaryAccent btn-loader text-align-center">
                    Register Now
                  </Form.Submit>
                )}

                <p className="account-terms">
                  By clicking "Register" I agree to Zivug Zone's{" "}
                  <Link to={`terms`} className="account-terms-link">
                    Terms and Conditions.
                  </Link>
                </p>

                <Link to={`login`} className="login-link">
                  Already have an account? Login
                </Link>
              </Form>
            </div>
          </div>
        </div>
        <ContactUsBanner />
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    constants: state.constants,
    user: state.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
