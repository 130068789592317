export const LOAD_SHADCHANIM_START = "LOAD_SHADCHANIM_START";
export const LOAD_SHADCHANIM_SUCCESS = "LOAD_SHADCHANIM_SUCCESS";
export const LOAD_SHADCHANIM_FAIL = "LOAD_SHADCHANIM_FAIL";

export const GET_SHADCHAN_START = "GET_SHADCHAN_START";
export const GET_SHADCHAN_SUCCESS = "GET_SHADCHAN_SUCCESS";

export const GET_MY_SHADCHAN_PROFILE_SUCCESS =
  "GET_MY_SHADCHAN_PROFILE_SUCCESS";
export const GET_MY_SHADCHAN_PROFILE_START = "GET_MY_SHADCHAN_PROFILE_START";

export const UPDATE_SHADCHAN_MEMBERS_SUCCESS =
  "UPDATE_SHADCHAN_MEMBERS_SUCCESS";
export const UPDATE_ACCEPTED_SHADCHAN_MEMBERS_SUCCESS =
  "UPDATE_ACCEPTED_SHADCHAN_MEMBERS_SUCCESS";
export const SOCKET_IO_REQUESTED_MEMBERS_UPDATE =
  "SOCKET_IO_REQUESTED_MEMBERS_UPDATE";
export const CLEAR_SELECTED_SHADCHAN = "CLEAR_SELECTED_SHADCHAN";
