import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Scrollbars } from "react-custom-scrollbars";
import Resume from "../resumes/Resume";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as resumeActions from "../../actions/resumeActions";
import * as modalActions from "../../actions/modalActions";
import { parse } from "query-string";

class InboxRightPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSidebar: true,
      resumeSelected: false,
      conversationResumeId: null,
      ideaId: null,
      resumeId: null,
    };
    this.selectResume = this.selectResume.bind(this);
  }

  toggleSidebar() {
    if (this.refs.sidebarContentResume) {
      this.props.toggleSidebar();
    }
  }

  componentDidMount() {
    const { location, resumes } = this.props;
    const { conversationResumeId } = parse(location.search);
    const resume = resumes.find(e => e.crId === +conversationResumeId);
    if (resume) this.selectResume(resume.resumeId, conversationResumeId);
  }

  componentDidUpdate(prevProps) {
    const { location, resumes } = this.props;
    const { conversationResumeId } = parse(location.search);
    const prevCrID = parse(prevProps.location.search).conversationResumeId;
    if (conversationResumeId && conversationResumeId !== prevCrID) {
      const resume = resumes.find(e => e.crId === +conversationResumeId);
      if (resume) this.selectResume(resume.resumeId, conversationResumeId);
    }
  }

  selectResume(resumeId, conversationResumeId) {
    this.setState({ resumeSelected: true }, () => {
      this.props.resumeActions.getResumeById(resumeId, conversationResumeId);
    });
  }

  unselectResume = () => this.setState({ resumeSelected: false });

  render() {
    const {
      props: { resumeLoading, resumes, showSidebar },
      state: { resumeSelected },
    } = this;
    let renderThis;

    if (resumeLoading) {
      renderThis = (
        <div className="right-panel">
          <div className="loader"></div>
        </div>
      );
    } else if (resumeSelected) {
      renderThis = (
        <div className="sidebar-buttons-padding">
          <div className="sidebar-buttons">
            <p
              className="btn-sidebar-create"
              onClick={() => this.setState({ resumeSelected: false })}
            >
              <FontAwesome name="arrow-left" /> Go Back
            </p>
          </div>

          <div className="inbox-resume-section">
            <div style={{ paddingTop: "20px" }}>
              <p
                className="btn-flat btn-primaryAccent"
                onClick={() =>
                  this.props.modalActions.setModal("INBOX_RESUME", {
                    unselectResume: this.unselectResume,
                  })
                }
              >
                View Full Resume <FontAwesome name="external-link" />
              </p>
            </div>
            <Resume sidebarView={true} />
          </div>
        </div>
      );
    } else if (resumes.length) {
      renderThis = (
        <div className="container">
          {resumes.map((r, index) => {
            const { crId, resumeId, name, isParticipantsResume } = r;
            return (
              <div
                key={index}
                className={
                  isParticipantsResume
                    ? "sidebar-participant-resume-wrapper"
                    : ""
                }
              >
                {isParticipantsResume ? (
                  <div className="pr-title">Participants Resume</div>
                ) : null}
                <div
                  onClick={() => this.selectResume(resumeId, crId)}
                  className="sidebar-list-item-info-inboxResume"
                  id={isParticipantsResume ? "sidebar-participant-resume" : ""}
                >
                  <i className="material-icons attached-resume">attach_file</i>
                  <h5 className="sidebar-list-item-title">{name}</h5>
                  <p className="sidebar-list-item-btn-view">
                    View <FontAwesome name="arrow-right" />
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      renderThis = (
        <div className="message-sidebar-empty">
          <FontAwesome name="envelope" className="message-sidebar-empty-icon" />
          <p>No Resumes</p>
        </div>
      );
    }

    let openArrow;
    if (!showSidebar) {
      openArrow = (
        <span
          className="sidebar-content-arrow"
          onClick={this.toggleSidebar.bind(this)}
        >
          <FontAwesome name="arrow-left" className="open-sidebar" />
        </span>
      );
    } else {
      openArrow = (
        <span
          className="sidebar-content-arrow"
          onClick={this.toggleSidebar.bind(this)}
        >
          <FontAwesome name="arrow-right" className="close-sidebar" />
        </span>
      );
    }

    let sidebarClass = showSidebar ? "header-padding active" : "header-padding";

    return (
      <div
        id="sidebar-content"
        className={sidebarClass}
        ref="sidebarContentResume"
      >
        {openArrow}

        <div id="sidebar-content-content">
          <div
            className="panel-actions panel-action-one"
            ref="tabs"
            onClick={() => this.setState({ resumeSelected: false })}
          >
            <div className="panel-action active">
              <p>Resumes</p>
            </div>
          </div>
          <Scrollbars
            style={{
              width: 350,
              height: this.context.objectHeights.mainPanelCalculatedHeight,
            }}
          >
            <div className="content-padding">
              <div className="sidebar-tab active">
                <div className="sidebar-resume-shares">{renderThis}</div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    resumes: { resumeLoading },
    singleConversation: {
      conversation: { resumes = [] },
    },
  } = state;
  return {
    resumeLoading,
    resumes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resumeActions: bindActionCreators(resumeActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InboxRightPanel),
);
