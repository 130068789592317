import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActions from "../../actions/modalActions";
import { Helmet } from "react-helmet";
import { Scrollbars } from "react-custom-scrollbars";
import MyMatchesLeftPanel from "./MyMatchesLeftPanel";
import CreatedIdeas from "./CreatedIdeas";
import WorkingIdeas from "./WorkingIdeas";
import DatingIdeas from "./DatingIdeas";
import PendingShadchanMsg from "../common/PendingShadchanMsg";
import * as myMatchesActions from "../../actions/myMatchesActions";

class MyMatchesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeScrollbar: false,
      columnHeight: 0,
    };
  }

  componentDidMount() {
    if (this.props.shadchanStatus === "approved") {
      this.props.actions.getMyIdeas();
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentWillReceiveProps() {
    if (this.props.shadchanStatus === "approved") {
      this.handleResize();
    }
  }

  handleResize = () => {
    const columnHeight =
      document.getElementsByClassName("ideas-column") &&
      document.getElementsByClassName("ideas-column")[0]
        ? document.getElementsByClassName("ideas-column")[0].clientHeight - 70
        : 400;
    this.setState({ columnHeight });
    this.forceUpdate();
    const { scrollbars } = this.refs;
    const { scrollHeight } = scrollbars.getValues();
    scrollHeight > columnHeight
      ? this.setState({ activeScrollbar: true })
      : this.setState({ activeScrollbar: false });
  };

  render() {
    const isMobile = window.innerWidth < 721;
    const mainContentStyles = {
      overflow: "auto",
      marginTop: isMobile ? 0 : "20px",
    };
    if (isMobile) {
      mainContentStyles.paddingTop = "100px";
    }

    const {
      state: { columnHeight, activeScrollbar },
      props: { shadchanStatus },
    } = this;
    const ideaSections = [
      { name: "Created", propName: "createdIdeas" },
      { name: "Status", propName: "workingIdeas" },
      { name: "Dating", propName: "datingIdeas" },
    ];
    return (
      <Fragment>
        {shadchanStatus !== "approved" && <PendingShadchanMsg />}
        {shadchanStatus === "approved" && (
          <div>
            <Helmet>
              <title>My Matches | Zivug Zone</title>
            </Helmet>
            {!isMobile && <MyMatchesLeftPanel />}
            <div className="container">
              <div
                id="content-main"
                className="header-padding column-layout-two"
                style={mainContentStyles}
              >
                <div className="scrollable-container">
                  {ideaSections.map(s => {
                    const { name, propName } = s;
                    return (
                      <div key={name} className="ideas-column-container">
                        <div className="ideas-column">
                          <div className="ideas-column-title">
                            {s.name}
                            <span>{this.props[`${propName}`].length}</span>
                          </div>
                          <Scrollbars
                            style={{ height: columnHeight }}
                            ref="scrollbars"
                          >
                            <div
                              className={
                                activeScrollbar
                                  ? "idea-items idea-items-scrollbar"
                                  : "idea-items"
                              }
                            >
                              {name === "Created" ? (
                                <CreatedIdeas />
                              ) : name === "Status" ? (
                                <WorkingIdeas />
                              ) : (
                                <DatingIdeas />
                              )}
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {isMobile && <MyMatchesLeftPanel />}
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    myMatches: { createdIdeas, workingIdeas, datingIdeas },
    user: { shadchanStatus },
  } = state;
  return {
    shadchanStatus,
    createdIdeas,
    workingIdeas,
    datingIdeas,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(myMatchesActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMatchesPage);
