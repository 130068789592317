import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactModal from "react-modal";
import * as modalActions from "../../actions/modalActions";
import modalComponents from "./modalConsts";

const ModalContainer = ({
  modalType,
  modalProps: { closeOnOutsideClick, ...componentProps },
  actions,
}) => {
  if (!modalType || !modalComponents[modalType]) {
    return <div className="no-modal"></div>;
  }

  ReactModal.setAppElement(".container-app");
  const SpecificModal = modalComponents[modalType];

  return (
    <ReactModal
      isOpen={true}
      contentLabel="Modal"
      className={{ base: "", afterOpen: "", beforeClose: "" }}
      overlayClassName={{
        base: "modal-container",
        afterOpen: "modal-open",
        beforeClose: "modal-close",
      }}
      onRequestClose={closeOnOutsideClick ? actions.unsetModal : () => {}}
    >
      <div className="modal">
        <SpecificModal {...componentProps} removeModal={actions.unsetModal} />
      </div>
    </ReactModal>
  );
};

export default connect(
  state => state.modals,
  dispatch => {
    return { actions: bindActionCreators(modalActions, dispatch) };
  },
)(ModalContainer);
