import * as yup from "yup";
import { validPhoneRegex } from "../../zivugZoneUtils";

yup.addMethod(yup.mixed, "min", function(min, msg) {
  return this.test({
    name: "min",
    exclusive: true,
    message: msg,
    test: value => !value || value.length >= min,
  });
});

yup.addMethod(yup.array, "containsValue", function(msg) {
  return this.test({
    name: "containsValue",
    exclusive: true,
    message: msg,
    test: values => values.some(v => v.name),
  });
});

// yup.addMethod(yup.array, "containsContactText", function(msg) {
//   return this.test({
//     name: "containsContactText",
//     exclusive: true,
//     message: msg,
//     test: values => values.some(v => v.contactText),
//   });
// });

yup.addMethod(yup.array, "containsTwoReferences", function(msg) {
  return this.test({
    name: "containsTwoReferences",
    exclusive: true,
    message: msg,
    test: values => {
      let filledOutReferences = values.filter(r => r.name);
      return filledOutReferences.length > 1;
    },
  });
});

let experienceObject = yup.object().shape({
  name: yup.string().when(["details"], {
    is: details => details,
    then: yup.string().required("Name is required"),
    otherwise: yup.string(),
  }),
  details: yup.mixed(),
});

export default yup.object().shape({
  firstName: yup.string().required("First name is required"),
  fullName: yup.mixed(),
  lastName: yup.string().required("Last name is required"),
  birthday: yup.date().required("Required"),
  age: yup.mixed(),
  height: yup.number().required("Required"),
  address: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  hometown: yup.mixed(),
  relocate: yup.mixed().required("Required"),
  maritalStatus: yup.mixed().required("Required"),
  numberChildren: yup.string().when(["maritalStatus"], {
    is: maritalStatus => maritalStatus > 1,
    then: yup.string().required("Number of children is required"),
    otherwise: yup.string().nullable(),
  }),
  ethnicity: yup.mixed().required("Required"),
  religious: yup.mixed().required("Required"),
  type: yup.mixed().required("Required"),
  isCohen: yup.bool(),
  languages: yup.array().of(yup.object()),

  personalDescription: yup
    .string()
    .required("Required")
    .nullable(),
  lookingFor: yup.mixed(),

  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
  mobilePhone: yup
    .string()
    .matches(validPhoneRegex, "Phone number is not valid")
    .required("Required"),
  homePhone: yup
    .string()
    .matches(validPhoneRegex, "Phone number is not valid")
    .nullable(),
  shidduchContactInfo: yup.mixed(),
  displayPersonalContactInfo: yup.mixed(),

  contactName: yup.mixed().when(["displayPersonalContactInfo"], {
    is: false,
    then: yup
      .string()
      .required("Required")
      .nullable(),
    otherwise: yup.mixed().nullable(),
  }),
  // contactEmail: yup.string().email("Invalid email"),
  contactEmail: yup.string().when(["displayPersonalContactInfo"], {
    is: false,
    then: yup
      .string()
      .email("Invalid email")
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  contactPhone: yup.string().when(["displayPersonalContactInfo"], {
    is: false,
    then: yup
      .string()
      .matches(validPhoneRegex, "Phone number is not valid")
      .required("Required")
      .nullable(),
    otherwise: yup
      .string()
      .matches(validPhoneRegex, "Phone number is not valid")
      .nullable(),
  }),
  contactRelationship: yup.mixed().when(["displayPersonalContactInfo"], {
    is: false,
    then: yup
      .string()
      .required("Required")
      .nullable(),
    otherwise: yup.mixed().nullable(),
  }),
  images: yup.array().of(
    yup.object().shape({
      fileLocation: yup.mixed(),
      mainImage: yup.mixed(),
    }),
  ),
  shuls: yup.array().of(
    yup.object().shape({
      name: yup.mixed(),
      rabbi: yup.mixed(),
      rabbiPhone: yup
        .string()
        .matches(validPhoneRegex, "Phone number is not valid"),
      details: yup.mixed(),
    }),
  ),
  familyInfo: yup.object().shape({
    info: yup.mixed(),
  }),
  education: yup
    .array()
    .of(experienceObject)
    .containsValue("Required"),
  occupation: yup.object().shape({
    name: yup.string().required("Occupation is required"),
    details: yup.mixed(),
  }),
  camps: yup.array().of(experienceObject),
  volunteer: yup.array().of(experienceObject),
  references: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Name is required"),
        referenceType: yup.mixed().required("Please choose a reference type"),
        details: yup.mixed(),
        contactInfo: yup.array().of(
          yup.object().shape({
            contactText: yup.mixed(),
          }),
        ),
        // .containsContactText("Required"),
      }),
    )
    .containsTwoReferences("2 References are required"),
});
