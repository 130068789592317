import React from "react";
import { connect } from "react-redux";
import Header from "../header/Header";
import { Helmet } from "react-helmet";

export const requireAuthentication = Component => {
  class AuthenticatedComponent extends React.Component {
    componentDidMount() {
      this.checkAuth();
    }

    componentDidUpdate(prevProps) {
      const user = this.props.user;
      if (prevProps.user !== user) {
        this.checkAuth();
      }
    }

    checkAuth() {
      const {
        user: { isAuthenticated, role, hasResume },
        location: { pathname, search },
      } = this.props;
      if (!isAuthenticated) {
        let redirectAfterLogin =
          pathname.substr(0, 1) === "/" ? pathname.substr(1) : pathname;
        this.props.history.push(
          `/loading${
            redirectAfterLogin
              ? `?next=${`${redirectAfterLogin}${search}`}`
              : ""
          }`,
        );
      }

      // force single to create resume
      if (role === "single" && !hasResume) {
        this.props.history.push("/myResume/create-edit");
      }
    }

    render() {
      return (
        <div>
          {this.props.user.isAuthenticated === true ? (
            <div className="main-app">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Zivug Zone</title>
              </Helmet>
              <Header {...this.props} />
              <Component {...this.props} />
            </div>
          ) : null}
        </div>
      );
    }
  }

  const mapStateToProps = state => ({
    user: state.user,
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
};
