import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Scrollbars } from "react-custom-scrollbars";
import FontAwesome from "react-fontawesome";
import { Helmet } from "react-helmet";
import * as shadchanActions from "../../../actions/shadchanActions";
import ShadchanInfo from "./ShadchanInfo";
import ShadchanMembers from "./ShadchanMembers";
import history from "../../../lib/history";

class ShadchanMain extends Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { activeTab: "about" };
    this.requestMembership = this.requestMembership.bind(this);
  }

  requestMembership(id) {
    this.props.actions.requestMembership(id);
  }

  changeActiveTab(tabId) {
    this.setState({ activeTab: tabId });
  }

  render() {
    const {
      props: {
        selectedShadchan: {
          id,
          firstName,
          lastName,
          memberStatus,
          conversationId,
        },
        displayMobile,
        switchMobileTab,
        role,
      },
      state: { activeTab },
    } = this;
    const shadchanName = firstName ? `${firstName} ${lastName}` : "";
    const image = "../images/small-placeholder.png";
    const title = `${shadchanName} | Zivug Zone`;
    let membershipStatus;
    if (role !== "single") membershipStatus = undefined;
    else if (memberStatus === "requested") {
      membershipStatus = (
        <div className="action-item action-item-pending">
          <FontAwesome name="user-plus" className="action-item-icon" />
          Your Request to join this Shadchan is Pending
        </div>
      );
    } else if (memberStatus === "accepted") {
      membershipStatus = (
        <div className="action-item action-item-accepted">
          <FontAwesome name="heart" className="action-item-icon" />
          You are a member of this shadchan
        </div>
      );
    } else if (memberStatus === "denied") {
      membershipStatus = (
        <div className="action-item action-item-denied">
          <FontAwesome name="exclamation-circle" className="action-item-icon" />
          Your membership has been declined
        </div>
      );
    } else {
      membershipStatus = (
        <div className="action-item link">
          <div onClick={() => this.requestMembership(id)}>
            <FontAwesome name="user-plus" className="action-item-icon" />
            Request to join this Shadchan
          </div>
        </div>
      );
    }

    return (
      <div
        id="content-main"
        className={`header-padding column-layout-two ${
          displayMobile ? "" : "mobile-hide"
        }`}
        ref="shadchanInfoContainer"
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="container overflow-auto groupPage">
          <div className="shadchan-actions-container">
            {displayMobile && (
              <div onClick={switchMobileTab} className="back">
                <FontAwesome name="arrow-left" className="action-item-icon" />{" "}
                Back to list
              </div>
            )}
            {membershipStatus}
            {role === "single" &&
              memberStatus === "accepted" &&
              conversationId && (
                <div
                  className="action-item action-item-send-msg"
                  onClick={() => history.push(`/inbox/${conversationId}`)}
                >
                  <FontAwesome
                    name="paper-plane"
                    className="action-item-icon"
                  />
                  Message Shadchan
                </div>
              )}
          </div>
        </div>

        <div className="content-padding-fixed-top">
          <Scrollbars
            style={{
              height: this.context.objectHeights.mainPanelCalculatedHeight,
            }}
          >
            <div className="content-padding" ref="groupMainInfo">
              <div className="resume-header resume-section ">
                <img
                  src={image}
                  alt=""
                  className="resume-header-image"
                  height="125"
                  width="125"
                />
                <div className="resume-header-info">
                  <h2 className="resume-name resume-name-container">
                    {shadchanName}
                  </h2>
                </div>
              </div>
              {/* SINGLE VIEW */}
              {role === "single" && (
                <div className="container">
                  <div className="section clearfix">
                    <ShadchanInfo />
                  </div>
                </div>
              )}
              {/* END SINGLE VIEW */}
              {/* ADMIN VIEW */}
              {role === "admin" && (
                <div className="container">
                  <div className="panel-actions panel-actions-bar">
                    <div
                      className={`panel-action panel-action-left ${
                        activeTab === "about" ? "active" : ""
                      }`}
                      onClick={() => this.changeActiveTab("about")}
                    >
                      <p>About</p>
                    </div>
                    <div
                      className={`panel-action panel-action-right ${
                        activeTab === "members" ? "active" : ""
                      }`}
                      onClick={() => this.changeActiveTab("members")}
                    >
                      <p>Members</p>
                    </div>
                  </div>
                  <div className="section clearfix">
                    <div
                      className={
                        this.state.activeTab === "about"
                          ? "sidebar-tab active"
                          : "sidebar-tab"
                      }
                    >
                      <ShadchanInfo />
                    </div>
                    <div
                      className={
                        this.state.activeTab === "members"
                          ? "sidebar-tab active"
                          : "sidebar-tab"
                      }
                    >
                      <ShadchanMembers />
                    </div>
                  </div>
                </div>
              )}
              {/* END ADMIN VIEW */}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    shadchan: { selectedShadchan },
    user: { role },
  } = state;
  return {
    selectedShadchan,
    role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(shadchanActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShadchanMain);
