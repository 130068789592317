import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import Infinite from "../common/Infinite";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import { heightToString } from "../common/propsConverter";
import "react-widgets/lib/less/react-widgets.less";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PrivateImage from "../common/privateImage";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as myMatchesActions from "../../actions/myMatchesActions";
import * as modalActions from "../../actions/modalActions";
import * as resumeActions from "../../actions/resumeActions";
const emptySearch = {
  pageNumber: 1,
  filterBy: "",
  searchText: "",
  gender: null,
  minAge: "",
  maxAge: "",
  minHeight: "",
  maxHeight: "",
  maritalStatus: [],
  maxChildren: "",
  types: [],
  languages: [],
  religious: [],
  ethnicity: [],
  location: "",
};

class MyMatchesLeftPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: {
        ...emptySearch,
      },
      searchActive: false,
      filterActive: false,
      height: 0,
    };
    this.filterDropDown = this.filterDropDown.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.selectChanged = this.selectChanged.bind(this);
    this.checkBoxChanged = this.checkBoxChanged.bind(this);
    this.genderChanged = this.genderChanged.bind(this);
    this.search = this.search.bind(this);
    this.searchAndClose = this.searchAndClose.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.viewResume = this.viewResume.bind(this);
    this.loadMoreResumes = this.loadMoreResumes.bind(this);
    this.debouncedSearch = _.debounce(this.search, 1000);
  }

  componentDidMount() {
    this.props.resumeActions.loadResumes(this.state.search);
    this.context.recalculateObjectHeights();
  }

  loadMoreResumes() {
    const {
      props: {
        pagination: { pageCount },
        resumeActions: { loadResumes },
      },
      state: {
        search: { pageNumber },
      },
    } = this;
    if (pageNumber >= pageCount) return;
    this.setState(
      ({ search }) => ({
        search: { ...search, pageNumber: search.pageNumber + 1 },
      }),
      () => loadResumes(this.state.search),
    );
  }

  viewResume(resumeId) {
    this.props.resumeActions.getResumeById(resumeId);
    this.props.modalActions.setModal("RESUME", { resumeId });
  }

  filterClick = () => {
    this.setState(
      { filterActive: !this.state.filterActive },
      this.context.recalculateObjectHeights,
    );
  };

  filterDropDown(event) {
    let search = { ...this.state.search };
    search.filterBy = event.target.value;
    this.setState({ search }, () => {
      this.search();
    });
  }

  inputChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = event.target.value;
    return this.setState({ search });
  }

  selectChanged(e) {
    const { name, value } = e.target;
    let search = { ...this.state.search };
    search[name] = Number(value);
    return this.setState({ search });
  }

  checkBoxChanged(e) {
    const { name, value: v } = e.target;
    let value = Number(v);
    let search = { ...this.state.search };
    let list = [...search[name]];
    if (list.includes(value)) {
      list = list.filter(i => i !== value);
    } else {
      list.push(value);
    }
    search[name] = list;
    return this.setState({ search });
  }

  genderChanged(event) {
    let value = Number(event.target.value);
    let search = { ...this.state.search };
    let gender = null;
    switch (search.gender) {
      case 1:
        gender = value === 1 ? null : 0;
        break;
      case 2:
        gender = value === 1 ? 0 : null;
        break;
      case 0:
        gender = value === 1 ? 2 : 1;
        break;
      default:
        gender = value === 1 ? 1 : 2;
    }
    search.gender = gender;
    return this.setState({ search });
  }

  clearSearch() {
    return this.setState({ search: emptySearch, searchActive: false }, () => {
      this.searchAndClose();
      this.context.recalculateObjectHeights();
    });
  }

  // handleKeyPress = e => {
  //   if (e.key === "Enter") {
  //     this.searchAndClose();
  //   }
  // };

  handleSearchChange = ({ target }) => {
    this.setState(
      {
        search: { ...this.state.search, searchText: target.value },
        filterActive: false,
      },
      this.debouncedSearch,
    );
  };

  searchAndClose() {
    this.search();
    this.setState({ filterActive: false });
  }

  search() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    let searchActive = !_.isEqual(search, emptySearch);
    this.setState({ search, searchActive }, () => {
      this.props.resumeActions.loadResumes(search);
      this.context.recalculateObjectHeights();
    });
  }

  resumeTypeRow(type, length, index) {
    return index < length - 1 ? type.type.text + ", " : type.type.text;
  }

  resumeRow(resume) {
    return (
      <li
        key={resume.id}
        onClick={() => this.viewResume(resume.id, resume)}
        className={
          this.props.selectedResume === resume.id
            ? "sidebar-list-item sidebar-list-item-active"
            : "sidebar-list-item"
        }
        ref={this.props.selectedResume === resume.id ? "activeItem" : ""}
      >
        <div className="sidebar-img-container">
          <PrivateImage
            fileName={resume.image}
            id={resume.id}
            section="resumeSmall"
            className="sidebar-list-item-image"
          />
        </div>
        <div className="sidebar-list-item-info sidebar-column-large">
          <h5 className="sidebar-list-item-title">
            {resume.firstName} {resume.lastName}
          </h5>
          <p className="sidebar-list-item-info-details full-name">
            {resume.fullName ? `(${resume.fullName})` : ""}
          </p>
          <p className="sidebar-list-item-info-details">
            <span>{resume.age || "-"}</span>
            <span>{resume.height}</span>
            <span>
              {resume.maritalStatus}{" "}
              {resume.numberChildren > 0 ? `/ ${resume.numberChildren}` : ""}
            </span>
          </p>
        </div>
      </li>
    );
  }

  renderFilterBar(search) {
    return (
      <div>
        <div className="sidebar-column-extraLarge filter-column-dropdown">
          <p>Include:</p>
          <select
            onChange={this.filterDropDown}
            value={search.filterBy}
            className="filter-bar-select mui-select"
          >
            <option className="option" value="" label="All" />
            <option
              className="option"
              value="myMembers"
              label="My Shadchan Members"
            />
          </select>
        </div>
      </div>
    );
  }

  render() {
    const containerHeight = this.context.objectHeights.sidebarCalculatedHeight;
    const {
      props: {
        constants,
        pagination: { rowCount: resumeCount },
      },
      state: { search, filterActive, searchActive },
    } = this;
    let content;
    if (filterActive) {
      content = (
        <CSSTransition
          key="filter"
          classNames="searchFormTransition"
          timeout={700}
        >
          <div id="form" key="search">
            <Scrollbars style={{ height: containerHeight }}>
              <div className="search-form">
                <div className="form-section mui-row clearfix">
                  <legend>Gender</legend>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="mui-radio form-input-inline"
                        name="gender"
                        value={1}
                        checked={search.gender === 1 || search.gender === 0}
                        onChange={this.genderChanged}
                      />
                      Male
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="mui-radio form-input-inline"
                        name="gender"
                        value={2}
                        checked={search.gender === 2 || search.gender === 0}
                        onChange={this.genderChanged}
                      />
                      Female
                    </label>
                  </div>
                </div>
                <div className="form-section mui-row clearfix">
                  <legend>Relationship Status</legend>
                  {constants.maritalStatus
                    ? constants.maritalStatus.map(t => {
                        return (
                          <label key={t.id}>
                            <input
                              type="checkbox"
                              className="mui-radio form-input-inline"
                              name="maritalStatus"
                              value={t.id}
                              checked={search.maritalStatus.includes(t.id)}
                              onChange={this.checkBoxChanged}
                            />
                            {t.text}
                          </label>
                        );
                      })
                    : null}
                  {search.maritalStatus.includes(2) ||
                  search.maritalStatus.includes(3) ? (
                    <div className="filter-children">
                      <p className="children-label">Number of Children</p>
                      <select
                        className="form-input-inline form-input-inline-select"
                        name="maxChildren"
                        value={search.maxChildren}
                        onChange={this.selectChanged}
                      >
                        <option value=""> </option>
                        <option value="0">None</option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((x, i) => (
                          <option key={`maxChildren${x}`} value={x}>
                            {x} or less
                          </option>
                        ))}
                        <option value={10}>Any</option>
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="form-section mui-row">
                  <legend>Age Range</legend>
                  <input
                    className="search-input-inline"
                    min={16}
                    max={80}
                    type="number"
                    name="minAge"
                    onChange={this.inputChanged}
                    value={search.minAge}
                  />
                  <p className="form-input-inline search-input-inline-label">
                    to
                  </p>
                  <input
                    className="search-input-inline"
                    min={16}
                    max={80}
                    type="number"
                    name="maxAge"
                    onChange={this.inputChanged}
                    value={search.maxAge}
                  />
                </div>
                <div className="form-section mui-row">
                  <legend>Height Range</legend>
                  <select
                    name="minHeight"
                    onChange={this.selectChanged}
                    value={search.minHeight}
                    className="form-input-inline-select search-input-inline"
                  >
                    <option value=""> </option>
                    {_.range(56, search.maxHeight || 85).map((x, i) => (
                      <option key={`minH${i}`} value={x}>
                        {heightToString(x)}
                      </option>
                    ))}
                  </select>
                  <p className="form-input-inline search-input-inline-label">
                    to
                  </p>
                  <select
                    name="maxHeight"
                    onChange={this.selectChanged}
                    value={search.maxHeight}
                    className="form-input-inline-select search-input-inline"
                  >
                    <option value=""> </option>
                    {_.range(
                      search.minHeight ? search.minHeight + 1 : 56,
                      85,
                    ).map((x, i) => (
                      <option key={`maxH${i}`} value={x}>
                        {heightToString(x)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-section mui-row filter-types clearfix">
                  <div className="mui-col-md-12">
                    <legend>Types</legend>
                    {constants.types
                      ? constants.types.map(t => {
                          return (
                            <label key={`types${t.id}`}>
                              <input
                                type="checkbox"
                                className="mui-radio form-input-inline"
                                name="types"
                                value={t.id}
                                checked={search.types.includes(t.id)}
                                onChange={this.checkBoxChanged}
                              />
                              {t.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row filter-languages clearfix">
                  <div className="mui-col-md-12">
                    <legend>Languages</legend>
                    {constants.languages
                      ? constants.languages.map(l => {
                          return (
                            <label key={`languages${l.id}`}>
                              <input
                                type="checkbox"
                                className="mui-radio form-input-inline"
                                name="languages"
                                value={l.id}
                                checked={search.languages.includes(l.id)}
                                onChange={this.checkBoxChanged}
                              />
                              {l.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row filter-rb clearfix">
                  <div className="mui-col-md-12">
                    <legend>Religious Background</legend>
                    {constants.religious
                      ? constants.religious.map(r => {
                          return (
                            <label key={`religious${r.id}`}>
                              <input
                                type="checkbox"
                                name="religious"
                                value={r.id}
                                checked={search.religious.includes(r.id)}
                                onChange={this.checkBoxChanged}
                                className="mui-radio form-input-inline"
                              />
                              {r.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row filter-ethnicity clearfix">
                  <div className="mui-col-md-12">
                    <legend>Ethnicity</legend>
                    {constants.ethnicity
                      ? constants.ethnicity.map(e => {
                          return (
                            <label key={`ethnicity${e.id}`}>
                              <input
                                type="checkbox"
                                name="ethnicity"
                                value={e.id}
                                checked={search.ethnicity.includes(e.id)}
                                onChange={this.checkBoxChanged}
                                className="mui-radio form-input-inline"
                              />
                              {e.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row">
                  <div className="mui-col-md-12">
                    <legend>Search by Location</legend>
                    <input
                      placeholder="Search by location"
                      className="search-dropdown-input"
                      type="text"
                      name="location"
                      value={search.location}
                      onChange={this.inputChanged}
                    />
                  </div>
                </div>
              </div>
            </Scrollbars>
            <div className="search-dropdown-box-footer">
              <button
                onClick={this.clearSearch}
                className="btn-flat btn-reset"
                type="button"
              >
                Clear
              </button>
              <button
                onClick={this.searchAndClose}
                className="btn-flat btn-success"
                type="button"
              >
                Search
              </button>
            </div>
          </div>
        </CSSTransition>
      );
    } else {
      content = (
        <CSSTransition
          key="list"
          classNames="searchFormTransition"
          timeout={700}
        >
          <div key="list">
            <div id="filter-bar" className="filter-bar">
              {this.renderFilterBar(search)}
            </div>
            {this.state.searchActive ? (
              <div className="sidebar-list-results" id="sidebar-list-results">
                <p>
                  <span className="sidebar-list-results-bold">
                    {resumeCount}{" "}
                  </span>
                  Result{resumeCount === 1 ? "" : "s"}
                </p>
                <p onClick={this.clearSearch} className="" type="button">
                  <span>Clear filters</span>
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="sidebar-list" id="resumeList">
              <ul>
                <Infinite
                  ref={infinite => {
                    this.infinite = infinite;
                  }}
                  selectedIndex={this.props.currentResumeIndex}
                  containerHeight={containerHeight}
                  hasMore={resumeCount > this.props.resumes.length}
                  className="infinite"
                  loadingMore={!!this.props.isLoading}
                  itemList={this.props.resumes}
                  renderRow={this.resumeRow.bind(this)}
                  noItemsText="No resumes."
                  loadMore={this.loadMoreResumes}
                />
              </ul>
            </div>
            <div className="sidebar-list-count" id="sidebar-list-count">
              <p>
                {resumeCount} Resume{resumeCount > 1 ? "s" : ""}
              </p>
            </div>
          </div>
        </CSSTransition>
      );
    }
    const isMobile = window.innerWidth < 721;

    return (
      <div
        id="idea-resumes"
        className="sidebar-left-filter header-padding"
        key="sidebar-filter"
        style={isMobile ? { paddingTop: "36px" } : null}
      >
        <div className="search-filter" id="mm-search-filter">
          <div className="search-bar" id="mm-search-bar">
            <div className="search" id="my-matches-search">
              <input
                className="input"
                type="text"
                placeholder="Search Resumes"
                name="searchText"
                //onKeyPress={this.handleKeyPress}
                onChange={this.handleSearchChange}
                value={search.searchText}
                autoComplete="off"
              />
            </div>
            <div
              className={`mm-filter-wrapper ${
                filterActive || searchActive ? "mm-filter-active" : ""
              }`}
              onClick={this.filterClick}
            >
              <div>filter</div>
              <FontAwesome name="sliders" />
            </div>
          </div>
        </div>
        <TransitionGroup className="search-form-container">
          {content}
        </TransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    resumes: { resumes, search, pagination, isLoading },
    constants,
  } = state;
  return {
    resumes,
    search,
    pagination,
    isLoading,
    constants,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(myMatchesActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    resumeActions: bindActionCreators(resumeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMatchesLeftPanel);
