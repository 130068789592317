import * as types from "../actionTypes/myMatchesActionTypes";

export default function myMatchesReducer(
  state = {
    createdIdeas: [],
    workingIdeas: [],
    datingIdeas: [],
  },
  action,
) {
  switch (action.type) {
    case types.GET_MY_IDEAS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case types.CREATE_IDEA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case types.EDIT_IDEA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case types.DELETE_IDEA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case types.SOCKET_IO_IDEA_UPDATE:
      return {
        ...state,
        ...action.ideas,
      };
    default:
      return state;
  }
}
