import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminActions from "../../actions/adminActions";
import moment from "moment";
const columns = [
  "id",
  "created on",
  "first name",
  "last name",
  "role",
  "email",
  "phone",
  "action",
];

function Users(props) {
  const {
    actions,
    users,
    pagination: { pageCount },
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    actions.getUsers({ pageNumber, search });
  }, [actions, pageNumber, search]);

  const nextPage = () => {
    if (pageNumber < pageCount) setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const blockUser = id => {
    actions.blockUser(id);
  };

  const unBlockUser = id => {
    actions.unBlockUser(id);
  };

  return (
    <div className="zz-admin-users">
      <div className="search-container">
        <input
          className="search-input"
          onChange={e => setUserInput(e.target.value)}
          placeholder="search users"
        />
        <div
          className="search"
          onClick={() => {
            setSearch(userInput);
            setPageNumber(1);
          }}
        >
          SEARCH
        </div>
      </div>
      <div className="container">
        <table>
          <thead>
            <tr>
              {columns.map(c => {
                return <th key={c}>{c}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {users.map(s => {
              const {
                id,
                createdAt,
                firstName,
                lastName,
                role,
                email,
                phone,
                blocked,
              } = s;

              return (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{moment(createdAt).format("MM/D/YYYY")}</td>
                  <td>{firstName}</td>
                  <td>{lastName}</td>
                  <td>{role}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>
                    {blocked ? (
                      <span className="approve" onClick={() => unBlockUser(id)}>
                        UnBlock
                      </span>
                    ) : (
                      <span className="deny" onClick={() => blockUser(id)}>
                        Block
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button
            className="button"
            onClick={() => previousPage()}
            disabled={pageNumber < 2}
          >
            {"<"}
          </button>{" "}
          <button
            className="button"
            onClick={() => nextPage()}
            disabled={pageNumber === pageCount}
          >
            {">"}
          </button>{" "}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { users, usersPagination } = state.admin;
  return {
    users,
    pagination: usersPagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(adminActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
