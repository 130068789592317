import * as types from "../actionTypes/adminTypes";

export default function constantsReducer(
  state = {
    pendingShadchanim: [],
    users: [],
    usersPagination: {},
  },
  action,
) {
  switch (action.type) {
    case types.ADMIN_PENDING_SHADCHANIM_SUCCESS:
      return {
        ...state,
        pendingShadchanim: action.pendingShadchanim,
      };
    case types.ADMIN_GET_USERS_SUCCESS:
      const { pagination, users } = action.users;
      return {
        ...state,
        users,
        usersPagination: pagination,
      };
    case types.ADMIN_BLOCK_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.id) {
            u.blocked = true;
          }
          return u;
        }),
      };
    case types.ADMIN_UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.id) {
            u.blocked = false;
          }
          return u;
        }),
      };
    default:
      return state;
  }
}
