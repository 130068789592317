import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as resumeActions from "../../actions/resumeActions";
import _ from "lodash";
import FontAwesome from "react-fontawesome";
import ReactTooltip from "react-tooltip";
import Converter from "../common/propsConverter";
import Dropzone from "react-dropzone";
import resizableTextArea from "../common/resizableTextArea";
import Form from "react-formal";
import "react-widgets/lib/less/react-widgets.less";
import SelectList from "react-widgets/lib/SelectList";
import MultiSelect from "react-widgets/lib/Multiselect";
import { apiAuthRequest } from "../../actions/helpers";
import VisibilitySensor from "react-visibility-sensor";
import PrivateImage from "../common/privateImage";
import fullResumeSchema from "./fullResumeSchema";
import * as resumeTips from "./resumeTips";
import { Helmet } from "react-helmet";

class CreateEditResume extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resume: props.resume,
      errors: {},
      edited: false,
      saved: false,
      visibleArea: 1,
    };
    this.visibleAreas = [1];
  }

  originalResume = {};

  componentDidMount() {
    const { resumeId, actions } = this.props;
    if (resumeId) {
      //editing a resume
      actions.getMyResumeToEdit();
    }
  }

  componentDidUpdate(prevProps) {
    const resume = this.props.resume;
    if (resume !== prevProps.resume) {
      this.setState({ resume });
      this.originalResume = _.cloneDeep(resume);
    }
  }

  areaVisibilityChanged = (isVisible, visibleArea) => {
    if (isVisible) {
      if (!this.visibleAreas.includes(visibleArea)) {
        this.visibleAreas.push(visibleArea);
      }
    } else {
      this.visibleAreas = this.visibleAreas.filter(i => i !== visibleArea);
    }
    let greatestVisibleArea = Math.max(...this.visibleAreas);
    if (greatestVisibleArea !== this.state.visibleArea) {
      this.setState({ visibleArea: greatestVisibleArea });
    }
  };

  scrollToSection = area => {
    if (this.refs[area]) {
      this.refs[area].scrollIntoView({ block: "start" });
      if (area === "general") window.scrollBy(0, -150);
      else if (area !== "photos") window.scrollBy(0, -100);
    }
  };

  onDrop = acceptedFiles => {
    if (!acceptedFiles.length) {
      return;
    }
    let image = acceptedFiles[0];
    if (image.size > 2500000 /* in bytes */) {
      return this.setState({ imageTooLarge: true });
    }

    let inside = this;
    let fd = new FormData();
    fd.append("image", image, image.name);
    apiAuthRequest("post", "/uploads/uploadResumeImage", fd)
      .then(imageUpload => {
        if (imageUpload.success && imageUpload.data.fileLocation) {
          this.addImage(imageUpload.data);
          inside.setState({ imageRejected: false, imageTooLarge: false });
        } else {
          inside.setState({ imageRejected: true });
        }
      })
      .catch(e => {
        inside.setState({ imageRejected: true });
        console.error(e);
      });
  };

  deleteNestedObject(section, index) {
    let component = this;
    this.deleteNestedObjectP(section, index).then(() => {
      component.refs.form.validate(section);
    });
  }

  async deleteNestedObjectP(sectionName, index) {
    let resume = { ...this.state.resume };
    let section = [...resume[sectionName]];
    section.splice(index, 1);
    resume[sectionName] = section;
    //delete errors for object that was removed
    let errors = { ...this.state.errors };
    errors = this.removeErrorsForSection(sectionName, errors);
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, errors, edited }, () => {
      return true;
    });
  }

  deleteExtraEmptyObjects = resume => {
    resume = { ...resume };
    let fieldsToExclude = ["references", "languages", "images"];
    Object.keys(resume).forEach(prop => {
      let propValue = resume[prop];
      if (Array.isArray(propValue) && !fieldsToExclude.includes(prop)) {
        for (let i = propValue.length - 1; i > 0; i--) {
          if (_.isEmpty(propValue[i])) {
            delete propValue[i];
          }
        }
      } else if (prop === "references") {
        for (let i = propValue.length - 1; i > 0; i--) {
          if (_.isEqual(propValue[i], { contactInfo: [{}] })) {
            delete propValue[i];
          }
        }
      }
    });
    return resume;
  };

  removeErrorsForSection(section, errors) {
    Object.keys(this.state.errors).forEach(err => {
      if (!err.includes(section)) {
        delete errors[err];
      }
    });
    return errors;
  }

  handleChange = values => {
    let edited = !_.isEqual(values, this.originalResume);
    this.setState({ resume: values, edited });
  };

  handleError = errors => {
    if (errors["birthday"]) {
      //if dob is in the error state change the message
      errors["birthday"] = "Required";
    }
    if (errors["height"]) {
      //if height is in the error state change the message
      errors["height"] = "Required";
    }
    this.setState({ errors });
  };

  addNestedObject = sectionName => {
    let resume = { ...this.state.resume };
    let section = [...resume[sectionName]];
    if (sectionName === "references") {
      section.push({
        contactInfo: [{}],
      });
    } else {
      section.push({});
    }
    resume[sectionName] = section;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  };

  addDoubleNestedObject = (sectionName, index, nestedSectionName) => {
    let resume = { ...this.state.resume };
    let section = [...resume[sectionName]];
    let item = { ...section[index] };
    let nestedSection = [...item[nestedSectionName]];
    nestedSection.push({});
    item[nestedSectionName] = nestedSection;
    section[index] = item;
    resume[sectionName] = section;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  };

  deleteDoubleNestedObject(sectionName, index, nestedSectionName, nestedIndex) {
    let resume = { ...this.state.resume };
    let section = resume[sectionName];
    let item = { ...section[index] };
    let nestedSection = [...item[nestedSectionName]];
    nestedSection.splice(nestedIndex, 1);
    item[nestedSectionName] = nestedSection;
    section[index] = item;
    resume[sectionName] = section;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  }

  addImage(image) {
    let resume = { ...this.state.resume };
    resume.images.push(image);
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, edited });
  }

  removeImage(imageIndex) {
    let resume = { ...this.state.resume };
    resume.images = resume.images.filter((i, index) => index !== imageIndex);
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, edited });
  }

  setImageAsMain(imageIndex) {
    let currentImages = [...this.state.resume.images];
    let images = [];
    images.push({ ...currentImages[imageIndex], mainImage: true });
    currentImages.forEach((obj, i) => {
      if (i !== imageIndex) {
        obj.mainImage = false;
        images.push(obj);
      }
    });
    let resume = { ...this.state.resume };
    resume.images = images;
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, edited });
  }

  saveResume = async () => {
    const {
      state: { resume },
      props: {
        userId,
        actions: { updateResume, createMyResume },
      },
    } = this;

    const formattedResume = this.deleteExtraEmptyObjects(resume);
    formattedResume.userId = userId;
    formattedResume.published = true;
    if (formattedResume.id) {
      const updated = await updateResume(formattedResume);
      if (updated) {
        this.setState({ edited: false });
      }
    } else {
      createMyResume(formattedResume);
    }
  };

  render() {
    const {
      props: { isLoading, constants },
      state: {
        resume,
        resume: { references = [] },
        saved,
        edited,
        errors,
        visibleArea,
      },
    } = this;
    if (isLoading) {
      return (
        <div className="loader-saving">
          Loading Resume<span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      );
    }
    return (
      <div>
        <Helmet>
          <title>My Resume | Zivug Zone</title>
        </Helmet>
        <div className="createResumePage">
          <Form
            id="form"
            ref="form"
            schema={fullResumeSchema}
            value={resume}
            onChange={this.handleChange}
            onSubmit={this.saveResume}
            onError={this.handleError}
            errors={errors}
          >
            <div className="sub-header sub-header-resume">
              <div className="mui-container">
                <div className="mui-col-md-12">
                  <div className="resume-sidebar">
                    <div className="steps-container">
                      <div className="steps">
                        <div className="content">
                          <ul className="clearfix">
                            <li
                              className={visibleArea === 1 ? "step-active" : ""}
                              onClick={() => this.scrollToSection("general")}
                            >
                              <b>1</b>
                              <span>General</span>
                            </li>
                            <li
                              className={visibleArea === 2 ? "step-active" : ""}
                              onClick={() => this.scrollToSection("experience")}
                            >
                              <b>2</b>
                              <span>Experience</span>
                            </li>
                            <li
                              className={visibleArea === 3 ? "step-active" : ""}
                              onClick={() =>
                                this.scrollToSection("description")
                              }
                            >
                              <b>3</b>
                              <span>Description</span>
                            </li>
                            <li
                              className={visibleArea === 4 ? "step-active" : ""}
                              onClick={() => this.scrollToSection("contact")}
                            >
                              <b>4</b>
                              <span>Contact</span>
                            </li>
                            <li
                              className={visibleArea === 5 ? "step-active" : ""}
                              onClick={() => this.scrollToSection("photos")}
                            >
                              <b>5</b>
                              <span>Photos</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mui-container">
              <div className="content header-padding header-padding-subheader">
                <div className="create-resume-page">
                  <div className="panel panel-withPadding clearfix">
                    <div className="resume create-resume">
                      <div className="resume-content">
                        <div>
                          <VisibilitySensor
                            onChange={isVisible =>
                              this.areaVisibilityChanged(isVisible, 1)
                            }
                            intervalCheck={false}
                            scrollCheck={true}
                            scrollDelay={10}
                            partialVisibility={true}
                          >
                            <div ref="general">
                              <div className="resume-header-info">
                                <div className="resume-title-header">
                                  <h2>
                                    General Info *
                                    <Form.Message
                                      className="has-error"
                                      for={[
                                        "firstName",
                                        "lastName",
                                        "birthday",
                                        "height",
                                        "maritalStatus",
                                      ]}
                                    >
                                      {messages => (
                                        <span>
                                          <FontAwesome name="exclamation-circle" />{" "}
                                          Please fix Errors
                                        </span>
                                      )}
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="mui-row">
                                    <div className="form-inline-half">
                                      <legend>
                                        First Name *
                                        <Form.Message
                                          className="has-error"
                                          for="firstName"
                                        />
                                      </legend>
                                      <Form.Field
                                        name="firstName"
                                        placeholder="First name"
                                        id="firstNameField"
                                      />
                                    </div>
                                    <div className="form-inline-half">
                                      <legend>
                                        Last Name *
                                        <Form.Message
                                          className="has-error"
                                          for="lastName"
                                        />
                                      </legend>
                                      <Form.Field
                                        name="lastName"
                                        placeholder="Last name"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div>
                                      <legend>
                                        Full Hebrew Name{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="fullName"
                                        />{" "}
                                        <span>
                                          <FontAwesome
                                            name="info-circle"
                                            data-multiline={true}
                                            data-tip={resumeTips.fullName}
                                          />
                                        </span>
                                      </legend>
                                      <Form.Field
                                        type="text"
                                        name="fullName"
                                        className="small-name"
                                        placeholder="Full Hebrew Name"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  ref="personalDetails"
                                  className="resume-header-details"
                                >
                                  <div className="resume-fields">
                                    <div className="mui-row">
                                      <div className="form-inline-half mui-col-md-6">
                                        <legend>
                                          Birthday *
                                          <Form.Message
                                            className="has-error"
                                            for="birthday"
                                          />{" "}
                                          <span>
                                            <FontAwesome
                                              name="info-circle"
                                              data-multiline={true}
                                              data-tip={resumeTips.dateOfBirth}
                                            />
                                          </span>
                                        </legend>
                                        <Form.Field
                                          type="date"
                                          name="birthday"
                                        />
                                      </div>
                                      <div className="form-inline-half mui-col-md-6">
                                        <legend>
                                          Height *
                                          <Form.Message
                                            className="has-error"
                                            for="height"
                                          />
                                        </legend>
                                        <Form.Field
                                          name="height"
                                          className=""
                                          as="select"
                                        >
                                          <option value="">
                                            Please choose a height
                                          </option>
                                          {_.range(56, 85).map((x, i) => (
                                            <option
                                              key={`height${i}`}
                                              value={x}
                                            >
                                              {Converter.heightToString(x)}
                                            </option>
                                          ))}
                                        </Form.Field>
                                      </div>
                                    </div>
                                    <div className="mui-row">
                                      <div className="form-inline-half mui-col-md-6">
                                        <legend>
                                          Marital Status *
                                          <Form.Message
                                            className="has-error"
                                            for="maritalStatus"
                                          />
                                        </legend>
                                        <Form.Field
                                          as={SelectList}
                                          data={constants.maritalStatus}
                                          mapFromValue={item => item.id}
                                          valueField="id"
                                          textField="alternateText"
                                          name="maritalStatus"
                                          className="form-input-margin-bottom form-inline inline-radio"
                                        />
                                      </div>
                                      {resume.maritalStatus > 1 ? (
                                        <div className="form-inline-half mui-col-md-6">
                                          <legend>
                                            Number of children *
                                            <Form.Message
                                              className="has-error"
                                              for="numberChildren"
                                            />
                                          </legend>
                                          <Form.Field
                                            name="numberChildren"
                                            className=""
                                            as="select"
                                          >
                                            <option disabled value="">
                                              {" "}
                                            </option>
                                            {_.range(0, 10).map((x, i) => (
                                              <option
                                                key={`noc${i}`}
                                                value={x.toString()}
                                              >
                                                {x}
                                              </option>
                                            ))}
                                            <option value={10}>10+</option>
                                          </Form.Field>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                ref="personalInfo"
                                className="resume-section"
                              >
                                <div className="resume-title-header">
                                  <h2>
                                    Personal Info *
                                    <Form.Message
                                      for={[
                                        "ethnicity",
                                        "languages",
                                        "religious",
                                        "address",
                                        "city",
                                        "state",
                                        "zip",
                                        "relocate",
                                      ]}
                                    >
                                      {messages => (
                                        <span>
                                          <FontAwesome name="exclamation-circle" />{" "}
                                          Please fix Errors
                                        </span>
                                      )}
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Ethnicity *
                                        <Form.Message
                                          className="has-error"
                                          for="ethnicity"
                                        />
                                      </legend>
                                      <Form.Field
                                        as={SelectList}
                                        data={constants.ethnicity}
                                        mapFromValue={item => item.id}
                                        valueField="id"
                                        textField="text"
                                        name="ethnicity"
                                        className="form-input-margin-bottom form-inline inline-radio radioBtn"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Languages{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="languages"
                                        />
                                      </legend>
                                      <Form.Field
                                        as={MultiSelect}
                                        className="react-tags"
                                        data={constants.languages}
                                        valueField="id"
                                        textField="text"
                                        name="languages"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div className="form-inline-half">
                                      <legend>
                                        Religious Background *
                                        <Form.Message
                                          className="has-error"
                                          for="religious"
                                        />
                                      </legend>
                                      <Form.Field
                                        as={SelectList}
                                        data={constants.religious}
                                        mapFromValue={item => item.id}
                                        valueField="id"
                                        textField="text"
                                        name="religious"
                                        className="form-input-margin-bottom form-inline inline-radio"
                                      />
                                    </div>
                                    {resume.gender === 1 ? (
                                      <div className="form-inline-half">
                                        <legend>
                                          Are you a Cohen?{" "}
                                          <Form.Message
                                            className="has-error"
                                            for="isCohen"
                                          />
                                        </legend>
                                        <Form.Field
                                          type="checkbox"
                                          className="mui-radio inline-radio"
                                          name="isCohen"
                                        />
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Street Address *
                                        <Form.Message
                                          className="has-error"
                                          for="address"
                                        />
                                      </legend>
                                      <Form.Field
                                        name="address"
                                        placeholder="Street Address"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div className="form-inline-third">
                                      <legend>
                                        City *
                                        <Form.Message
                                          className="has-error"
                                          for="city"
                                        />
                                      </legend>
                                      <Form.Field
                                        name="city"
                                        placeholder="City"
                                      />
                                    </div>
                                    <div className="form-inline-third">
                                      <legend>
                                        State *
                                        <Form.Message
                                          className="has-error"
                                          for="state"
                                        />
                                      </legend>
                                      <Form.Field
                                        name="state"
                                        placeholder="State"
                                      />
                                    </div>
                                    <div className="form-inline-third">
                                      <legend>
                                        Zip *
                                        <Form.Message
                                          className="has-error"
                                          for="zip"
                                        />
                                      </legend>
                                      <Form.Field
                                        name="zip"
                                        placeholder="Zip"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div className="form-inline-half mui-col-md-6">
                                      <legend>
                                        Hometown{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="hometown"
                                        />
                                      </legend>
                                      <Form.Field
                                        type="text"
                                        name="hometown"
                                        placeholder="Where did you grow up?"
                                      />
                                    </div>

                                    <div className="form-inline-half mui-col-md-6">
                                      <legend>
                                        Are you willing to relocate *
                                        <Form.Message
                                          className="has-error"
                                          for="relocate"
                                        />
                                      </legend>
                                      <Form.Field
                                        as={SelectList}
                                        data={constants.relocate}
                                        mapFromValue={item => item.id}
                                        valueField="id"
                                        textField="text"
                                        name="relocate"
                                        className="form-inline inline-radio radioBtn"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div ref="family" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>Family</h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="resume-fields-additional mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Family Information{" "}
                                        <span>
                                          <FontAwesome
                                            name="info-circle"
                                            data-multiline={true}
                                            data-tip={resumeTips.family}
                                          />
                                        </span>{" "}
                                      </legend>
                                      <Form.Field
                                        as={resizableTextArea}
                                        name="familyInfo.info"
                                        placeholder="Father's Name and Occupation, Mother's Name and Occupation, Siblings' Names and Ages (School/Occupation Names and Occupations) etc..."
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div ref="shuls" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>Shul / Synagogue</h2>
                                </div>
                                <div className="resume-fields">
                                  <ul>
                                    {resume.shuls.length
                                      ? resume.shuls.map((shul, i) => {
                                          return (
                                            <li
                                              key={`shul${i}`}
                                              className={
                                                resume.shuls.length > 1
                                                  ? "additional-item"
                                                  : ""
                                              }
                                            >
                                              {resume.shuls.length > 1 ? (
                                                <FontAwesome
                                                  name="times"
                                                  className="resume-fields-close"
                                                  onClick={() =>
                                                    this.deleteNestedObject(
                                                      "shuls",
                                                      i,
                                                    )
                                                  }
                                                />
                                              ) : null}
                                              <div className="mui-row">
                                                <div className="form-inline-third">
                                                  <legend>
                                                    Shul / Synagogue Name{" "}
                                                    <Form.Message
                                                      className="has-error"
                                                      for={`shuls[${i}].name`}
                                                    />
                                                  </legend>
                                                  <Form.Field
                                                    name={`shuls[${i}].name`}
                                                    placeholder="Shul / Synagogue Name"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-inline-third">
                                                  <legend>
                                                    Rabbi Name{" "}
                                                    <Form.Message
                                                      className="has-error"
                                                      for={`shuls[${i}].rabbi`}
                                                    />
                                                  </legend>
                                                  <Form.Field
                                                    name={`shuls[${i}].rabbi`}
                                                    placeholder="Rabbi's Name"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-inline-third">
                                                  <legend>
                                                    Rabbi Phone{" "}
                                                    <Form.Message
                                                      className="has-error"
                                                      for={`shuls[${i}].rabbiPhone`}
                                                    />
                                                  </legend>
                                                  <Form.Field
                                                    name={`shuls[${i}].rabbiPhone`}
                                                    placeholder="Rabbi's number"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="mui-row">
                                                <div className="mui-col-md-12">
                                                  <legend>
                                                    Additional Information
                                                  </legend>
                                                  <Form.Field
                                                    name={`shuls[${i}].details`}
                                                    placeholder="Additional Information"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                  <div
                                    className="mui-row clearfix"
                                    onClick={this.addNestedObject.bind(
                                      this,
                                      "shuls",
                                    )}
                                  >
                                    <div className="resume-addItem">
                                      <FontAwesome name="plus" />
                                      <span> Add Shul / Synagogue</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </VisibilitySensor>
                          <VisibilitySensor
                            onChange={isVisible =>
                              this.areaVisibilityChanged(isVisible, 2)
                            }
                            intervalCheck={false}
                            scrollCheck={true}
                            scrollDelay={10}
                            partialVisibility={true}
                          >
                            <div ref="experience">
                              <div ref="education" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>
                                    Education Info * &nbsp;
                                    <span>
                                      <FontAwesome
                                        name="info-circle"
                                        data-multiline={true}
                                        data-tip={resumeTips.education}
                                      />
                                    </span>
                                    <Form.Message
                                      className="has-error"
                                      for="education"
                                    />
                                    <Form.Message group="education">
                                      {({ messages }) =>
                                        _.isEmpty(messages) ? null : (
                                          <span className="validation-error">
                                            <FontAwesome name="exclamation-circle" />{" "}
                                            Please fix Errors
                                          </span>
                                        )
                                      }
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <ul>
                                    {resume.education.length
                                      ? resume.education.map((ed, i) => {
                                          return (
                                            <li
                                              key={`education${i}`}
                                              className={
                                                resume.education.length > 1
                                                  ? "additional-item"
                                                  : ""
                                              }
                                            >
                                              {resume.education.length > 1 ? (
                                                <FontAwesome
                                                  name="times"
                                                  className="resume-fields-close"
                                                  onClick={() =>
                                                    this.deleteNestedObject(
                                                      "education",
                                                      i,
                                                    )
                                                  }
                                                />
                                              ) : null}
                                              <div className="mui-row">
                                                <div className="form-inline-half mui-col-md-6">
                                                  <legend>
                                                    Place of Education{" "}
                                                    <Form.Message
                                                      className="has-error"
                                                      for={[
                                                        `education[${i}].name`,
                                                      ]}
                                                    />
                                                  </legend>
                                                  <Form.Field
                                                    group="education"
                                                    name={`education[${i}].name`}
                                                    validates={[`education`]}
                                                    placeholder="Name of School or Place of Education"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-inline-half mui-col-md-6">
                                                  <legend>Details</legend>
                                                  <Form.Field
                                                    group="education"
                                                    name={`education[${i}].details`}
                                                    validates={[
                                                      `education`,
                                                      `education[${i}].name`,
                                                    ]}
                                                    placeholder="Years and/or any additional details"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                  <div
                                    className="mui-row clearfix"
                                    onClick={this.addNestedObject.bind(
                                      this,
                                      "education",
                                    )}
                                  >
                                    <div className="resume-addItem">
                                      <FontAwesome name="plus" />
                                      <span> Add Education</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div ref="occupation" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>
                                    Occupation *
                                    <Form.Message for={["occupation.name"]}>
                                      {messages => (
                                        <span>
                                          <FontAwesome name="exclamation-circle" />{" "}
                                          Please fix Errors
                                        </span>
                                      )}
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <ul>
                                    {resume.occupation ? (
                                      <li key="occupation">
                                        <div className="mui-row">
                                          <div className="mui-col-md-12">
                                            <legend>
                                              Position *
                                              <Form.Message
                                                className="has-error"
                                                for="occupation.name"
                                              />
                                            </legend>
                                            <Form.Field
                                              name={`occupation.name`}
                                              placeholder="Title / Position at Company"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                        <div className="mui-row">
                                          <div className="mui-col-md-12">
                                            <legend>Details</legend>
                                            <Form.Field
                                              name={`occupation.details`}
                                              placeholder="Years and/or any additional details"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                              <div ref="camps" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>
                                    Camps
                                    <Form.Message group="camps">
                                      {({ messages }) =>
                                        _.isEmpty(messages) ? null : (
                                          <span className="validation-error">
                                            <FontAwesome name="exclamation-circle" />{" "}
                                            Please fix Errors
                                          </span>
                                        )
                                      }
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <ul>
                                    {resume.camps.length
                                      ? resume.camps.map((camp, i) => {
                                          return (
                                            <li
                                              key={`camp${i}`}
                                              className={
                                                resume.camps.length > 1
                                                  ? "additional-item"
                                                  : ""
                                              }
                                            >
                                              {resume.camps.length > 1 ? (
                                                <FontAwesome
                                                  name="times"
                                                  className="resume-fields-close"
                                                  onClick={() =>
                                                    this.deleteNestedObject(
                                                      "camps",
                                                      i,
                                                    )
                                                  }
                                                />
                                              ) : null}
                                              <div className="mui-row">
                                                <div className="form-inline-half mui-col-md-6">
                                                  <legend>
                                                    Camp Name{" "}
                                                    <Form.Message
                                                      className="has-error"
                                                      for={[`camps[${i}].name`]}
                                                    />
                                                  </legend>
                                                  <Form.Field
                                                    group="camps"
                                                    validates={["camps"]}
                                                    name={`camps[${i}].name`}
                                                    placeholder="Camp Name"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-inline-half mui-col-md-6">
                                                  <legend>Details </legend>
                                                  <Form.Field
                                                    group="camps"
                                                    validates={["camps"]}
                                                    name={`camps[${i}].details`}
                                                    placeholder="Details"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                  <div className="mui-row clearfix">
                                    <div
                                      className="resume-addItem"
                                      onClick={() =>
                                        this.addNestedObject("camps")
                                      }
                                    >
                                      <FontAwesome name="plus" />
                                      <span> Add Camp</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div ref="volunteer" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>
                                    Other / Organization / Volunteer Work
                                    <Form.Message group="volunteer">
                                      {({ messages }) =>
                                        _.isEmpty(messages) ? null : (
                                          <span className="validation-error">
                                            <FontAwesome name="exclamation-circle" />{" "}
                                            Please fix Errors
                                          </span>
                                        )
                                      }
                                    </Form.Message>
                                    <Form.Message for={["volunteer"]}>
                                      {messages => "Please fix errors"}
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <ul>
                                    {resume.volunteer.length
                                      ? resume.volunteer.map((volunteer, i) => {
                                          return (
                                            <li
                                              key={`volunteer${i}`}
                                              className={
                                                resume.volunteer.length > 1
                                                  ? "additional-item"
                                                  : ""
                                              }
                                            >
                                              {resume.volunteer.length > 1 ? (
                                                <FontAwesome
                                                  name="times"
                                                  className="resume-fields-close"
                                                  onClick={() =>
                                                    this.deleteNestedObject(
                                                      "volunteer",
                                                      i,
                                                    )
                                                  }
                                                />
                                              ) : null}

                                              <Form.Message
                                                for={[`volunteer[${i}]`]}
                                              />
                                              <div className="mui-row">
                                                <div className="form-inline-half mui-col-md-6">
                                                  <legend>
                                                    Organization Name{" "}
                                                    <Form.Message
                                                      className="has-error"
                                                      for={[
                                                        `volunteer[${i}].name`,
                                                      ]}
                                                    />
                                                  </legend>
                                                  <Form.Field
                                                    validates={["volunteer"]}
                                                    group="volunteer"
                                                    name={`volunteer[${i}].name`}
                                                    placeholder="Organization Name"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-inline-half mui-col-md-6">
                                                  <legend>Details</legend>
                                                  <Form.Field
                                                    validates={["volunteer"]}
                                                    group="volunteer"
                                                    type="text"
                                                    name={`volunteer[${i}].details`}
                                                    placeholder="Details"
                                                  />
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                  <div className="mui-row clearfix">
                                    <div
                                      className="resume-addItem"
                                      onClick={() =>
                                        this.addNestedObject("volunteer")
                                      }
                                    >
                                      <FontAwesome name="plus" />
                                      <span> Add</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </VisibilitySensor>
                          <VisibilitySensor
                            onChange={isVisible =>
                              this.areaVisibilityChanged(isVisible, 3)
                            }
                            intervalCheck={false}
                            scrollCheck={true}
                            scrollDelay={10}
                            partialVisibility={true}
                          >
                            <div ref="description">
                              <div className="resume-section">
                                <div className="resume-title-header">
                                  <h2>Type</h2>
                                </div>
                                <div className="mui-row">
                                  <div className="mui-col-md-12">
                                    <legend>
                                      Type *
                                      <Form.Message
                                        className="has-error"
                                        for="type"
                                      />
                                    </legend>
                                    <Form.Field
                                      as={SelectList}
                                      data={constants.types}
                                      mapFromValue={item => item.id}
                                      valueField="id"
                                      textField="text"
                                      name="type"
                                      className="form-input-margin-bottom form-inline inline-radio radioBtn"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div
                                ref="personalDescription"
                                className="resume-section"
                              >
                                <div className="resume-title-header">
                                  <h2>Personal Description</h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Personal Description *{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="personalDescription"
                                        />{" "}
                                        <span>
                                          <FontAwesome
                                            name="info-circle"
                                            data-multiline={true}
                                            data-tip={resumeTips.description}
                                          />
                                        </span>{" "}
                                      </legend>
                                      <Form.Field
                                        name="personalDescription"
                                        placeholder="Im a friendly, easygoing and warm person. I try to be a good listener and to be considerate of other people. Some of my favorite activities are playing sports, reading, watching interesting movies and spending time outdoors. I appreciate my quiet time at home too. Kindness is a very important quality to me and I believe in being flexible and having an open mind."
                                        as={resizableTextArea}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div ref="lookingFor" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>Looking For</h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Looking For{" "}
                                        <span>
                                          <FontAwesome
                                            name="info-circle"
                                            data-multiline={true}
                                            data-tip={resumeTips.lookingFor}
                                          />
                                        </span>{" "}
                                      </legend>
                                      <Form.Field
                                        name="lookingFor"
                                        placeholder="I'm looking for a life partner and see a healthy relationship as being best friends who look up to each other, respect each other, grow together, and make each other happy."
                                        as={resizableTextArea}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </VisibilitySensor>
                        </div>

                        <div>
                          <VisibilitySensor
                            onChange={isVisible =>
                              this.areaVisibilityChanged(isVisible, 4)
                            }
                            intervalCheck={false}
                            scrollCheck={true}
                            scrollDelay={10}
                            partialVisibility={true}
                          >
                            <div ref="contact">
                              <div
                                ref="personalContactInfo"
                                className="resume-section"
                              >
                                <div className="resume-title-header">
                                  <h2>
                                    {resume.firstName
                                      ? `${resume.firstName}'s `
                                      : ""}{" "}
                                    Personal Contact Info *
                                    <span className="contact-checkbox">
                                      <label className="label-title">
                                        <span>Display this info on resume</span>
                                        <Form.Field
                                          validates={[
                                            "contactName",
                                            "contactRelationship",
                                            "contactPhone",
                                            "contactEmail",
                                          ]}
                                          type="checkbox"
                                          name="displayPersonalContactInfo"
                                        />
                                      </label>
                                    </span>
                                    <Form.Message
                                      for={[
                                        "mobilePhone",
                                        "homePhone",
                                        "email",
                                        "shidduchContactInfo",
                                      ]}
                                    >
                                      {messages => (
                                        <span>
                                          <FontAwesome name="exclamation-circle" />{" "}
                                          Please fix Errors
                                        </span>
                                      )}
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="mui-row">
                                    <div className="form-inline-third mui-col-md-3">
                                      <legend>
                                        Cell phone *
                                        <Form.Message
                                          className="has-error"
                                          for="mobilePhone"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Cell phone"
                                        name="mobilePhone"
                                        type="text"
                                      />
                                    </div>
                                    <div className="form-inline-third mui-col-md-3">
                                      <legend>
                                        Home phone{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="homePhone"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Home phone"
                                        name="homePhone"
                                        type="text"
                                      />
                                    </div>
                                    <div className="form-inline-third mui-col-md-3">
                                      <legend>
                                        Email *
                                        <Form.Message
                                          className="has-error"
                                          for="email"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Email"
                                        name="email"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div className="mui-col-md-12">
                                      <legend>
                                        Advice for contacting me{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="shidduchContactInfo"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Advice for contacting me"
                                        name="shidduchContactInfo"
                                        as={resizableTextArea}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div ref="contact" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>
                                    Resume Contact Info
                                    <Form.Message
                                      for={[
                                        "contactName",
                                        "contactRelationship",
                                        "contactPhone",
                                        "contactEmail",
                                      ]}
                                    >
                                      {messages => (
                                        <span>
                                          <FontAwesome name="exclamation-circle" />{" "}
                                          Please fix Errors
                                        </span>
                                      )}
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <div className="mui-row">
                                    <div className="form-inline-half mui-col-md-6">
                                      <legend>
                                        Contact Name{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="contactName"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Name"
                                        name="contactName"
                                        type="text"
                                      />
                                    </div>
                                    <div className="form-inline-half mui-col-md-6">
                                      <legend>
                                        Relationship{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="contactRelationship"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Parent, Shadchan etc.."
                                        name="contactRelationship"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="mui-row">
                                    <div className="form-inline-half mui-col-md-6">
                                      <legend>
                                        Contact Phone{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="contactPhone"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Phone"
                                        name="contactPhone"
                                        type="text"
                                      />
                                    </div>
                                    <div className="form-inline-half mui-col-md-6">
                                      <legend>
                                        Contact Email{" "}
                                        <Form.Message
                                          className="has-error"
                                          for="contactEmail"
                                        />
                                      </legend>
                                      <Form.Field
                                        placeholder="Email"
                                        name="contactEmail"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div ref="references" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>
                                    References *
                                    <span>
                                      <FontAwesome
                                        name="info-circle"
                                        data-multiline={true}
                                        data-tip={resumeTips.references}
                                      />
                                    </span>
                                    <Form.Message
                                      className="has-validation-error"
                                      for="references"
                                    />
                                    <Form.Message group="references">
                                      {({ messages }) =>
                                        _.isEmpty(messages) ? null : (
                                          <span className="validation-error">
                                            <FontAwesome name="exclamation-circle" />{" "}
                                            Please fix Errors
                                          </span>
                                        )
                                      }
                                    </Form.Message>
                                  </h2>
                                </div>
                                <div className="resume-fields">
                                  <ul>
                                    {references.map((reference, i) => {
                                      if (!reference) return null;
                                      const { contactInfo = [] } = reference;
                                      return (
                                        <li
                                          key={`references${i}`}
                                          className="additional-item"
                                        >
                                          {references.length > 2 && (
                                            <FontAwesome
                                              name="times"
                                              className="resume-fields-close"
                                              onClick={() =>
                                                this.deleteNestedObject(
                                                  "references",
                                                  i,
                                                )
                                              }
                                            />
                                          )}
                                          <div className="mui-row">
                                            <div className="form-inline-half mui-col-md-6">
                                              <legend>
                                                Reference Name *
                                                <Form.Message
                                                  className="has-error"
                                                  for={`references[${i}].name`}
                                                />
                                              </legend>
                                              <Form.Field
                                                group="references"
                                                name={`references[${i}].name`}
                                                validates={[`references`]}
                                                placeholder="Reference Name"
                                                type="text"
                                              />
                                            </div>
                                            <div className="form-inline-half mui-col-md-6">
                                              <legend>
                                                Reference Type *
                                                <Form.Message
                                                  className="has-error"
                                                  for={`references[${i}].referenceType`}
                                                />
                                              </legend>
                                              <Form.Field
                                                group="references"
                                                name={`references[${i}].referenceType`}
                                                className="reference-select details-text"
                                                as="select"
                                              >
                                                <option disabled value="">
                                                  Choose a reference type
                                                </option>
                                                <option value="Personal">
                                                  Personal
                                                </option>
                                                <option value="Family">
                                                  Family
                                                </option>
                                                <option value="Professional">
                                                  Professional
                                                </option>
                                              </Form.Field>
                                            </div>
                                          </div>
                                          <div className="mui-row">
                                            <div className="form-inline-half mui-col-md-12">
                                              <legend>
                                                Reference Number or Email
                                              </legend>
                                              {contactInfo.map((_, i2) => {
                                                return (
                                                  <div key={`refInfo${i2}`}>
                                                    {contactInfo.length > 1 ? (
                                                      <FontAwesome
                                                        name="times"
                                                        className="resume-fields-close"
                                                        onClick={this.deleteDoubleNestedObject.bind(
                                                          this,
                                                          "references",
                                                          i,
                                                          "contactInfo",
                                                          i2,
                                                        )}
                                                      />
                                                    ) : null}
                                                    <div
                                                      className={
                                                        contactInfo.length > 1
                                                          ? "reference-item"
                                                          : ""
                                                      }
                                                    >
                                                      <Form.Field
                                                        group="references"
                                                        name={`references[${i}].contactInfo[${i2}].contactText`}
                                                        validates={[
                                                          `references[${i}].contactInfo`,
                                                          `references[${i}]`,
                                                          `references`,
                                                        ]}
                                                        placeholder="Reference Number or Email"
                                                        type="text"
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                              <div
                                                className="resume-addItem resume-addItem-simple"
                                                onClick={() =>
                                                  this.addDoubleNestedObject(
                                                    "references",
                                                    i,
                                                    "contactInfo",
                                                  )
                                                }
                                              >
                                                <FontAwesome name="plus" />
                                                <span>
                                                  &nbsp;Add Number or Email
                                                </span>
                                              </div>
                                            </div>

                                            <div className="mui-col-md-12 form-inline-half">
                                              <legend>
                                                Additional Information
                                                <Form.Message
                                                  className="has-error"
                                                  for={`references[${i}].details`}
                                                />
                                              </legend>
                                              <Form.Field
                                                group="references"
                                                name={`references[${i}].details`}
                                                placeholder="Additional Details"
                                                as={resizableTextArea}
                                              />
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div
                                    className="mui-row clearfix"
                                    onClick={() =>
                                      this.addNestedObject("references")
                                    }
                                  >
                                    <div className="resume-addItem">
                                      <FontAwesome name="plus" />
                                      <span> Add Reference</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </VisibilitySensor>
                          <VisibilitySensor
                            onChange={isVisible =>
                              this.areaVisibilityChanged(isVisible, 5)
                            }
                            intervalCheck={false}
                            scrollCheck={true}
                            scrollDelay={10}
                            partialVisibility={true}
                          >
                            <div ref="photos">
                              <div ref="images" className="resume-section">
                                <div className="resume-title-header">
                                  <h2>Photos</h2>
                                </div>
                                <div className="resume-fields">
                                  {resume.images && resume.images.length
                                    ? resume.images.map((image, index) => {
                                        return (
                                          <div
                                            key={`image${index}`}
                                            className="resumePicPreview mui-row"
                                          >
                                            <div className="resume-pic-container">
                                              <FontAwesome
                                                name="trash"
                                                className="group-color-light mui--text-center"
                                                onClick={this.removeImage.bind(
                                                  this,
                                                  index,
                                                )}
                                              />
                                              <PrivateImage
                                                fileName={image.fileLocation}
                                                id={resume.id || 0}
                                                section="resumeSmall"
                                                className="dragDropPreview"
                                                myResume={true}
                                              />
                                              {index !== 0 ? (
                                                <span
                                                  className="make-primary"
                                                  onClick={this.setImageAsMain.bind(
                                                    this,
                                                    index,
                                                  )}
                                                >
                                                  Make Primary
                                                </span>
                                              ) : null}
                                            </div>
                                          </div>
                                        );
                                      })
                                    : null}

                                  <Dropzone
                                    onDropRejected={() =>
                                      this.setState({ imageRejected: true })
                                    }
                                    onDropAccepted={this.onDrop}
                                    multiple={false}
                                    accept="image/*"
                                    className={
                                      resume.images && resume.images.length > 1
                                        ? "dragDrop dragSquare"
                                        : "dragDrop"
                                    }
                                    activeClassName={
                                      resume.images && resume.images.length > 1
                                        ? "dragDropActive dragSquare"
                                        : "dragDropActive"
                                    }
                                  >
                                    <div>
                                      <FontAwesome
                                        name="upload"
                                        className="group-color-light mui--text-center"
                                      />
                                      <h4 className="mui--text-center">
                                        Drop a .jpg or .png file here, or{" "}
                                        <span className="dragDropFileBtn">
                                          select file to upload
                                        </span>
                                      </h4>
                                    </div>
                                    {this.state.imageTooLarge ? (
                                      <span>
                                        That image is too big. Try another one!
                                      </span>
                                    ) : null}
                                    {this.state.imageRejected ? (
                                      <span>
                                        Whoops... that image won't work. Try
                                        again!
                                      </span>
                                    ) : null}
                                  </Dropzone>
                                </div>
                              </div>
                            </div>
                          </VisibilitySensor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="resume-sidebar-container">
                  <div className="resume-messageBar">
                    <div className="resume-messageBar-content">
                      {isLoading && resume && resume.id && saved ? (
                        <p className="saving">
                          <FontAwesome name="save" /> Saving...
                        </p>
                      ) : null}

                      {edited ? (
                        <p className="unsaved">
                          <FontAwesome name="pencil" /> You have unsaved changes
                        </p>
                      ) : !isLoading && resume.id ? (
                        <p className="saved">
                          <FontAwesome name="check" /> All changes saved
                        </p>
                      ) : null}

                      {_.isEmpty(errors) ? null : (
                        <p className="validation-error-general validation-error">
                          <FontAwesome name="exclamation-circle" />
                          Please fix errors
                        </p>
                      )}

                      <div className="my-resume-edit-btns">
                        <div className="btn-resume-save">
                          <Form.Submit className="btn-secondary btn-flat">
                            {resume.id ? "Update Resume" : "Save Resume"}
                          </Form.Submit>
                        </div>

                        {resume.id && !edited ? (
                          <Link
                            to={`/myResume`}
                            className="btn-secondary btn-flat"
                          >
                            View Resume
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReactTooltip effect="solid" html={true} />
          </Form>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(resumeActions, dispatch),
  };
}

function mapStateToProps(state) {
  const {
    constants,
    resumes: { editResume: resume, isLoading, errorLoadingResume },
    user: { id: userId, resumeId },
  } = state;
  return {
    constants,
    resume,
    isLoading,
    errorLoadingResume,
    resumeId,
    userId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditResume);
