import React from "react";
import * as modalActions from "../../../actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Resume from "../../resumes/Resume";

class MemberResumeModal extends React.Component {
  render() {
    return (
      <div className="idea-modal large-modal">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <i
            className="material-icons modal-close-icon"
            style={{ fontSize: "36px" }}
            onClick={this.props.removeModal.bind(this)}
          >
            close
          </i>
        </div>
        <Resume noPadding={true} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(MemberResumeModal);
