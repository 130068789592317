import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import * as yup from "yup";
import Form from "react-formal";

const userInviteSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
});

class HeaderInviteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invite: {},
      errors: {},
      sending: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.inviteUserSuccess && nextProps.inviteUserSuccess) {
      this.setState({ sending: false, invite: {}, sent: true });
      setTimeout(() => {
        this.props.closeInvite();
        this.setState({ sent: false });
      }, 3000);
    }
  }

  inviteUser(values) {
    this.setState({ sending: true }, () => {
      this.props.actions.inviteUser(values.email);
    });
  }

  render() {
    return (
      <div
        className={
          this.props.miniMode
            ? "invite-submenu header-submenu"
            : "header-dropdown-content"
        }
      >
        {this.state.sent ? (
          <div>Invite Sent</div>
        ) : (
          <Form
            value={this.state.invite}
            schema={userInviteSchema}
            onChange={invite => this.setState({ invite })}
            onSubmit={this.inviteUser.bind(this)}
            id="form"
          >
            <legend>
              Email <Form.Message className="has-error" for="email" />
            </legend>
            <Form.Field
              type="text"
              name="email"
              events={["onChange"]}
              placeholder="Enter email address"
              className="form-input-margin-bottom"
            />
            {this.state.sending ? (
              <button className="btn-flat share-btn">
                <div className="loader-small"></div>
              </button>
            ) : (
              <Form.Submit type="submit" className="btn-flat share-btn">
                Share
              </Form.Submit>
            )}
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inviteUserSuccess: state.user.inviteUserSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderInviteUser);
