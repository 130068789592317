import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shadchanActions from "../../../actions/shadchanActions";
import * as resumeActions from "../../../actions/resumeActions";
import * as modalActions from "../../../actions/modalActions";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import history from "../../../lib/history";
import SweetAlert from "react-bootstrap-sweetalert";

class ShadchanMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      mobileSectionOpen: "",
    };
    this.clearAlert = this.clearAlert.bind(this);
  }

  acceptMemberRequest(memberId) {
    this.props.actions.acceptMemberRequest(memberId);
  }

  clearAlert() {
    this.setState({ alert: null });
  }

  viewResume = resumeId => {
    const { resumeActions, modalActions } = this.props;
    resumeActions.getResumeById(resumeId);
    modalActions.setModal("MEMBER_RESUME_MODAL", {});
  };

  declineMemberRequest(memberId) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete member request!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => {
            this.props.actions.declineMemberRequest(memberId);
            this.clearAlert();
          }}
          onCancel={this.clearAlert}
        >
          You will not be able to recover this request.
        </SweetAlert>
      ),
    });
  }

  removeMember = memberId => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete member!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => {
            this.props.actions.removeMember(memberId);
            this.clearAlert();
          }}
          onCancel={this.clearAlert}
        >
          You will not be able to recover this member without reinviting.
        </SweetAlert>
      ),
    });
  };

  onMobileMenuClick(activeMenu) {
    this.setState({ mobileSectionOpen: activeMenu });
  }

  onMobileMenuHeaderClick(menu, event) {
    if (event) {
      //stop it from reopening the menu
      event.stopPropagation();
    }
    this.setState({
      mobileSectionOpen: this.state.mobileSectionOpen === menu ? "" : menu,
    });
  }

  memberInviteList(member) {
    // const isAdmin = this.props.role === "admin";
    const { id, dateInvited, email, firstName, lastName } = member;
    const formattedDateInvited = moment(dateInvited).format("MMMM Do, YYYY");

    return (
      <div className="mui-row list-items" key={id}>
        <div className="list-item">
          <div className="list-item-info">
            <h5>
              {firstName} {lastName}
            </h5>
            <h6 className="list-item-secondary">{email}</h6>
            <p>
              <span>Invited on:</span> {formattedDateInvited}
            </p>
          </div>
          <button
            className="btn-flat btn-secondary btn-fullWidth"
            onClick={() =>
              this.props.actions.withdrawInviteMemberToGroup(member.id)
            }
          >
            Withdraw Invite
          </button>
          <button
            className="btn-flat btn-secondary btn-fullWidth"
            onClick={() =>
              this.props.actions.resendInviteMemberToGroup(member.id)
            }
          >
            Resend Invite
          </button>
          {member.inviteResent ? (
            <p className="sent">
              <FontAwesome name="check" /> Sent
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  memberRequestList(member) {
    const isAdmin = this.props.role === "admin";
    const { id, dateRequested, email, firstName, lastName, resumeId } = member;
    const formattedDateRequested = moment(dateRequested).format(
      "MMMM Do, YYYY",
    );

    return (
      <div className="mui-row list-items" key={id}>
        <div className="list-item">
          <div className="list-item-info">
            <h5>
              {firstName} {lastName}
            </h5>
            <h6 className="list-item-secondary">{email}</h6>
            <p>
              <span>Requested on:</span> {formattedDateRequested}
            </p>

            {!isAdmin && (
              <div className="groups-resume-actions">
                <p
                  className="list-item-info-button"
                  onClick={() => this.viewResume(resumeId)}
                >
                  View
                </p>
                <p
                  className="list-item-info-button"
                  onClick={() => this.acceptMemberRequest(id)}
                >
                  Accept
                </p>
                <p
                  className="list-item-info-button"
                  onClick={() => this.declineMemberRequest(id)}
                >
                  Decline
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    let {
      acceptedMembers,
      //invitedMembers,
      requestedMembers,
      role,
    } = this.props;
    const isAdmin = role === "admin";
    let columnRequestedMembers;
    if (requestedMembers.length) {
      columnRequestedMembers = requestedMembers.map(member =>
        this.memberRequestList(member),
      );
    } else {
      columnRequestedMembers = (
        <div className="message-sidebar-empty">
          <FontAwesome
            name="user"
            className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection"
          />
          <p>No people requested to join</p>
        </div>
      );
    }

    return (
      <div className="mui-row list-items-container">
        <div className="mui-col-md-12">
          <div
            ref="activeMembers"
            className={
              this.state.mobileSectionOpen === "activeMembers"
                ? "column member-menu-active"
                : "column"
            }
            onClick={() => this.onMobileMenuClick("activeMembers")}
          >
            <div
              className="column-heading"
              onClick={() => this.onMobileMenuHeaderClick("activeMembers")}
            >
              <h3>
                My Active Members{" "}
                <span className="circle">{acceptedMembers.length}</span>
              </h3>
              <FontAwesome name="caret-down" className="mobile-members-open" />
            </div>
            <div className="column-content">
              {acceptedMembers.length > 0 &&
                acceptedMembers.map(member => {
                  const {
                    firstName,
                    lastName,
                    email,
                    id,
                    conversationId,
                    // resumeId,
                    dateAccepted,
                  } = member;
                  const formattedDateAccepted = moment(dateAccepted).format(
                    "MMMM Do, YYYY",
                  );

                  return (
                    <div key={id} className="mui-row list-items">
                      <div className="list-item">
                        <h5>
                          {firstName} {lastName}
                        </h5>
                        <h6 className="list-item-secondary">{email}</h6>
                        <p>
                          <span>Accepted on:</span> {formattedDateAccepted}
                        </p>
                        {!isAdmin && (
                          <div className="groups-resume-actions">
                            <p
                              className="list-item-info-button"
                              onClick={() =>
                                history.push(`/inbox/${conversationId}`)
                              }
                            >
                              Message
                            </p>
                            <p
                              className="list-item-info-button"
                              onClick={() => this.removeMember(id)}
                            >
                              Remove
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* <div
            ref="invitedMembers"
            className={
              this.state.mobileSectionOpen === "invitedMembers"
                ? "column member-menu-active"
                : "column"
            }
            onClick={() => this.onMobileMenuClick("invitedMembers")}
          >
            <div
              className="column-heading"
              onClick={() => this.onMobileMenuHeaderClick("invitedMembers")}
            >
              <h3>
                Invited Members{" "}
                <span className="circle">{invitedMembers.length}</span>
              </h3>
              <FontAwesome name="caret-down" className="mobile-members-open" />
            </div>
            <div className="column-content">
              {invitedMembers.length > 0 ? (
                invitedMembers.map(member => this.memberInviteList(member))
              ) : (
                <div className="message-sidebar-empty">
                  <FontAwesome
                    name="file-o"
                    className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection"
                  />
                  <p>No members have been invited</p>
                </div>
              )}
              {!isAdmin && (
                <p
                  className="btn-flat btn-sidebar"
                  // onClick={() => this.inviteMemberToGroup()}
                >
                  Invite member
                  <FontAwesome name="arrow-right" />
                </p>
              )}
            </div>
          </div> */}
          <div
            ref="requestedMembers"
            className={
              this.state.mobileSectionOpen === "requestedMembers"
                ? "column member-menu-active"
                : "column"
            }
            onClick={() => this.onMobileMenuClick("requestedMembers")}
          >
            <div
              className="column-heading"
              onClick={() => this.onMobileMenuHeaderClick("requestedMembers")}
            >
              <h3>
                Requested Members{" "}
                <span className="circle">{requestedMembers.length}</span>
              </h3>
              <FontAwesome name="caret-down" className="mobile-members-open" />
            </div>
            <div className="column-content">{columnRequestedMembers}</div>
          </div>
        </div>
        {this.state.alert}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    shadchan: { acceptedMembers, invitedMembers, requestedMembers },
    user: { role },
  } = state;
  return {
    role,
    acceptedMembers,
    invitedMembers,
    requestedMembers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(shadchanActions, dispatch),
    resumeActions: bindActionCreators(resumeActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShadchanMembers);
