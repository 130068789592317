import axios from "axios";
import history from "../lib/history";
import { updateCookie } from "./userActions";
const cookie = require("cookie");
const baseURL = process.env.REACT_APP_API_URL;

export function getConfig() {
  let ztCookie = cookie.parse(document.cookie).zivugtechToken;
  if (ztCookie) {
    updateCookie(ztCookie, 2);
    return {
      headers: { "x-access-token": ztCookie },
    };
  } else {
    let nextRoute = history.location.pathname;
    window.location.replace(`/login${nextRoute ? `?next=${nextRoute}` : ""}`);
  }
}

export async function apiAuthRequest(method, requestUrl, data, cancelToken) {
  try {
    let config = getConfig();
    if (method === "post") {
      config.data = data;
    } else {
      config.params = data;
    }
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    let response = await axios({
      method: method,
      url: `${baseURL}${requestUrl}`,
      ...config,
    });

    if (response.cancelled) {
      return {
        success: false,
        cancelled: true,
        data: response.data,
      };
    } else {
      return {
        success: true,
        data: response.data,
      };
    }
  } catch (error) {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      //not logged in
      let nextRoute = history.location.pathname;
      window.location.replace(`/login${nextRoute ? `?next=${nextRoute}` : ""}`);
    } else if (status === 403) {
      //user does not have access to this api
      window.location.replace(`/login`);
      return {
        success: false,
        data:
          error.response && error.response.data
            ? error.response.data.message
            : "Unauthorized request",
      };
    } else if (status === 400) {
      //bad data
      return {
        success: false,
        data:
          error.response && error.response.data
            ? error.response.data.message
            : "An error has occurred, please try again later",
      };
    }
    return {
      success: false,
      data:
        error.response && error.response.data
          ? error.response.data.message
          : "Unknown error - check your internet connection",
    };
  }
}

export async function apiRequest(method, requestUrl, data) {
  try {
    let config = {};
    if (method === "post") {
      config.data = data;
    } else {
      config.params = data;
    }
    let response = await axios({
      method: method,
      url: `${baseURL}${requestUrl}`,
      ...config,
    });
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null;

    if (status === 403) {
      //user does not have access to this api
      return {
        success: false,
        data:
          error.response && error.response.data
            ? error.response.data.message
            : "Unauthorized request",
      };
    } else if (status === 400) {
      //bad data
      return {
        success: false,
        data:
          error.response && error.response.data
            ? error.response.data.message
            : "An error has occurred, please try again later",
      };
    }
    return {
      success: false,
      data:
        error.response && error.response.data
          ? error.response.data.message
          : "Unknown error - check your internet connection",
    };
  }
}
