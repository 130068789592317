import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shadchanActions from "../../actions/shadchanActions";
import * as yup from "yup";
import Form from "react-formal";
import SelectList from "react-widgets/lib/SelectList";
import resizableTextArea from "../common/resizableTextArea";
import { Helmet } from "react-helmet";
import MultiSelect from "react-widgets/lib/Multiselect";
import history from "../../lib/history";
import FontAwesome from "react-fontawesome";
import _ from "lodash";

const editShadchanSchema = yup.object().shape({
  about: yup.string().nullable(),
  languages: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      text: yup.string(),
    }),
  ),
  shadchanEthnicityId: yup.number().required("Required"),
  shadchanTypeId: yup.number().required("Required"),
  specialties: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      text: yup.string(),
    }),
  ),
  types: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      text: yup.string(),
    }),
  ),
  ageStart: yup.number().nullable(),
  ageEnd: yup.number().nullable(),
});

class ShadchanRegistrationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shadchan: {
        languages: [],
        types: [],
        specialties: [],
      },

      submitting: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.actions.getMyShadchanProfile();
  }

  componentDidUpdate(prevProps) {
    const shadchan = this.props.shadchan;
    if (prevProps.shadchan !== shadchan) {
      const {
        about,
        ageStart,
        ageEnd,
        shadchanEthnicityId,
        shadchanTypeId,
        types,
        specialties,
        languages,
      } = shadchan;
      this.setState({
        shadchan: {
          about,
          ageStart,
          ageEnd,
          shadchanEthnicityId,
          shadchanTypeId,
          types,
          specialties,
          languages,
        },
      });
    }
  }

  handleChange(model) {
    this.setState({ shadchan: model });
  }

  async submit(values) {
    this.setState({ submitting: true });
    const updated = await this.props.actions.editShadchanProfile(values);
    if (updated) {
      return history.push("/myShadchanProfile");
    }
    this.setState({ submitting: false });
  }

  render() {
    const {
      props: { constants },
      state: { shadchan, submitting },
    } = this;
    return (
      <div className="header-padding page-account">
        <Helmet>
          <title>Edit My Shadchan Profile | Zivug Zone</title>
        </Helmet>
        <div className="page-account-container-large">
          <Form
            onSubmit={this.submit}
            id="form"
            schema={editShadchanSchema}
            value={shadchan}
            onChange={this.handleChange}
            className="page-account-content mui--z1 shadchan-edit-container"
            debug={true}
          >
            <FontAwesome
              name="close"
              onClick={() => history.push("/myShadchanProfile")}
              className="cancel-shadchan-form"
            />
            <h3>Personal Info</h3>
            <div className="container">
              <legend>About Me</legend>
              <Form.Field
                placeholder="Description about your services"
                as={resizableTextArea}
                className="form-input-margin-bottom"
                name="about"
              />
            </div>
            <div className="container">
              <legend>
                Languages <Form.Message className="has-error" for="languages" />
              </legend>
              <Form.Field
                as={MultiSelect}
                className="form-input-margin-bottom"
                data={constants.languages}
                valueField="id"
                textField="text"
                name="languages"
              />
            </div>

            <div className="container">
              <legend>
                Ethnicity
                <Form.Message className="has-error" for="shadchanEthnicityId" />
              </legend>
              <Form.Field
                as={SelectList}
                data={constants.ethnicity}
                mapFromValue={item => item.id}
                valueField="id"
                textField="text"
                name="shadchanEthnicityId"
                className="form-input-margin-bottom form-inline inline-radio radioBtn"
              />
            </div>

            <div className="container">
              <legend>
                Religious Background
                <Form.Message className="has-error" for="shadchanTypeId" />
              </legend>
              <Form.Field
                as={SelectList}
                data={constants.types}
                mapFromValue={item => item.id}
                valueField="id"
                textField="text"
                name="shadchanTypeId"
                className="form-input-margin-bottom form-inline inline-radio"
              />
            </div>

            <h3>specialties</h3>
            <div className="container">
              <legend>
                Types
                <Form.Message className="has-error" for="types" />
              </legend>
              <Form.Field
                style={{ height: "42px" }}
                as={MultiSelect}
                className="form-input-margin-bottom"
                data={constants.types}
                valueField="id"
                textField="text"
                name="types"
              />
            </div>
            <div className="container">
              <legend>
                Specialties
                <Form.Message className="has-error" for="specialties" />
              </legend>
              <Form.Field
                style={{ height: "42px" }}
                as={MultiSelect}
                data={constants.specialties}
                valueField="id"
                textField="text"
                name="specialties"
                className="form-input-margin-bottom"
              />
            </div>

            <div className="container">
              <div className="form-inline-half">
                <legend>
                  age range start
                  <Form.Message className="has-error" for="ageStart" />
                </legend>
                <Form.Field
                  style={{ height: "42px" }}
                  name="ageStart"
                  className="form-input-margin-bottom"
                  as="select"
                >
                  <option value="">Please choose a start age</option>
                  {_.range(18, 99).map(x => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </Form.Field>
              </div>
              <div className="form-inline-half">
                <legend>
                  age range end
                  <Form.Message className="has-error" for="ageEnd" />
                </legend>
                <Form.Field
                  style={{ height: "42px" }}
                  name="ageEnd"
                  className="form-input-margin-bottom"
                  as="select"
                >
                  <option value="">Please choose a end age</option>
                  {_.range(18, 99).map(x => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </Form.Field>
              </div>
            </div>

            {submitting ? (
              <div className="btn-flat btn-primaryAccent text-align-center btn-loader">
                <div className="loader-small"></div>
              </div>
            ) : (
              <Form.Submit className="btn-flat btn-primaryAccent btn-loader text-align-center">
                SUBMIT
              </Form.Submit>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    constants: state.constants,
    shadchan: state.shadchan.selectedShadchan,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(shadchanActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShadchanRegistrationPage);
