import React from "react";
import PropTypes from "prop-types";
import Infinite from "../common/Infinite";
import moment from "moment-timezone";
// import Popup from "../common/Popup";
import FontAwesome from "react-fontawesome";
import { cutString } from "../common/utils";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as inboxActions from "../../actions/inboxPageActions";

const emptySearch = {
  pageNumber: 1,
  filterBy: "",
  searchText: "",
  unreadOnly: false,
};

class InboxLeftPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      search: { ...emptySearch },
    };
    this.timezone = moment.tz.guess();
    this.reload = this.reload.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.loadMoreConversations = this.loadMoreConversations.bind(this);
    this.filterDropDown = this.filterDropDown.bind(this);
    this.unreadChanged = this.unreadChanged.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadConversations(this.state.search);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.displayMobile !== this.props.displayMobile) {
      nextContext.recalculateObjectHeights();
    }
  }

  filterDropDown(event) {
    let search = { ...this.state.search };
    search.filterBy = event.target.value;
    this.setState({ search }, () => {
      this.reload();
    });
  }

  unreadChanged() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    search.unreadOnly = !search.unreadOnly;
    this.setState({ search }, () => {
      this.reload();
    });
  }

  reload() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    let searchActive = !_.isEqual(search, emptySearch);
    this.setState({ search, searchActive }, () => {
      this.props.actions.loadConversations(this.state.search);
    });
  }

  inputChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = event.target.value;
    return this.setState({ search });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.reload();
    }
  }

  clearSearch() {
    let search = { ...emptySearch };
    this.setState({ search }, () => {
      this.reload();
    });
  }

  loadMoreConversations() {
    const {
      props: {
        pagination: { pageCount },
        actions: { loadConversations },
      },
      state: {
        search: { pageNumber },
      },
    } = this;
    if (pageNumber >= pageCount) return;
    this.setState(
      ({ search }) => ({
        search: { ...search, pageNumber: search.pageNumber + 1 },
      }),
      () => loadConversations(this.state.search),
    );
  }

  inboxRow(conversation) {
    const {
      id,
      lastActive,
      initials,
      read,
      lastMessage,
      lastMessageSentByMe,
      otherParticipant,
    } = conversation;
    let selectedClass =
      parseInt(this.props.selectedConversation) === id
        ? "sidebar-list-item sidebar-list-item-active"
        : "sidebar-list-item";
    return (
      <li
        key={id}
        onClick={this.props.handleClick.bind(null, id)}
        className={selectedClass}
      >
        <span
          className={`sidebar-column-small ${
            read ? "sidebar-list-item-read" : "sidebar-list-item-unread"
          }`}
        >
          <FontAwesome name="circle" />
        </span>
        <div className="sidebar-img-container">
          <div className="sidebar-list-item-image">
            <span>{initials}</span>
          </div>
        </div>
        <div className="sidebar-list-item-info sidebar-column-large">
          <h5
            className={
              !read
                ? "sidebar-list-item-unread-title sidebar-list-item-title"
                : "sidebar-list-item-title"
            }
          >
            {otherParticipant}
          </h5>
          {lastMessage && (
            <p className="sidebar-list-item-info-details">
              <span>{cutString(lastMessage, 18)}</span>
            </p>
          )}
          {lastActive && (
            <p className="sidebar-list-item-info-types">
              <span>
                {lastMessageSentByMe ? "sent" : "received"}{" "}
                {moment.tz(lastActive, this.timezone).fromNow()}
              </span>
            </p>
          )}
        </div>
      </li>
    );
  }

  render() {
    const {
      conversations,
      pagination: { rowCount },
      displayMobile,
      isLoading,
    } = this.props;
    const { searchActive } = this.state;
    return (
      <div
        id="sidebar-left"
        className={`header-padding ${displayMobile ? "" : "mobile-hide"}`}
      >
        <div className="search-filter" id="search-filter">
          <div className="search-bar">
            <div className="search">
              <input
                className="input"
                type="text"
                onChange={this.inputChanged}
                onBlur={this.reload}
                onKeyPress={this.handleKeyPress}
                value={this.state.search.searchText}
                placeholder="Search Messages"
                autoComplete="off"
                name="searchText"
              />
            </div>
          </div>
        </div>
        {/* <div id="filter-bar" className="filter-bar">
          <div className="sidebar-column-small filter-column-read">
            {this.state.search.filterBy ? null : (
              <Popup
                trigger={
                  this.state.search.unreadOnly ? (
                    <FontAwesome
                      name="circle"
                      onClick={this.unreadChanged}
                      className="active"
                    />
                  ) : (
                    <FontAwesome name="circle-o" onClick={this.unreadChanged} />
                  )
                }
                content="Filter by new messages"
              />
            )}
          </div>
          <div className="sidebar-column-large filter-column-dropdown">
            <p>Include:</p>
            <select
              onChange={this.filterDropDown}
              value={this.state.search.filterBy}
              className="inbox-settings-select mui-select"
            >
              <option className="option" value="all" label="All" />
              <option className="option" value="" label="Received" />
              <option className="option" value="sent" label="Sent" />
              <option className="option" value="archived" label="Archived" />
              <option className="option" value="shared" label="Shared" />
              <option
                className="option"
                value="suggestions"
                label="Suggestions"
              />
              <option className="option" value="ideas" label="Ideas" />
            </select>
          </div>
        </div> */}
        {searchActive ? (
          <div className="sidebar-list-results" id="sidebar-list-results">
            <p>
              <span className="sidebar-list-results-bold">{rowCount} </span>
              Result{rowCount === 1 ? "" : "s"}
            </p>
            <p onClick={this.clearSearch} className="" type="button">
              <span>Clear</span>
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="sidebar-list" id="resumeList">
          <ul>
            <Infinite
              containerHeight={
                this.context.objectHeights.sidebarCalculatedHeight
              }
              hasMore={rowCount > conversations.length}
              className="infinite"
              loadingMore={isLoading}
              itemList={conversations}
              renderRow={this.inboxRow.bind(this)}
              noItemsText="No messages."
              loadMore={this.loadMoreConversations.bind(this)}
            />
          </ul>
        </div>
        <div className="sidebar-list-count" id="sidebar-list-count">
          <p>
            {rowCount} Conversations{rowCount > 1 ? "s" : ""}
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    conversations: { conversations, pagination, search, isLoading },
  } = state;
  return {
    conversations,
    pagination,
    search,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(inboxActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxLeftPanel);
