import * as types from "../actionTypes/userActionTypes";

export default function userReducer(state = { isLoading: true }, action) {
  switch (action.type) {
    case types.USER_NOT_LOGGED_IN:
      return {
        isLoading: false,
      };
    case types.USER_LOGIN_START:
      return {
        errorMessage: "",
        isAuthenticated: false,
        isLoggingIn: true,
      };
    case types.USER_LOGIN_SUCCESS:
      const {
        user,
        user: { id, role, hasResume, resumeId, shadchanStatus, viewImages },
      } = action;
      return {
        unconfirmedUserId: null,
        errorMessage: "",
        user,
        role,
        id,
        hasResume,
        resumeId,
        shadchanStatus,
        viewImages,
        isLoggingIn: false,
        isAuthenticated: true,
      };
    case types.USER_LOGIN_ERROR:
      return {
        unconfirmedUserId: action.data.unconfirmedUserId,
        errorMessage: action.data.message,
        isLoggingIn: false,
        isAuthenticated: false,
      };
    case types.REGISTER_USER_START:
      return {
        errorMessage: "",
        isRegistered: false,
        isRegistering: true,
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        unconfirmedUserId: action.unconfirmedUserId,
        errorMessage: "",
        isRegistered: true,
        isRegistering: false,
      };
    case types.REGISTER_USER_ERROR:
      return {
        errorMessage: action.errorMessage,
        isRegistered: false,
        isRegistering: false,
      };
    case types.REGISTER_USER_DONE:
      return {};
    case types.CONFIRM_USER_FAIL:
      return {
        errorConfirmingUser: true,
        canResendConfirmation: action.data.canResend,
        confirmationErrorMessage: action.data.message,
      };
    case types.CONFIRM_USER_SUCCESS:
      return {
        userConfirmed: true,
      };
    case types.RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmationResent: true,
        message: action.data.message,
        confirmedAlready: action.data.confirmedAlready,
      };
    case types.ACCOUNT_SETTINGS_SAVED:
      const updatedUser = action.user;
      return {
        ...state,
        viewImages: updatedUser.viewImages,
        user: updatedUser,
      };
    case types.ADD_USER_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emails: [...state.user.emails, action.userEmail],
        },
      };
    case types.REMOVE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.filter(e => e.id !== action.userEmailId),
        },
      };
    case types.RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.map(e => {
            if (e.id === action.userEmailId) {
              e.confirmationResent = true;
            }
            return e;
          }),
        },
      };
    case types.INVITE_USER_START:
      return {
        ...state,
        inviteUserSuccess: false,
      };
    case types.INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteUserSuccess: true,
      };
    case types.UPLOAD_USER_ID_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          verificationImage: action.verificationImage,
        },
      };
    case types.USER_LOGOUT:
      return {
        isLoading: false,
      };
    ///////////ZIVUG ZONE/////////////
    case types.RESUME_CREATED:
      return {
        ...state,
        hasResume: true,
        resumeId: action.resumeId,
      };
    default:
      return state;
  }
}
