import React, { Fragment } from "react";
import history from "../../lib/history";
import Resume from "./Resume";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as resumeActions from "../../actions/resumeActions";
import { Helmet } from "react-helmet";

class MyResume extends React.Component {
  componentDidMount() {
    const { resumeId, actions } = this.props;
    if (!resumeId) return history.push("/");
    actions.getMyResume();
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>My Resume | Zivug Zone</title>
        </Helmet>
        <Resume myResume={true} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { resumeId },
  } = state;
  return { resumeId };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(resumeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyResume);
