import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shadchanActions from "../../actions/shadchanActions";
import ShadchanimLeftPanel from "./components/ShadchanLeftPanel";
import ShadchanMain from "./components/ShadchanMain";
import { Helmet } from "react-helmet";

class ShadchanimPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
    showWelcome: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const {
      role,
      match: {
        params: { id: shadchanId },
      },
      actions: { adminGetShadchanMembers, getShadchanById },
    } = this.props;
    this.state = {
      selectedShadchan: shadchanId ? +shadchanId : null,
      mobileTab: props.match.params.id ? "single" : "list",
    };
    this.selectShadchan = this.selectShadchan.bind(this);

    if (shadchanId) {
      getShadchanById(shadchanId);
      if (role === "admin") {
        adminGetShadchanMembers(shadchanId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const prevShadchanId = prevProps.match.params.id;
    const {
      role,
      actions: { adminGetShadchanMembers, getShadchanById },
      match,
    } = this.props;
    const shadchanId = match.params.id;
    if (shadchanId !== prevShadchanId) {
      if (shadchanId) {
        getShadchanById(shadchanId);
        if (role === "admin") {
          adminGetShadchanMembers(shadchanId);
        }
        this.setState({ selectedShadchan: +shadchanId });
      } else {
        this.props.actions.clearSelectedShadchan();
        this.setState({ selectedShadchan: null });
      }
    }
  }

  selectShadchan(id) {
    this.props.history.push(`/shadchanim/${id}`);
    this.setState({ mobileTab: "single", selectedShadchan: +id });
  }

  render() {
    const {
      props: { location, isLoadingSelectedShadchan },
      state: { selectedShadchan, mobileTab },
    } = this;

    let content = "";

    if (isLoadingSelectedShadchan) {
      content = <div className="loader"></div>;
    } else if (selectedShadchan) {
      content = (
        <ShadchanMain
          displayMobile={mobileTab === "single"}
          switchMobileTab={() => this.setState({ mobileTab: "list" })}
        />
      );
    } else {
      content = (
        <div id="content-main" className="header-padding column-layout-two">
          <div className="content-padding message-empty">
            <i className="material-icons">description</i>
            <p>No Shadchan Selected</p>
            <p>
              Please choose a Shadchan from the left panel to view its content.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Helmet>
          <title>Shadchanim | Zivug Zone</title>
        </Helmet>
        <ShadchanimLeftPanel
          selectShadchan={this.selectShadchan}
          selectedShadchan={selectedShadchan}
          location={location}
          displayMobile={mobileTab === "list"}
          switchMobileTab={() => this.setState({ mobileTab: "single" })}
        />
        <div className={`${mobileTab === "single" ? "" : "mobile-hide"}`}>
          {content}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    shadchan: { selectedShadchan, isLoadingSelectedShadchan },
    user: { role },
  } = state;
  return {
    role,
    selectedShadchan,
    isLoadingSelectedShadchan,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(shadchanActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShadchanimPage);
