import * as conversationTypes from "../actionTypes/conversationActionTypes";
import _ from "lodash";
import { mergeCurrentItemsWithNextPage } from "../components/common/utils";

export default function conversationReducer(
  state = {
    conversations: [],
    pagination: {},
    search: {},
    unreadConversations: [],
  },
  action,
) {
  switch (action.type) {
    case conversationTypes.LOAD_CONVERSATIONS_START:
      return {
        ...state,
        isLoading: true,
        conversations:
          action.search.pageNumber === 1 ? [] : state.conversations,
        search: action.search,
      };
    case conversationTypes.LOAD_CONVERSATIONS_SUCCESS:
      let { pagination, results } = action.conversations;
      return {
        ...state,
        pagination: { ...state.pagination, ...pagination },
        isLoading: false,
        conversations: mergeCurrentItemsWithNextPage(
          state.conversations,
          results,
          pagination.page,
          pagination.pageSize,
        ),
      };
    case conversationTypes.LOAD_UNREAD_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        unreadConversations: action.unreadConversations,
      };
    case conversationTypes.MARK_CONVERSATION_UNREAD:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c.id === action.conversationId) {
            c.read = false;
          }
          return c;
        }),
        unreadConversations: _.uniq([
          ...state.unreadConversations,
          action.conversationId,
        ]),
      };
    case conversationTypes.MARK_CONVERSATION_READ:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c.id === action.conversationId) {
            c.read = true;
          }
          return c;
        }),
        unreadConversations: state.unreadConversations.filter(
          i => i !== action.conversationId,
        ),
      };
    case conversationTypes.ARCHIVE_CONVERSATION:
      return {
        ...state,
        conversations:
          state.search.filterBy !== "archived"
            ? state.conversations.filter(c => c.id !== action.conversation.id)
            : [
                {
                  ...action.conversation,
                  subject:
                    action.conversation.messages[
                      action.conversation.messages.length - 1
                    ].text,
                },
                ...state.conversations,
              ],
        unreadConversations: state.unreadConversations.filter(
          i => i !== action.conversation.id,
        ),
      };
    case conversationTypes.UNARCHIVE_CONVERSATION:
      return {
        ...state,
        conversations:
          state.search.filterBy === "archived"
            ? state.conversations.filter(c => c.id !== action.conversation.id)
            : state.conversations.find(c => c.id === action.conversation.id)
            ? state.conversations
            : [
                {
                  ...action.conversation,
                  subject:
                    action.conversation.messages[
                      action.conversation.messages.length - 1
                    ].text,
                },
                ...state.conversations,
              ],
      };
    case conversationTypes.LOAD_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c && c.id === action.conversation.id) {
            c.read = true;
          }
          return c;
        }),
        unreadConversations: state.unreadConversations.filter(
          i => i !== action.conversation.id,
        ),
      };
    case conversationTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c.id === action.message.conversationId) {
            c.lastMessage = action.message.text.substring(0, 30);
            c.lastActive = new Date();
            c.read = true;
            c.lastMessageSentByMe = true;
          }
          return c;
        }),
        unreadConversations: state.unreadConversations.filter(
          i => i !== action.message.conversationId,
        ),
      };
    case conversationTypes.NEW_MESSAGE_RECEIVED:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c.id === parseInt(action.data.conversationId)) {
            c.lastMessage = action.data.text.substring(0, 30);
            c.lastActive = new Date();
            c.read = false;
            c.lastMessageSentByMe = false;
          }
          return c;
        }),
        unreadConversations: _.uniq([
          ...state.unreadConversations,
          parseInt(action.data.conversationId),
        ]),
      };
    default:
      return state;
  }
}
