import React from "react";
import * as myMatchesActions from "../../actions/myMatchesActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FontAwesome from "react-fontawesome";
import ResumeAutosuggest from "../common/resumeAutosuggest";
import PrivateImage from "../common/privateImage";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment-timezone";

class ideaModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      hisResumeId,
      herResumeId,
      dateCreated,
      lastUpdated,
      isNew,
      id,
      userId,
      noteText,
    } = props;
    this.state = {
      id,
      userId,
      isNew: isNew ? true : false,
      submitted: false,
      hisResumeId: hisResumeId ? hisResumeId : null,
      herResumeId: herResumeId ? herResumeId : null,
      noteText: noteText ? noteText : "",
      hisName: "",
      herName: "",
      dateCreated: dateCreated ? dateCreated : null,
      lastUpdated: lastUpdated ? lastUpdated : null,
    };

    this.timezone = moment.tz.guess();
    this.saveAutocomplete = this.saveAutocomplete.bind(this);
    this.archiveIdea = this.archiveIdea.bind(this);
    this.isArchiving = false;
    this.handleChange = this.handleChange.bind(this);
  }

  saveAutocomplete(field, value) {
    return this.setState({ [field]: value, submitted: false });
  }

  archiveIdeaAlert() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title=" Are you sure you want to delete this idea?"
          onConfirm={this.archiveIdea}
          onCancel={() => this.setState({ alert: null })}
        ></SweetAlert>
      ),
    });
  }

  archiveIdea() {
    this.isArchiving = true;
    this.props.actions.deleteIdea(this.state.id);
    this.props.removeModal();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  saveIdea(e) {
    e.preventDefault();
    const {
      state: { id, userId, isNew, hisResumeId, herResumeId, noteText },
    } = this;
    if (isNew) {
      if (hisResumeId && herResumeId) {
        this.props.actions.createIdea({
          hisResumeId,
          herResumeId,
          noteText: noteText ? noteText : null,
        });
      } else {
        return this.setState({ submitted: true });
      }
    }
    if (!isNew && !this.isArchiving) {
      // for now they can only edit note
      this.props.actions.editIdea({ id, userId, noteText });
    }

    this.props.removeModal();
  }

  hisResume() {
    const {
      hisResumeId,
      hisFirstName,
      hisLastName,
      hisHeight,
      hisMaritalStatus,
      hisAge,
      hisImage,
    } = this.props;
    return (
      <div className="idea-card idea-card-modal">
        <PrivateImage
          fileName={hisImage}
          id={hisResumeId}
          section="resume"
          className="idea-card-image"
        />
        <div className="idea-card-info">
          <h5 className="idea-card-title">
            {hisFirstName} {hisLastName}
          </h5>
          <div className="idea-card-details">
            <div className="flex">
              <span>{hisAge}</span>
              <span>{hisHeight}</span>
              <span>{hisMaritalStatus}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  herResume() {
    const {
      herResumeId,
      herFirstName,
      herLastName,
      herHeight,
      herMaritalStatus,
      herAge,
      herImage,
    } = this.props;
    return (
      <div className="idea-card idea-card-modal">
        <PrivateImage
          fileName={herImage}
          id={herResumeId}
          section="resume"
          className="idea-card-image"
        />
        <div className="idea-card-info">
          <h5 className="idea-card-title">
            {herFirstName} {herLastName}
          </h5>
          <div className="idea-card-details">
            <div className="flex">
              <span>{herAge}</span>
              <span>{herHeight}</span>
              <span>{herMaritalStatus}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  hisEmpty() {
    const { submitted, hisResumeId, hisName } = this.state;
    return (
      <div className="mui-row">
        <legend>
          Male{" "}
          {submitted && !hisResumeId ? (
            <span className="has-error"> Cannot be blank</span>
          ) : null}
        </legend>
        <ResumeAutosuggest
          placeholder="Enter/Select name of Male"
          name="hisName"
          value={hisName}
          gender="1"
          itemSelected={this.saveAutocomplete}
          onChange={this.saveAutocomplete.bind(null, "hisName")}
          fieldName="hisResumeId"
        ></ResumeAutosuggest>
      </div>
    );
  }

  herEmpty() {
    const { submitted, herResumeId, herName } = this.state;
    return (
      <div className="mui-row">
        <legend>
          Female{" "}
          {submitted && !herResumeId ? (
            <span className="has-error"> Cannot be blank</span>
          ) : null}
        </legend>
        <ResumeAutosuggest
          placeholder="Enter/Select name of Female"
          name="herName"
          value={herName}
          gender="2"
          itemSelected={this.saveAutocomplete}
          onChange={this.saveAutocomplete.bind(null, "herName")}
          fieldName="herResumeId"
        ></ResumeAutosuggest>
      </div>
    );
  }

  renderIdeaCard() {
    const { isNew, hisResumeId, herResumeId } = this.props;
    return (
      <div className="autosuggest">
        <div className="form-inline-half mui-col-md-6">
          {!isNew || hisResumeId ? this.hisResume() : this.hisEmpty()}
        </div>
        <div className="form-inline-half mui-col-md-6">
          {!isNew || herResumeId ? this.herResume() : this.herEmpty()}
        </div>
      </div>
    );
  }

  render() {
    const {
      state: { isNew, noteText, dateCreated, lastUpdated },
    } = this;
    return (
      <div className="idea-modal large-modal">
        <div className="mui-col-md-12">
          {this.state.alert}
          {!isNew ? (
            <div>
              <div className="modal-header mui-row">
                <div className="modal-header-left">
                  <p>Edit Idea</p>
                  <p
                    className="btn-modal"
                    onClick={this.archiveIdeaAlert.bind(this)}
                  >
                    <FontAwesome name="trash" />
                    <span className="mobile-hidden">Delete</span>
                  </p>
                </div>
                <i
                  className="material-icons modal-close-icon"
                  onClick={this.props.removeModal.bind(this)}
                >
                  close
                </i>
              </div>
            </div>
          ) : (
            <div className="modal-header mui-row">
              <p>Create a new idea</p>
              <i
                className="material-icons modal-close-icon"
                onClick={this.props.removeModal.bind(this)}
              >
                close
              </i>
            </div>
          )}

          <div className="modal-content-container">
            <form id="form">
              {this.renderIdeaCard()}
              {dateCreated && (
                <p>
                  Created:{" "}
                  {moment
                    .tz(dateCreated, this.timezone)
                    .format("MMMM Do YYYY h:mm A")}
                </p>
              )}
              {lastUpdated && lastUpdated !== dateCreated ? (
                <p>
                  Updated:{" "}
                  {moment
                    .tz(lastUpdated, this.timezone)
                    .format("MMMM Do YYYY h:mm A")}
                </p>
              ) : null}

              <div className="ideas-modal-note mui-row">
                <legend>Note</legend>
                <textarea
                  rows={3}
                  label="Note:"
                  name="noteText"
                  className="note-textarea"
                  placeholder="Add a note here.."
                  value={noteText}
                  onChange={this.handleChange}
                >
                  {" "}
                </textarea>
              </div>
              <button
                id="idea-save-button"
                className="btn-flat btn-accentPrimary btn-fullWidth"
                onClick={this.saveIdea.bind(this)}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(myMatchesActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ideaModal);
