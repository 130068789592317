import React from "react";

class PendingShadchanMsg extends React.Component {
  render() {
    return (
      <div className="pending-shadchan-msg">
        <div>Your Shadchan Application is pending approval</div>
        <div>
          You can't use this functionality until your Application is approved
        </div>
      </div>
    );
  }
}

export default PendingShadchanMsg;
