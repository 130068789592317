import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";
import * as notificationActions from "../../actions/notificationActions";
import NotificationItem from "./NotificationItem";
import history from "../../lib/history";

class HeaderNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      pulse: false,
      notifications: this.parseNotifications(props.notifications),
    };
    this.timezone = moment.tz.guess();
    this.isChildOf = this.isChildOf.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.timeout = null;
  }

  componentDidMount() {
    if (document) {
      document.addEventListener("click", this.toggleNotification);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.length !== this.state.notifications.length) {
      this.setState({ pulse: true });

      this.timeout = setTimeout(() => {
        this.setState({ pulse: false });
      }, 1200);
    }
    return this.setState({
      notifications: this.parseNotifications(nextProps.notifications),
    });
  }

  parseNotifications(notifications) {
    return notifications.map(n => {
      const { dateSent, tag, ...rest } = n;
      return {
        date: moment
          .tz(dateSent, this.timezone)
          .subtract(20, "s")
          .fromNow(),
        tags: [{ type: "success", text: tag }],
        ...rest,
      };
    });
  }

  toggleNotification(e) {
    if (
      e.target === this.refs.notificationIcon &&
      !this.state.showNotification
    ) {
      this.setState({ showNotification: true });
    } else if (
      this.state.showNotification &&
      !this.isChildOf(e.target, this.refs.notificationHolder)
    ) {
      this.setState({ showNotification: false });
    }
  }

  isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    } else {
      return this.isChildOf(child.parentNode, parent);
    }
  }

  goToNotificationLink = notification => {
    const { id, link: { path, id: linkId, query } = {} } = notification;
    this.props.actions.markNotificationAsRead(id);
    history.push(
      `/${path}${linkId ? `/${linkId}` : ""}${query ? `?${query}` : ""}`,
    );
    this.setState({ showNotification: false });
  };

  render() {
    const { notifications = [], pulse, showNotification } = this.state;
    return (
      <div className="wrapper notification-dropdown">
        <div className="react-notification-center light-theme">
          <div
            className={`r-notifications-icon active ${pulse ? "pulse" : ""}`}
            ref="notificationIcon"
          >
            {notifications.length}
          </div>
          {showNotification && (
            <div className="rr-wrapper left" ref="notificationHolder">
              <div className="notification-holder">
                <div className="r-notifications">
                  <div className="rn-header">Notifications</div>
                  <div className="rn-content" ref="rrContent">
                    {notifications.length === 0 && (
                      <div className="no-rn">No notifications.</div>
                    )}
                    <ul className="rn-ul">
                      {notifications.map((item, i) => {
                        return (
                          <NotificationItem
                            key={i}
                            onClick={item => this.goToNotificationLink(item)}
                            {...item}
                          />
                        );
                      })}
                    </ul>
                  </div>
                  <div className="rn-footer">
                    <p
                      className="clear-all"
                      onClick={this.props.actions.clearAllNotifications}
                    >
                      Clear all
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(notificationActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderNotifications);
