import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shadchanActions from "../../../actions/shadchanActions";
import Infinite from "../../common/Infinite";
import _ from "lodash";

const emptySearch = {
  pageNumber: 1,
  filterBy: "all",
  searchText: "",
};

class ShadchanLeftPanel extends Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      search: {
        ...emptySearch,
        pageNumber: props.pagination.page,
      },
      filterActive: false,
      searchActive: false,
    };
    this.reload = this.reload.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.loadMoreShadchanim = this.loadMoreShadchanim.bind(this);
    this.filterDropDown = this.filterDropDown.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    if (this.props.search) {
      let previousSearch = { ...this.state.search, ...this.props.search };
      this.setState({ search: previousSearch }, () => {
        this.reload();
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.location.hash !== nextProps.location.hash) {
      //if coming from a notification, refresh data
      this.reload();
    }
    if (nextProps.displayMobile !== this.props.displayMobile) {
      nextContext.recalculateObjectHeights();
    }
  }

  filterDropDown(event) {
    let search = { ...this.state.search };
    search.filterBy = event.target.value;
    this.setState({ search }, () => {
      this.reload();
    });
  }

  reload() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    let searchActive = !_.isEqual(search, emptySearch);
    this.setState({ search, searchActive }, () => {
      this.props.actions.loadShadchanim(search);
      this.context.recalculateObjectHeights();
    });
  }

  clearSearch() {
    let search = { ...emptySearch };
    this.setState({ search }, () => {
      this.reload();
    });
  }

  inputChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = event.target.value;
    return this.setState({ search });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.reload();
    }
  }

  loadMoreShadchanim() {
    const {
      props: {
        pagination: { pageCount },
        actions: { loadShadchanim },
      },
      state: {
        search: { pageNumber },
      },
    } = this;
    if (pageNumber >= pageCount) return;
    this.setState(
      ({ search }) => ({
        search: { ...search, pageNumber: search.pageNumber + 1 },
      }),
      () => loadShadchanim(this.state.search),
    );
  }

  shadchanRow(shadchan) {
    const { id, firstName, lastName } = shadchan;
    const name = `${firstName} ${lastName}`;
    let shadchanImage = shadchan.logoLocation
      ? `../../api/images/shadchan/${shadchan.logoLocation}`
      : "../images/small-placeholder.png";

    const maxLimit = 22;
    return (
      <li
        key={id}
        className={
          this.props.selectedShadchan === id
            ? "sidebar-list-item sidebar-list-item-active"
            : "sidebar-list-item"
        }
        onClick={() => this.props.selectShadchan(id)}
      >
        <span className="sidebar-column-small"></span>
        <div className="sidebar-img-container">
          <img src={shadchanImage} alt="" className="sidebar-list-item-image" />
        </div>
        <div className="sidebar-list-item-info sidebar-column-large">
          <h5 className="sidebar-list-item-title">
            {name.length > maxLimit
              ? name.substring(0, maxLimit - 3) + "..."
              : name}
          </h5>
        </div>
      </li>
    );
  }

  render() {
    const {
      props: {
        pagination: { rowCount },
        displayMobile,
      },
      state: {
        search: { searchText },
      },
    } = this;

    return (
      <div
        id="sidebar-left"
        className={`header-padding ${displayMobile ? "" : "mobile-hide"}`}
      >
        <div className="search-filter" id="search-filter">
          <div className="search-bar">
            <div className="search">
              <input
                className="input"
                type="text"
                onChange={this.inputChanged}
                onBlur={this.reload}
                onKeyPress={this.handleKeyPress}
                value={searchText}
                placeholder="Search Shadchanim"
                name="searchText"
              />
            </div>
          </div>
        </div>
        {/* <div id="filter-bar" className="filter-bar">
          <div className="filter-column-dropdown group-filter">
            <p>Include:</p>
            <select
              className="inbox-settings-select mui-select"
              onChange={this.filterDropDown}
              value={this.state.search.filterBy}
            >
              <option className="option" value="all" label="All" />
              <option
                className="option"
                value="groupMember"
                label="Groups I am a member of"
              />
              <option
                className="option"
                value="groupInvite"
                label="Groups I am a invited to"
              />
            </select>
          </div>
        </div> */}
        {this.state.searchActive ? (
          <div className="sidebar-list-results" id="sidebar-list-results">
            <p>
              <span className="sidebar-list-results-bold">{rowCount} </span>
              Result{rowCount === 1 ? "" : "s"}
            </p>
            <p onClick={this.clearSearch} className="" type="button">
              <span>Clear filters</span>
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="sidebar-list" id="resumeList">
          <ul>
            <Infinite
              containerHeight={
                this.context.objectHeights.sidebarCalculatedHeight
              }
              hasMore={rowCount > this.props.shadchanim.length}
              className="infinite"
              loadingMore={!!this.props.isLoading}
              itemList={this.props.shadchanim}
              renderRow={this.shadchanRow.bind(this)}
              noItemsText="No shadchanim."
              loadMore={this.loadMoreShadchanim.bind(this)}
            />
          </ul>
        </div>
        <div className="sidebar-list-count" id="sidebar-list-count">
          <p>
            {rowCount} Shadchan{rowCount > 1 ? "im" : ""}
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { shadchanim, search, pagination, isLoading } = state.shadchan;
  return {
    shadchanim,
    search,
    pagination,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(shadchanActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShadchanLeftPanel);
