import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { LoginPage } from "./components/account/login/LoginPage";
import NewPassword from "./components/account/login/newPassword";
import ForgotPassword from "./components/account/login/forgotPasswordPage";
import ConfirmUser from "./components/account/register/confirmUser";
import RegisterPage from "./components/account/register/registerPage";
import ShadchanRegistrationPage from "./components/account/register/shadchanRegistrationPage";
import MyResume from "./components/resumes/MyResume";
import ConversationDisplay from "./components/inbox/inboxPage";
import { requireAuthentication } from "./components/common/AuthenticatedComponent";
import MyMatchesPage from "./components/myMatches/MyMatchesPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import CreateResumeNew from "./components/resumes/CreateEditResume";
import AccountSettings from "./components/account/accountSettings/accountSettingsPage";
import LoadingPage from "./components/pages/loadingPage";
import Shadchanim from "./components/shadchanim/ShadchanimPage";
import MyShadchanProfilePage from "./components/shadchanim/MyShadchanProfilePage";
import EditMyShadchanPage from "./components/shadchanim/EditMyShadchanPage";
import PendingShadchanim from "./components/admin/PendingShadchanim";
import Users from "./components/admin/Users";

export default (
  <div style={{ height: "100%" }}>
    <Switch>
      <Route exact path="/loading" component={LoadingPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route
        exact
        path="/shadchanRegistration"
        component={ShadchanRegistrationPage}
      />
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      <Route exact path="/confirmUser/:token/:userId" component={ConfirmUser} />
      <Route exact path="/resetPassword/:token" component={NewPassword} />

      <Route
        exact
        path="/accountSettings"
        component={requireAuthentication(AccountSettings)}
      />
      <Route
        exact
        path="/shadchanim/:id?"
        component={requireAuthentication(Shadchanim)}
      />

      <Route
        exact
        path="/myShadchanProfile"
        component={requireAuthentication(MyShadchanProfilePage)}
      />

      <Route
        exact
        path="/myShadchanProfile/edit"
        component={requireAuthentication(EditMyShadchanPage)}
      />

      <Route
        exact
        path="/myResume"
        component={requireAuthentication(MyResume)}
      />
      <Route
        exact
        path="/myResume/create-edit"
        component={requireAuthentication(CreateResumeNew)}
      />
      <Route
        exact
        path="/inbox/:id?"
        component={requireAuthentication(ConversationDisplay)}
      />

      <Route
        exact
        path="/ideas"
        component={requireAuthentication(MyMatchesPage)}
      />

      <Route
        exact
        path="/admin/pendingShadchanim"
        component={requireAuthentication(PendingShadchanim)}
      />

      <Route
        exact
        path="/admin/users"
        component={requireAuthentication(Users)}
      />

      <Route exact path="/" component={LoadingPage} />

      <Route exact path="/404" component={NotFoundPage} />
      <Redirect path="*" to="/404" />
    </Switch>
  </div>
);
