import React from "react";

export function ContactUsBanner() {
  const isMobile = window.innerWidth < 701;
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "grey",
        zIndex: 9,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 30,
        color: "#ffffff",
        fontSize: isMobile ? "9px" : "14px",
      }}
    >
      For questions or comments please contact us at: support@zivugzone.com
    </div>
  );
}
