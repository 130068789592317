function getRedirectUrl(nextRoute, role, hasResume) {
  let redirect;
  switch (true) {
    case !!nextRoute:
      redirect = nextRoute;
      break;
    case role === "single" && hasResume:
      redirect = "/inbox";
      break;
    case role === "single":
      redirect = "/myResume/create-edit";
      break;
    case role === "shadchan":
      redirect = "/ideas";
      break;
    case role === "admin":
      redirect = "/admin/pendingShadchanim";
      break;
    default:
      redirect = "/login";
  }
  return redirect;
}

function getResumeFieldsForNewIdea(resume) {
  const {
    gender,
    height,
    maritalStatus,
    id,
    userId,
    firstName,
    lastName,
    age,
    images = [],
  } = resume;
  const prefix = gender === "Male" ? "his" : "her";
  return {
    isNew: true,
    [`${prefix}FirstName`]: firstName,
    [`${prefix}LastName`]: lastName,
    [`${prefix}Age`]: age,
    [`${prefix}MaritalStatus`]: maritalStatus,
    [`${prefix}Height`]: height,
    [`${prefix}Image`]: images.length ? images[0].fileLocation : null,
    [`${prefix}ResumeId`]: id,
    [`${prefix}UserId`]: userId,
  };
}

const validPhoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$|^$/;

export { getRedirectUrl, getResumeFieldsForNewIdea, validPhoneRegex };
