import io from "socket.io-client";
import {
  receivedNewMessage,
  socketIoConversationResumesUpdate,
} from "./actions/singleConversationActions";
import { receivedNewNotification } from "./actions/notificationActions";
import { socketIoIdeaUpdate } from "./actions/myMatchesActions";
import { socketIoRequestedMembersUpdate } from "./actions/shadchanActions";
import { store } from "./store";
let socket;

function setupSocketConnection(token) {
  socket = io(process.env.REACT_APP_SOCKET_URL);
  socket.emit("token", token);

  socket.on("reconnect_attempt", () => {
    socket.emit("token", token);
  });

  socket.on("message", data => {
    receivedNewMessage(data.message, store.dispatch);
  });

  socket.on("ideaUpdate", data => {
    socketIoIdeaUpdate(data.ideas, store.dispatch);
  });

  socket.on("notifications", data => {
    receivedNewNotification(data.notifications, store.dispatch);
  });

  socket.on("conversationResumesUpdate", data => {
    socketIoConversationResumesUpdate(data, store.dispatch);
  });

  socket.on("requestedMembersUpdate", data => {
    socketIoRequestedMembersUpdate(data.requestedMembers, store.dispatch);
  });
}

function closeConnection() {
  if (socket) socket.close();
}

export { setupSocketConnection, closeConnection };
