import * as types from "../actionTypes/resumeActionTypes";
import * as ideaTypes from "../actionTypes/myMatchesActionTypes";
import { mergeCurrentItemsWithNextPage } from "../components/common/utils";

export default function resumeReducer(
  state = {
    selectedResume: {},
    editResume: {
      images: [],
      references: [{ contactInfo: [{}] }, { contactInfo: [{}] }],
      shuls: [{}],
      education: [{}, {}, {}],
      occupation: {},
      camps: [{}],
      volunteer: [{}],
      familyInfo: {},
      languages: [],
      displayPersonalContactInfo: true,
      birthday: null,
    },
    resumeLoading: false,
    resumes: [],
    pagination: { page: 1 },
    search: {},
    isLoading: false,
  },
  action,
) {
  switch (action.type) {
    case types.LOAD_RESUMES_START:
      return {
        ...state,
        isLoading: true,
        resumes: action.search.pageNumber === 1 ? [] : state.resumes,
        search: action.search,
      };
    case types.LOAD_RESUMES_SUCCESS:
      const { pagination, resumes } = action.data;
      let pageInfo = pagination;
      let newResumes = mergeCurrentItemsWithNextPage(
        state.resumes,
        resumes,
        pageInfo.page,
        pageInfo.pageSize,
      );
      return {
        ...state,
        resumes: newResumes,
        pagination: { ...state.pagination, ...pagination },
        isLoading: false,
      };
    case types.GET_RESUME_START:
      return {
        ...state,
        resumeLoading: true,
        selectedResume: {},
      };
    case types.GET_RESUME_SUCCESS:
      return {
        ...state,
        resumeLoading: false,
        selectedResume: action.resume,
      };
    case types.GET_RESUME_FOR_EDIT_SUCCESS:
      return {
        ...state,
        editResume: action.resume,
      };
    case types.UPDATE_RESUME_SUCCESS:
      return {
        ...state,
        editResume: action.resume,
      };
    case ideaTypes.REJECT_IDEA_SUCCESS:
      return {
        ...state,
        selectedResume: {},
      };
    case ideaTypes.ACCEPT_IDEA_SUCCESS:
      return {
        ...state,
        selectedResume: { ...state.selectedResume, ...action.data },
      };
    default:
      return state;
  }
}
