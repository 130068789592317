import * as types from "../actionTypes/adminTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function getPendingShadchanim(info) {
  return async dispatch => {
    let result = await apiAuthRequest(
      "get",
      "/admin/getPendingShadchanim",
      info,
    );
    if (result.success) {
      dispatch({
        type: types.ADMIN_PENDING_SHADCHANIM_SUCCESS,
        pendingShadchanim: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function getUsers(info) {
  return async dispatch => {
    let result = await apiAuthRequest("get", "/admin/getUsers", info);
    if (result.success) {
      dispatch({
        type: types.ADMIN_GET_USERS_SUCCESS,
        users: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function blockUser(id) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/admin/blockUser", { id });
    if (result.success) {
      dispatch({
        type: types.ADMIN_BLOCK_USER_SUCCESS,
        id,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function unBlockUser(id) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/admin/unBlockUser", { id });
    if (result.success) {
      dispatch({
        type: types.ADMIN_UNBLOCK_USER_SUCCESS,
        id,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function approveShadchan(id) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/admin/approveShadchan", { id });
    if (result.success) {
      dispatch({
        type: types.ADMIN_PENDING_SHADCHANIM_SUCCESS,
        pendingShadchanim: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function denyShadchan(id) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/admin/denyShadchan", { id });
    if (result.success) {
      dispatch({
        type: types.ADMIN_PENDING_SHADCHANIM_SUCCESS,
        pendingShadchanim: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
