import * as types from "../actionTypes/constantsTypes";
import { apiRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function loadConsts() {
  return async dispatch => {
    let result = await apiRequest("get", "/constants/getAll");
    if (result.success) {
      dispatch({
        type: types.LOAD_CONSTS_SUCCESS,
        consts: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
