import * as types from "../actionTypes/resumeActionTypes";
import * as userTypes from "../actionTypes/userActionTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";
import history from "../lib/history";
import { updateCookie } from "./userActions";

export function loadResumes(params) {
  return async dispatch => {
    dispatch({ type: types.LOAD_RESUMES_START, search: params });

    let result = await apiAuthRequest("post", "/resumes/getAllResumes", params);
    if (result.success) {
      dispatch({
        type: types.LOAD_RESUMES_SUCCESS,
        data: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function createMyResume(resume) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/resumes/create", resume);
    if (result.success) {
      const { resumeId, token } = result.data;
      updateCookie(token, 2);
      dispatch({
        type: userTypes.RESUME_CREATED,
        resumeId,
      });
      history.push("/myResume");
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function getMyResume() {
  return async dispatch => {
    dispatch({ type: types.GET_RESUME_START });
    let result = await apiAuthRequest("get", "/resumes/getMyResume");
    if (result.success) {
      dispatch({
        type: types.GET_RESUME_SUCCESS,
        resume: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function getMyResumeToEdit() {
  return async dispatch => {
    // dispatch({ type: types.GET_RESUME_START });
    let result = await apiAuthRequest("get", "/resumes/getMyResumeToEdit");
    if (result.success) {
      dispatch({
        type: types.GET_RESUME_FOR_EDIT_SUCCESS,
        resume: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function updateResume(resume) {
  return async dispatch => {
    // dispatch({
    //   type: types.SAVE_RESUME_START,
    //   resume
    // });

    let result = await apiAuthRequest("post", "/resumes/editResume", resume);
    if (result.success) {
      dispatch({
        type: types.UPDATE_RESUME_SUCCESS,
        resume,
      });
      return true;
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function getResumeById(id, conversationResumeId) {
  const queryObj = { id };
  if (conversationResumeId)
    queryObj.conversationResumeId = conversationResumeId;
  return async dispatch => {
    dispatch({ type: types.GET_RESUME_START });
    let result = await apiAuthRequest(
      "get",
      "/resumes/getResumeById",
      queryObj,
    );
    if (result.success) {
      dispatch({
        type: types.GET_RESUME_SUCCESS,
        resume: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
