import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findDOMNode } from "react-dom";
import scrollIntoView from "scroll-into-view";
import * as conversationActions from "../../actions/singleConversationActions";
import SingleConversation from "./SingleConversation";
import InboxRightPanel from "./inboxRightPanel";
import { Scrollbars } from "react-custom-scrollbars";
import Popup from "../common/Popup";
import isMobile from "ismobilejs";
import FontAwesome from "react-fontawesome";

class InboxMainPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSidebar: !isMobile.any,
      height: 0,
      replyFocused: false,
      alert: null,
    };
  }

  showSidebar() {
    this.setState({ showSidebar: true });
  }

  sendMessage(message) {
    this.unfocusReply();
    this.props.conversationActions.sendMessage(message);
  }

  markConversationUnread() {
    this.props.conversationActions.markUnread(this.props.conversation.id);
  }

  markConversationRead() {
    this.props.conversationActions.marksAsRead(this.props.conversation.id);
  }

  reply() {
    let conversationRef = findDOMNode(this.refs.conversation);
    if (conversationRef) {
      scrollIntoView(conversationRef.children[2]);
      this.setState({ replyFocused: true });
    }
  }

  unfocusReply() {
    this.setState({ replyFocused: false });
  }

  render() {
    let renderThis;
    const { conversation, isLoading, selectedConversation } = this.props;
    if (isLoading) {
      renderThis = (
        <div id="content-main" className="header-padding column-layout-two">
          <div className="loader"></div>
        </div>
      );
    } else if (
      !isLoading &&
      conversation &&
      conversation.id &&
      selectedConversation
    ) {
      renderThis = (
        <div className="container">
          <div
            id="content-main"
            className={`header-padding ${
              this.state.showSidebar ? "" : "sidebar-hidden"
            }`}
          >
            <div className="container overflow-auto">
              <div className="inbox-chat-background" />
              <div className="panel-actions panel-actions-fixed">
                {this.props.displayMobile ? (
                  <div
                    onClick={this.props.switchMobileTab}
                    className="panel-action all-resumes"
                  >
                    <FontAwesome name="arrow-left" /> <p>Back to list</p>
                  </div>
                ) : null}
                <Popup
                  trigger={
                    <div
                      onClick={this.reply.bind(this)}
                      className="panel-action panel-action-button"
                    >
                      <FontAwesome name="reply" />
                      <p>Reply</p>
                    </div>
                  }
                  content="Click here to reply to a message"
                />

                {conversation.read ? (
                  <Popup
                    trigger={
                      <div
                        onClick={this.markConversationUnread.bind(this)}
                        className="panel-action"
                      >
                        <FontAwesome name="envelope" />
                        <p>Mark as unread</p>
                      </div>
                    }
                    content="Mark as unread"
                  />
                ) : (
                  <Popup
                    trigger={
                      <div
                        onClick={this.markConversationRead.bind(this)}
                        className="panel-action"
                      >
                        <FontAwesome name="eye" />
                        <p>Mark as read</p>
                      </div>
                    }
                    content="Click here to remove the notification symbol. "
                  />
                )}
              </div>
              <div className="content-padding-fixed-top">
                <Scrollbars
                  style={{
                    height: this.context.objectHeights
                      .mainPanelCalculatedHeight,
                  }}
                >
                  <div className="sidebar-tab active">
                    <div className="sidebar-resume-shares">
                      <SingleConversation
                        ref="conversation"
                        replyFocused={this.state.replyFocused}
                        unfocusReply={this.unfocusReply.bind(this)}
                        conversation={conversation}
                        sendMessage={this.sendMessage.bind(this)}
                        showSidebar={this.showSidebar.bind(this)}
                        sidebarOpen={this.state.showSidebar}
                      />
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
          <InboxRightPanel
            conversationId={conversation.id}
            showSidebar={this.state.showSidebar}
            toggleSidebar={() =>
              this.setState({ showSidebar: !this.state.showSidebar })
            }
          />
        </div>
      );
    } else {
      renderThis = (
        <div id="content-main" className="header-padding column-layout-two">
          <div className="content-padding message-empty">
            <i className="material-icons">mail_outline</i>
            <p>No Message Selected</p>
            <p>Select a message from the left panel to view its content.</p>
          </div>
        </div>
      );
    }
    return (
      <div
        className={`container ${this.props.displayMobile ? "" : "mobile-hide"}`}
      >
        {this.state.alert}
        {renderThis}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    conversation: state.singleConversation.conversation,
    isLoading: state.singleConversation.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    conversationActions: bindActionCreators(conversationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxMainPanel);
