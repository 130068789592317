import * as types from "../actionTypes/shadchanActionTypes";
import { mergeCurrentItemsWithNextPage } from "../components/common/utils";

export default function shadchanReducer(
  state = {
    shadchanim: [],
    selectedShadchan: {},
    pagination: { page: 1 },
    isLoadingSelectedShadchan: false,
    isLoading: 0,
    search: {},
    acceptedMembers: [],
    invitedMembers: [],
    requestedMembers: [],
  },
  action,
) {
  switch (action.type) {
    case types.LOAD_SHADCHANIM_START:
      return {
        ...state,
        isLoading:
          state.isLoading || state.isLoading === 0 ? state.isLoading + 1 : 1,
        shadchanim: action.search.pageNumber === 1 ? [] : state.shadchanim,
        search: action.search,
      };
    case types.LOAD_SHADCHANIM_SUCCESS:
      const { pagination, shadchanim } = action.shadchanim;
      let pageInfo = pagination;
      let newGroups = mergeCurrentItemsWithNextPage(
        state.shadchanim,
        shadchanim,
        pageInfo.page,
        pageInfo.pageSize,
      );
      return {
        ...state,
        shadchanim: newGroups,
        pagination: { ...state.pagination, ...pagination },
        isLoading: state.isLoading ? state.isLoading - 1 : 0,
      };
    case types.GET_SHADCHAN_START:
      return {
        ...state,
        isLoadingSelectedShadchan: true,
      };
    case types.GET_SHADCHAN_SUCCESS:
      return {
        ...state,
        isLoadingSelectedShadchan: false,
        selectedShadchan: action.shadchan,
      };
    case types.CLEAR_SELECTED_SHADCHAN:
      return {
        ...state,
        selectedShadchan: {},
      };
    case types.UPDATE_SHADCHAN_MEMBERS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case types.UPDATE_ACCEPTED_SHADCHAN_MEMBERS_SUCCESS:
      return {
        ...state,
        acceptedMembers: action.data,
      };
    case types.GET_MY_SHADCHAN_PROFILE_START:
      return {
        ...state,
        isLoadingSelectedShadchan: true,
      };
    case types.SOCKET_IO_REQUESTED_MEMBERS_UPDATE:
      return {
        ...state,
        requestedMembers: action.requestedMembers,
      };
    case types.GET_MY_SHADCHAN_PROFILE_SUCCESS:
      const { shadchan, ...rest } = action.data;
      return {
        ...state,
        selectedShadchan: shadchan,
        ...rest,
        isLoadingSelectedShadchan: false,
      };
    default:
      return state;
  }
}
