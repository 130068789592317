import React from "react";
import { connect } from "react-redux";
const baseURL = process.env.REACT_APP_API_URL;

class PrivateImage extends React.Component {
  render() {
    const {
      viewImages,
      myResume,
      fileName,
      section,
      id,
      resize,
      className,
      height,
      width,
      onClick,
    } = this.props;
    const src = Boolean(fileName && (viewImages || myResume))
      ? `${baseURL}/images/${section}/${id}/${fileName}?${
          resize ? `resize=${resize}` : ""
        }`
      : "/images/xl-placeholder.png";
    return (
      <img
        ref="currentImage"
        alt="img"
        src={src}
        className={className}
        height={height}
        width={width}
        onClick={onClick}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    viewImages: state.user.viewImages,
  };
}

export default connect(mapStateToProps)(PrivateImage);
