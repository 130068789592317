import * as types from "../actionTypes/conversationActionTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function getConversationById(id) {
  return async dispatch => {
    dispatch({
      type: types.LOAD_CONVERSATION_START,
    });

    let result = await apiAuthRequest("get", "/inbox/getById", { id });
    if (result.success) {
      dispatch({
        type: types.LOAD_CONVERSATION_SUCCESS,
        conversation: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function receivedNewMessage(data, dispatch) {
  dispatch({ type: types.NEW_MESSAGE_RECEIVED, data });
}

export function sendMessage(message) {
  return async dispatch => {
    const result = await apiAuthRequest("post", "/inbox/reply", message);
    if (result.success) {
      dispatch({ type: types.SEND_MESSAGE_SUCCESS, message: result.data });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function markUnread(conversationId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/inbox/markUnread", {
      conversationId: conversationId,
    });
    if (result.success) {
      dispatch({
        type: types.MARK_CONVERSATION_UNREAD,
        conversationId,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
export function marksAsRead(conversationId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/inbox/markAsRead", {
      conversationId: conversationId,
    });
    if (result.success) {
      dispatch({
        type: types.MARK_CONVERSATION_READ,
        conversationId,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
export function archiveConversation(conversation) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/inbox/archive", {
      conversationId: conversation.id,
    });
    if (result.success) {
      dispatch({
        type: types.ARCHIVE_CONVERSATION,
        conversation,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
export function unarchiveConversation(conversation) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/inbox/unarchive", {
      conversationId: conversation.id,
    });
    if (result.success) {
      dispatch({
        type: types.UNARCHIVE_CONVERSATION,
        conversation,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function socketIoConversationResumesUpdate(data, dispatch) {
  dispatch({ type: types.SOCKET_IO_CONVERSATION_RESUME_UPDATE, data });
}
