import React from "react";
import PropTypes from "prop-types";
import InboxLeftPanel from "./inboxLeftPanel";
import InboxMainPanel from "./inboxMainPanel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import * as singleConversationActions from "../../actions/singleConversationActions";

class ConversationDisplay extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const conversationId = props.match.params.id;
    this.state = {
      selectedConversation: conversationId ? conversationId : null,
      mobileTab: props.match.params.id ? "single" : "list",
    };
  }
  componentDidMount() {
    const selectedConversation = this.state.selectedConversation;
    if (selectedConversation) {
      this.getSelectedConversation();
    }
  }

  componentDidUpdate(prevProps) {
    const prevSelectedConversation = prevProps.match.params.id;
    const currentSelectedConversation = this.props.match.params.id;
    if (prevSelectedConversation !== currentSelectedConversation) {
      if (currentSelectedConversation) {
        this.setState(
          () => ({
            mobileTab: "single",
            selectedConversation: currentSelectedConversation,
          }),
          this.getSelectedConversation,
        );
      } else {
        this.setState({ selectedConversation: null, mobileTab: "list" });
      }
    }
  }

  getSelectedConversation = () => {
    const { props, state } = this;
    props.singleActions.getConversationById(state.selectedConversation);
  };

  selectConversation(id) {
    this.props.history.push(`/inbox/${id}`);
  }

  render() {
    const {
      props: { unreadConversations },
      state: { selectedConversation, mobileTab },
    } = this;

    const unreadCount = unreadConversations.length;
    const title = `Inbox ${
      unreadCount > 0 ? `[${unreadCount}]` : ""
    } | Zivug Zone`;

    return (
      <div className="main-container mui-container-fluid">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <InboxLeftPanel
          handleClick={this.selectConversation.bind(this)}
          selectedConversation={selectedConversation}
          displayMobile={mobileTab === "list"}
          switchMobileTab={() => this.setState({ mobileTab: "single" })}
        />
        <InboxMainPanel
          selectedConversation={selectedConversation}
          displayMobile={mobileTab === "single"}
          switchMobileTab={() => {
            this.props.history.push(`/inbox`);
            this.setState({ mobileTab: "list" });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    unreadConversations: state.conversations.unreadConversations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    singleActions: bindActionCreators(singleConversationActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConversationDisplay);
