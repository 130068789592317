import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../actions/userActions";
import Subheader from "../../common/SubHeader";
import * as yup from "yup";
import Form from "react-formal";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import LocationInput from "../../common/locationInput";
import { validPhoneRegex } from "../../../zivugZoneUtils";

const accountSettingsSchema = yup.object().shape({
  id: yup.mixed(),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phone: yup
    .string()
    .matches(validPhoneRegex, "Phone number is not valid")
    .required("Required"),
  location: yup.string(),
  viewImages: yup.boolean().nullable(),
});

const securitySettingsSchema = yup.object().shape({
  oldPassword: yup.string().required("Required"),
  newPassword: yup
    .string()
    .required("Required")
    .min(6, "Password is too short"),
  confirmNewPassword: yup
    .mixed()
    .test("match", "Passwords don't match", function(confirmPassword) {
      return confirmPassword === this.parent.newPassword;
    }),
});

class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, phone, location, id, viewImages } = props.user;
    this.state = {
      account: {
        id,
        firstName: firstName || "",
        lastName: lastName || "",
        phone: phone || "",
        location: location || "",
        viewImages,
      },
      security: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.settings.accountSettingsSaved ||
      nextProps.settings.accountSettingsError
    ) {
      this.setState({ savingAccountSettings: false });
    }
    if (
      nextProps.settings.securitySettingsSaved ||
      nextProps.settings.securitySettingsError
    ) {
      let security = this.state.security;
      if (nextProps.settings.securitySettingsSaved) {
        security = {};
      }
      this.setState({ savingSecuritySettings: false, security });
    }
  }

  updateAccount = values => {
    this.setState({ savingAccountSettings: true, submitted: true }, () => {
      this.props.actions.updateUser(values);
    });
  };

  updateSecurity = values => {
    this.setState({ savingSecuritySettings: true, submitted: true }, () => {
      this.props.actions.updatePassword(values);
    });
  };

  render() {
    let {
      accountSettingsSaved,
      accountSettingsError,
      securitySettingsSaved,
      securitySettingsError,
      errorMessage,
    } = this.props.settings;
    let submitted = this.state.submitted;
    return (
      <div>
        <Helmet>
          <title>Account Settings | Zivug Zone</title>
        </Helmet>
        <Subheader title="Account Settings" />
        <div className="mui-container-fluid">
          <div
            className="content header-padding header-padding-subheader"
            id="form"
          >
            <div className="mui-col-md-10">
              <Form
                onSubmit={this.updateAccount}
                schema={accountSettingsSchema}
                value={this.state.account}
                onChange={account =>
                  this.setState({ account, submitted: false })
                }
              >
                <div className="account-settings-panel mui-panel">
                  <div className="form-heading primary-color">
                    <h3 className="organization-info">Personal Info</h3>
                  </div>
                  <div className="mui-row">
                    <div className="form-inline-half">
                      <legend>
                        First Name *{" "}
                        <Form.Message className="has-error" for="firstName" />
                      </legend>
                      <Form.Field name="firstName" />
                    </div>

                    <div className="form-inline-half">
                      <legend>
                        Last Name *{" "}
                        <Form.Message className="has-error" for="lastName" />
                      </legend>
                      <Form.Field name="lastName" />
                    </div>
                  </div>
                  <div className="mui-row">
                    <div className="form-inline-half mui-col-md-6">
                      <legend>
                        Phone Number *{" "}
                        <Form.Message className="has-error" for="phone" />
                      </legend>
                      <Form.Field name="phone" />
                    </div>

                    <div className="form-inline-half mui-col-md-6">
                      <legend>View Resume Images </legend>
                      <Form.Field
                        type="checkbox"
                        className="mui-radio inline-radio"
                        name="viewImages"
                      />
                    </div>
                  </div>

                  <div className="mui-row">
                    <div className="mui-col-md-12">
                      <legend>
                        Location{" "}
                        <Form.Message className="has-error" for="location" />
                      </legend>
                      <Form.Field
                        name="location"
                        placeholder="City, State"
                        className="form-input-margin-bottom"
                        as={LocationInput}
                      />
                    </div>
                  </div>
                  <div className="footer-singleForm">
                    {this.state.savingAccountSettings ? (
                      <div className="btn-flat btn-primaryAccent">
                        <div className="loader-small"></div>
                      </div>
                    ) : (
                      <Form.Submit
                        type="submit"
                        className="btn-flat btn-primaryAccent"
                      >
                        Update Personal Info
                      </Form.Submit>
                    )}
                    {submitted && accountSettingsSaved && (
                      <p className="settings-message">
                        Settings saved successfully
                      </p>
                    )}
                    {submitted && accountSettingsError && (
                      <p className="settings-message">{errorMessage}</p>
                    )}
                  </div>
                </div>
              </Form>

              <Form
                onSubmit={this.updateSecurity}
                schema={securitySettingsSchema}
                value={this.state.security}
                onChange={security =>
                  this.setState({ security, submitted: false })
                }
              >
                <div className="account-settings-panel mui-panel">
                  <div className="form-heading primary-color">
                    <h3 className="organization-info" ref="personalInfo">
                      Sign-in & Security
                    </h3>
                  </div>
                  <div className="mui-row">
                    <div className="form-inline-third">
                      <legend>
                        Current Password *{" "}
                        <Form.Message className="has-error" for="oldPassword" />
                      </legend>
                      <Form.Field name="oldPassword" type="password" />
                    </div>
                    <div className="form-inline-third">
                      <legend>
                        New Password *{" "}
                        <Form.Message className="has-error" for="newPassword" />
                      </legend>
                      <Form.Field
                        name="newPassword"
                        validates={
                          this.state.security.confirmPassword
                            ? ["confirmPassword"]
                            : []
                        }
                        type="password"
                      />
                    </div>

                    <div className="form-inline-third">
                      <legend>
                        Confirm Password *{" "}
                        <Form.Message
                          className="has-error"
                          for="confirmNewPassword"
                        />
                      </legend>
                      <Form.Field name="confirmNewPassword" type="password" />
                    </div>
                  </div>

                  <div className="footer-singleForm">
                    {this.state.savingSecuritySettings ? (
                      <div className="btn-flat btn-primaryAccent">
                        <div className="loader-small"></div>
                      </div>
                    ) : (
                      <Form.Submit
                        type="submit"
                        className="btn-flat btn-primaryAccent"
                      >
                        Save New Password
                      </Form.Submit>
                    )}
                    {submitted && securitySettingsSaved && (
                      <p className="settings-message">Password Updated</p>
                    )}
                    {submitted && securitySettingsError && (
                      <p className="settings-message">{errorMessage}</p>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ReactTooltip effect="solid" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    constants: state.constants,
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
