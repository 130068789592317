import React from "react";
import * as modalActions from "../../actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Resume from "../resumes/Resume";
import { getResumeFieldsForNewIdea } from "../../zivugZoneUtils";

class ResumeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="idea-modal large-modal">
        <div className="mm-resume-modal-actions">
          <div
            onClick={() =>
              this.props.modalActions.setModal(
                "IDEA",
                getResumeFieldsForNewIdea(this.props.resume),
              )
            }
            className="make-match-button"
          >
            Make Match
          </div>
          <i
            className="material-icons modal-close-icon"
            style={{ fontSize: "36px" }}
            onClick={this.props.removeModal.bind(this)}
          >
            close
          </i>
        </div>
        <Resume noPadding={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    resumes: { selectedResume },
  } = state;
  return {
    resume: selectedResume,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeModal);
