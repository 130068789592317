import * as types from "../actionTypes/notificationActionTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function loadNotifications() {
  return async dispatch => {
    let result = await apiAuthRequest("get", "/notifications/getByUser");
    if (result.success) {
      dispatch({
        type: types.LOAD_NOTIFICATIONS_SUCCESS,
        notifications: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function receivedNewNotification(notifications, dispatch) {
  dispatch({ type: types.NEW_NOTIFICATION_RECEIVED, notifications });
}

export function markNotificationAsRead(notificationId) {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/notifications/markAsRead", {
      notificationId,
    });
    if (result.success) {
      dispatch({
        type: types.NOTIFICATION_READ_SUCCESS,
        notificationId,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}

export function clearAllNotifications() {
  return async dispatch => {
    let result = await apiAuthRequest("post", "/notifications/markAllAsRead");
    if (result.success) {
      dispatch({
        type: types.CLEAR_NOTIFICATIONS_SUCCESS,
      });
    } else {
      console.error(result.data);
      toastr.error(result.data);
    }
  };
}
