import React from "react";
import * as ideasActions from "../../actions/myMatchesActions";
import * as modalActions from "../../actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Resume from "../resumes/Resume";
import { getResumeFieldsForNewIdea } from "../../zivugZoneUtils";
import history from "../../lib/history";

class InboxResumeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  rejectIdea = async () => {
    const { ideaId, conversationId, actions, unselectResume } = this.props;
    const success = await actions.rejectIdea({ ideaId, conversationId });
    if (success) unselectResume();
  };

  acceptIdea = () => {
    const { ideaId, conversationId, actions } = this.props;
    actions.acceptIdea({ ideaId, conversationId });
  };

  render() {
    const { ideaStatus, role, modalActions, selectedResume } = this.props;
    let actionSection;
    switch (true) {
      case role === "shadchan" && !ideaStatus:
        actionSection = (
          <div
            onClick={() => {
              history.push("/ideas");
              modalActions.setModal(
                "IDEA",
                getResumeFieldsForNewIdea(selectedResume),
              );
            }}
            className="inbox-modal-button inbox-modal-button-make-match"
          >
            Make Match
          </div>
        );
        break;
      case role === "shadchan" && ideaStatus !== 5:
        actionSection = (
          <div className="inbox-modal-resume-status">Waiting response</div>
        );
        break;
      case role === "shadchan" && ideaStatus === 5:
        actionSection = (
          <div className="inbox-modal-resume-status">
            Resume has been accepted
          </div>
        );
        break;
      case role === "single" && ideaStatus !== 5:
        actionSection = (
          <div className="flex">
            <div
              className="inbox-modal-button inbox-modal-button-accept"
              onClick={this.acceptIdea}
            >
              Accept
            </div>
            <div
              className="inbox-modal-button inbox-modal-button-reject"
              onClick={this.rejectIdea}
            >
              Reject
            </div>
          </div>
        );
        break;
      case role === "single" && ideaStatus === 5:
        actionSection = (
          <div className="inbox-modal-resume-status">
            You have accepted this resume
          </div>
        );
        break;

      default:
        actionSection = null;
        break;
    }
    return (
      <div className="idea-modal large-modal">
        <div className="inbox-modal-actions">
          {actionSection}
          <i
            className="material-icons modal-close-icon"
            style={{ fontSize: "36px" }}
            onClick={this.props.removeModal.bind(this)}
          >
            close
          </i>
        </div>
        <Resume noPadding={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    resumes: {
      selectedResume = {},
      selectedResume: { ideaId, conversationId, ideaStatus } = {},
    },
    user: { role },
  } = state;
  return {
    ideaId,
    conversationId,
    ideaStatus,
    role,
    selectedResume,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ideasActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxResumeModal);
