import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shadchanActions from "../../actions/shadchanActions";
import ShadchanInfo from "./components/ShadchanInfo";
import ShadchanMembers from "./components/ShadchanMembers";
import { Helmet } from "react-helmet";
import history from "../../lib/history";
import queryString from "query-string";

class MyShadchanProfilePage extends Component {
  constructor(props) {
    super(props);
    const { tab } = queryString.parse(props.location.search);
    this.state = {
      activeTab: tab && tab === "members" ? "members" : "about",
    };
  }

  componentDidMount() {
    this.props.actions.getMyShadchanProfile();
  }

  componentDidUpdate(prevProps) {
    const { tab: currentTab } = queryString.parse(this.props.location.search);
    const { tab: prevTab } = queryString.parse(prevProps.location.search);
    if (
      currentTab &&
      currentTab !== prevTab &&
      (currentTab === "members" || currentTab === "about")
    ) {
      this.setState({ activeTab: currentTab });
    }
  }

  changeActiveTab(tab) {
    history.push(`/myShadchanProfile?tab=${tab}`);
  }

  render() {
    const {
      state: { activeTab },
      props: {
        selectedShadchan: { firstName, lastName },
        isLoadingSelectedShadchan,
      },
    } = this;

    const shadchanName = firstName ? `${firstName} ${lastName}` : "";
    const image = "../images/small-placeholder.png";

    return (
      <Fragment>
        <Helmet>
          <title>My Shadchan Profile | Zivug Zone</title>
        </Helmet>
        {isLoadingSelectedShadchan && <div className="loader"></div>}
        {!isLoadingSelectedShadchan && (
          <div className="header-padding shadchan-profile-container">
            <div className="resume-header resume-section flex">
              <img
                src={image}
                alt=""
                className="resume-header-image"
                height="125"
                width="125"
              />

              <div className="resume-header-info my-shadchan-header-info">
                <h2 className="resume-name resume-name-container">
                  {shadchanName}
                </h2>
                <div
                  className="my-shadchan-edit-button"
                  onClick={() => history.push("/myShadchanProfile/edit")}
                >
                  edit
                </div>
              </div>
            </div>

            <div className="container">
              <div className="panel-actions panel-actions-bar">
                <div
                  className={`panel-action panel-action-left ${
                    activeTab === "about" ? "active" : ""
                  }`}
                  onClick={() => this.changeActiveTab("about")}
                >
                  <p>About</p>
                </div>
                <div
                  className={`panel-action panel-action-right ${
                    activeTab === "members" ? "active" : ""
                  }`}
                  onClick={() => this.changeActiveTab("members")}
                >
                  <p>My Members</p>
                </div>
              </div>
              <div className="section clearfix">
                <div
                  className={
                    this.state.activeTab === "about"
                      ? "sidebar-tab active"
                      : "sidebar-tab"
                  }
                >
                  <ShadchanInfo />
                </div>
                <div
                  className={
                    this.state.activeTab === "members"
                      ? "sidebar-tab active"
                      : "sidebar-tab"
                  }
                >
                  <ShadchanMembers />
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { selectedShadchan, isLoadingSelectedShadchan } = state.shadchan;
  return { selectedShadchan, isLoadingSelectedShadchan };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(shadchanActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyShadchanProfilePage);
