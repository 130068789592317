import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getNextUrlFromParams } from "../common/utils";
import { getRedirectUrl } from "../../zivugZoneUtils";

class LoadingPage extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.checkAuth();
    }
  }

  checkAuth = props => {
    const {
      user: { isAuthenticated, role, hasResume, isLoading },
      location,
      history,
    } = this.props;
    let nextRoute = getNextUrlFromParams(location.search);
    if (isAuthenticated) {
      history.push(getRedirectUrl(nextRoute, role, hasResume));
    } else if (!isLoading) {
      this.props.history.push(`/login${nextRoute ? `?next=${nextRoute}` : ""}`);
    }
  };

  render() {
    return (
      <div className="page-account-container">
        <div className="loader"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LoadingPage);
