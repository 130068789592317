import React, { Fragment } from "react";
import Lightbox from "react-images";
const baseURL = process.env.REACT_APP_API_URL;

class PrivateLightbox extends React.Component {
  render() {
    const { images, currentImage } = this.props;
    let resumeImages;
    if (images) {
      resumeImages = this.props.images.map(image => {
        return {
          src: `${baseURL}/images/${this.props.section}/${this.props.id}/${image.fileLocation}`,
        };
      });
    }
    return (
      <Fragment>
        {images && typeof currentImage === "number" && (
          <Lightbox
            ref="lightbox"
            currentImage={currentImage}
            images={resumeImages}
            isOpen={this.props.isOpen}
            onClose={this.props.onClose}
            onClickPrev={this.props.onClickPrev}
            onClickNext={this.props.onClickNext}
            backdropClosesModal={true}
          />
        )}
      </Fragment>
    );
  }
}

export default PrivateLightbox;
