import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActions from "../../actions/modalActions";
import { cutString } from "../common/utils";
import PrivateImage from "../common/privateImage";

class DatingIdeas extends Component {
  render() {
    const { ideas } = this.props;
    return (
      <Fragment>
        {ideas.map(i => {
          const {
            id,
            hisResumeId,
            hisFirstName,
            hisLastName,
            hisAge,
            hisMaritalStatus,
            hisHeight,
            hisImage,
            herResumeId,
            herFirstName,
            herLastName,
            herAge,
            herMaritalStatus,
            herHeight,
            herImage,
          } = i;
          return (
            <div
              key={id}
              className="idea-items-group"
              onClick={() => this.props.modalActions.setModal("IDEA", i)}
            >
              <div className="ideas-card-row">
                <div className="idea-card">
                  <PrivateImage
                    fileName={hisImage}
                    id={hisResumeId}
                    section="resumeSmall"
                    className="idea-card-image"
                  />
                  <div className="idea-card-info">
                    <h5 className="idea-card-title">
                      {cutString(`${hisFirstName} ${hisLastName}`, 20)}
                    </h5>
                    <div className="idea-card-details">
                      <div className="flex">
                        <span>{hisAge}</span>
                        <span>{hisHeight}</span>
                        <span>{hisMaritalStatus}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="idea-card">
                  <PrivateImage
                    fileName={herImage}
                    id={herResumeId}
                    section="resumeSmall"
                    className="idea-card-image"
                  />
                  <div className="idea-card-info">
                    <h5 className="idea-card-title">
                      {cutString(`${herFirstName} ${herLastName}`, 20)}
                    </h5>
                    <div className="idea-card-details">
                      <div className="flex">
                        <span>{herAge}</span>
                        <span>{herHeight}</span>
                        <span>{herMaritalStatus}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    ideas: state.myMatches.datingIdeas,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatingIdeas);
