import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActions from "../../actions/modalActions";
import * as myMatchesActions from "../../actions/myMatchesActions";
import { cutString } from "../common/utils";
import { SAID_YES, SAID_NO, SUGGESTED } from "../../lib/ideaStatuses";
import PrivateImage from "../common/privateImage";

class WorkingIdeas extends Component {
  state = {
    sentIdeas: [],
  };

  sendMatch(e, ideaId, recipientResumeId) {
    e.stopPropagation();
    const sendId = `${ideaId}${recipientResumeId}`;
    if (this.state.sentIdeas.includes(sendId)) return;
    this.setState(
      prevState => ({
        sentIdeas: [sendId, ...prevState.sentIdeas],
      }),
      () => {
        this.props.myMatchesActions.sendIdea({
          ideaId,
          recipientResumeId,
        });
      },
    );
  }

  render() {
    const { ideas } = this.props;
    return (
      <Fragment>
        {ideas.map(i => {
          const {
            id,
            hisResumeId,
            hisFirstName,
            hisLastName,
            hisAge,
            hisMaritalStatus,
            hisHeight,
            hisStatus,
            hisImage,
            herResumeId,
            herFirstName,
            herLastName,
            herAge,
            herMaritalStatus,
            herHeight,
            herStatus,
            herImage,
          } = i;
          return (
            <div
              key={id}
              className="idea-items-group"
              onClick={() => this.props.modalActions.setModal("IDEA", i)}
            >
              <div className="ideas-card-row">
                <div className="idea-card">
                  <PrivateImage
                    fileName={hisImage}
                    id={hisResumeId}
                    section="resumeSmall"
                    className="idea-card-image"
                  />
                  <div className="idea-card-info">
                    <h5 className="idea-card-title">
                      {cutString(`${hisFirstName} ${hisLastName}`, 20)}
                    </h5>
                    <div className="idea-card-details">
                      <div className="flex">
                        <span>{hisAge}</span>
                        <span>{hisHeight}</span>
                        <span>{hisMaritalStatus}</span>
                      </div>
                      {hisStatus === SAID_YES ? (
                        <div className="created-action created-action-accepted">
                          Accepted
                        </div>
                      ) : hisStatus === SAID_NO ? (
                        <div className="created-action created-action-rejected">
                          Rejected
                        </div>
                      ) : hisStatus === SUGGESTED ? (
                        <div className="created-action created-action-inactive">
                          match sent
                        </div>
                      ) : (
                        <div
                          className="created-action created-action-active"
                          onClick={e => this.sendMatch(e, id, hisResumeId)}
                        >
                          send match
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="idea-card">
                  <PrivateImage
                    fileName={herImage}
                    id={herResumeId}
                    section="resumeSmall"
                    className="idea-card-image"
                  />
                  <div className="idea-card-info">
                    <h5 className="idea-card-title">
                      {cutString(`${herFirstName} ${herLastName}`, 20)}
                    </h5>
                    <div className="idea-card-details">
                      <div className="flex">
                        <span>{herAge}</span>
                        <span>{herHeight}</span>
                        <span>{herMaritalStatus}</span>
                      </div>
                      {herStatus === SAID_YES ? (
                        <div className="created-action created-action-accepted">
                          Accepted
                        </div>
                      ) : herStatus === SAID_NO ? (
                        <div className="created-action created-action-rejected">
                          Rejected
                        </div>
                      ) : herStatus === SUGGESTED ? (
                        <div className="created-action created-action-inactive">
                          match sent
                        </div>
                      ) : (
                        <div
                          className="created-action created-action-active"
                          onClick={e => this.sendMatch(e, id, herResumeId)}
                        >
                          send match
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    ideas: state.myMatches.workingIdeas,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    myMatchesActions: bindActionCreators(myMatchesActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkingIdeas);
