import React from "react";
import FontAwesome from "react-fontawesome";
import PrivateImage from "../common/privateImage";
import PrivateLightbox from "../common/privateLightbox";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Resume extends React.Component {
  state = {
    currentImage: 0,
    lightboxIsOpen: false,
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  goToPrevLightboxImage = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  goToNextLightboxImage = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  openLightbox = imageIndex => {
    this.setState({
      currentImage: imageIndex,
      lightboxIsOpen: true,
    });
  };

  render() {
    const { resume, sidebarView, noPadding, myResume, viewImages } = this.props;
    let fileLocation = "";
    if (resume && resume.images && resume.images.length) {
      fileLocation = resume.images[0].fileLocation;
    }

    return (
      <div
        ref="resumeInfo"
        className={sidebarView || noPadding ? "" : "header-padding"}
      >
        {Object.keys(resume).length > 0 && (
          <div className="create-resume-container  resumePage mui-container">
            <div
              className={`resume-header resume-section ${
                sidebarView ? "inbox-resume-header" : ""
              }`}
            >
              {Boolean(fileLocation && (viewImages || myResume)) ? (
                <div className="resume-header-image link">
                  <PrivateImage
                    fileName={fileLocation}
                    id={resume.id}
                    section="resume"
                    myResume={myResume}
                    onClick={() => this.openLightbox(0)}
                  />
                  <PrivateLightbox
                    currentImage={this.state.currentImage}
                    id={resume.id}
                    section="resume"
                    images={resume.images}
                    isOpen={this.state.lightboxIsOpen}
                    onClose={this.closeLightbox}
                    onClickPrev={this.goToPrevLightboxImage}
                    onClickNext={this.goToNextLightboxImage}
                  />
                </div>
              ) : (
                <img
                  alt="placeholder"
                  src="/images/xl-placeholder.png"
                  className="resume-header-image"
                  height="125"
                  width="125"
                />
              )}

              <div className="resume-header-info">
                <h2 className="resume-name large-name resume-name-container">
                  {resume.firstName} {resume.lastName}
                  {resume.fullName && (
                    <span className="resume-fullName">({resume.fullName})</span>
                  )}
                  {myResume && (
                    <Link className="edit-button" to={`/myResume/create-edit`}>
                      edit
                    </Link>
                  )}
                </h2>
                <div className="resume-header-details">
                  <p>
                    <span className="resume-header-detail-title">Age</span>
                    <span>{resume.age || "-"}</span>
                  </p>
                  <p>
                    <span className="resume-header-detail-title">Height</span>
                    <span>{resume.height}</span>
                  </p>
                  <p>
                    <span className="resume-header-detail-title">
                      Marital Status
                    </span>
                    <span>
                      {resume.maritalStatus}{" "}
                      {resume.numberChildren > 0
                        ? `/ ${resume.numberChildren}`
                        : ""}
                    </span>
                  </p>
                </div>
              </div>
              {!sidebarView ? (
                <div className="resume-id">
                  <span>
                    Updated: {moment(resume.updatedAt).format("MM/D/YYYY")}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="content single-resume-content">
              <div className="mui-col-md-12">
                <div className="panelContainer resume-section">
                  <div className="resume create-resume">
                    <div className="resume-content">
                      <div className="resume-column-left">
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>Personal Info</h3>
                          </div>
                          <div className="resume-content">
                            <table className="resume-table">
                              <tbody>
                                <tr>
                                  <td className="resume-table-title">
                                    Gender:
                                  </td>
                                  <td className="details-text">
                                    {resume.gender}
                                  </td>
                                </tr>
                                {resume.ethnicity ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Ethnicity:
                                    </td>
                                    <td className="details-text">
                                      {resume.ethnicity}
                                    </td>
                                  </tr>
                                ) : null}
                                {resume.languages.length ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Languages:
                                    </td>
                                    {resume.languages.map(l => (
                                      <td
                                        key={`language${l.id}`}
                                        className="details-text language"
                                      >
                                        {l.text}
                                      </td>
                                    ))}
                                  </tr>
                                ) : null}
                                {resume.religious ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Religious Background:
                                    </td>
                                    <td className="details-text">
                                      {resume.religious}
                                    </td>
                                  </tr>
                                ) : null}
                                {resume.type ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Type:
                                    </td>
                                    <td className="details-text">
                                      {resume.type}
                                    </td>
                                  </tr>
                                ) : null}
                                {resume.gender === "Male" ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Cohen:
                                    </td>
                                    <td className="details-text">
                                      {resume.isCohen ? "Yes" : "--"}
                                    </td>
                                  </tr>
                                ) : null}
                                <tr>
                                  <td className="resume-table-title">
                                    Address:
                                  </td>
                                  <td className="details-text">
                                    {resume.address}
                                    <br />
                                    {resume.city}, {resume.state}{" "}
                                    {resume.country}
                                  </td>
                                </tr>
                                {resume.hometown ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Hometown:
                                    </td>
                                    <td className="details-text">
                                      {resume.hometown}
                                    </td>
                                  </tr>
                                ) : null}
                                {resume.relocate ? (
                                  <tr>
                                    <td className="resume-table-title">
                                      Willing to Relocate:
                                    </td>
                                    <td className="details-text">
                                      {resume.relocate}
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {resume.personalDescription ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 ref="personalDescription">
                                Personal Description
                              </h3>
                            </div>
                            <div className="resume-content">
                              <p>{resume.personalDescription}</p>
                            </div>
                          </div>
                        ) : null}

                        {resume.lookingFor ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 ref="personalDescription">Looking For</h3>
                            </div>
                            <div className="resume-content">
                              <p>{resume.lookingFor}</p>
                            </div>
                          </div>
                        ) : null}

                        {resume.familyInfo.info ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3>Family</h3>
                            </div>
                            <div className="resume-content">
                              <ul>
                                <li>
                                  <p className="margin-top preserve-whitespace">
                                    {resume.familyInfo.info}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : null}

                        {resume.shuls.length ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 ref="test">Shul</h3>
                            </div>
                            <div className="resume-content">
                              {resume.shuls &&
                                resume.shuls.map(shul => {
                                  return (
                                    <div key={`shul${shul.id}`}>
                                      <p className="bold no-margin-bottom">
                                        {shul.name}
                                      </p>
                                      <p className="rabbi-name no-margin-bottom">
                                        {shul.rabbi}
                                      </p>
                                      <p className="no-margin-bottom">
                                        {shul.rabbiPhone}
                                      </p>
                                      <p className="details-text">
                                        {shul.details}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}

                        {resume.camps.length ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 ref="test">Camps</h3>
                            </div>
                            <div className="resume-content">
                              {resume.camps &&
                                resume.camps.map(camp => {
                                  return (
                                    <div key={`camp${camp.id}`}>
                                      <p className="bold inline no-margin-bottom">
                                        {camp.name}
                                      </p>
                                      <p className="details-text">
                                        {camp.details}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}

                        {resume.volunteer.length ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 ref="test">
                                Other / Organization / Volunteer Work
                              </h3>
                            </div>
                            <div className="resume-content">
                              {resume.volunteer &&
                                resume.volunteer.map(item => {
                                  return (
                                    <div key={`volunteer${item.id}`}>
                                      <p className="bold inline no-margin-bottom">
                                        {item.name}
                                      </p>
                                      <p className="details-text">
                                        {item.details}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="resume-column-right">
                        {resume.images.length > 1 ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3>Photos</h3>
                            </div>
                            <div className="resume-content">
                              <div className="resume-photos">
                                {resume.images.map((image, i) => {
                                  if (i > 0) {
                                    return (
                                      <div
                                        className="resume-pic"
                                        key={`image${image.id}`}
                                      >
                                        <PrivateImage
                                          fileName={image.fileLocation}
                                          id={resume.id}
                                          section="resumeSmall"
                                          myResume={myResume}
                                          onClick={
                                            viewImages || myResume
                                              ? () => this.openLightbox(i)
                                              : null
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {resume.displayPersonalContactInfo &&
                        (resume.mobilePhone ||
                          resume.homePhone ||
                          resume.email ||
                          resume.shidduchContactInfo) ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3>{resume.firstName}'s Contact Info</h3>
                            </div>
                            <div className="resume-content">
                              <p className="my-contact-info no-margin-bottom">
                                {resume.mobilePhone}
                              </p>
                              <p className="my-contact-info no-margin-bottom">
                                {resume.homePhone}
                              </p>
                              <p className="my-contact-info">{resume.email}</p>
                              <p>{resume.shidduchContactInfo}</p>
                            </div>
                          </div>
                        ) : null}
                        {resume.contactName ||
                        resume.contactPhone ||
                        resume.contactEmail ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 className="inline">Resume Contact Info</h3>
                            </div>
                            <div className="resume-content">
                              <p className="bold inline no-margin-bottom">
                                {resume.contactName}
                                <span className="reference-relationship">
                                  {resume.contactRelationship}
                                </span>
                              </p>
                              <p className="no-margin-bottom">
                                {resume.contactPhone}
                              </p>
                              <p>{resume.contactEmail}</p>
                            </div>
                          </div>
                        ) : null}
                        {resume.shadchanName && (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3 className="inline">Shadchan Contact Info</h3>
                            </div>
                            <div className="resume-content">
                              <p className="bold inline no-margin-bottom">
                                {resume.shadchanName}
                              </p>
                              <p className="no-margin-bottom">
                                {resume.shadchanPhone}
                              </p>
                              <p>{resume.shadchanEmail}</p>
                              <p>{resume.shadchanDetails}</p>
                            </div>
                          </div>
                        )}

                        {resume.occupation && resume.occupation.id ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3>Occupation</h3>
                            </div>
                            <div className="resume-content">
                              <p className="bold inline no-margin-bottom">
                                {resume.occupation.name}
                              </p>
                              <p className="details-text">
                                {resume.occupation.details}
                              </p>
                            </div>
                          </div>
                        ) : null}

                        {resume.education.length ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3>Education</h3>
                            </div>
                            <div className="resume-content">
                              {resume.education &&
                                resume.education.map(ed => {
                                  return (
                                    <div
                                      key={`ed${ed.id}`}
                                      className="resume-timeline"
                                    >
                                      <FontAwesome
                                        name="circle"
                                        className="resume-circle"
                                      />
                                      <p className="bold inline">{ed.name}</p>
                                      <p className="details-text">
                                        {ed.details}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}

                        {resume.references.length ? (
                          <div className="resume-section">
                            <div className="resume-title-header">
                              <h3>References</h3>
                            </div>
                            <div className="resume-content">
                              <ul>
                                {resume.references.map(reference => {
                                  return (
                                    <li key={`reference${reference.id}`}>
                                      <p className="bold inline no-margin-bottom">
                                        {reference.name}
                                        <span className="reference-relationship">
                                          {reference.referenceType}
                                        </span>
                                      </p>
                                      {reference.contactInfo &&
                                        reference.contactInfo.map(info => {
                                          return (
                                            <p
                                              key={`refInfo${info.id}`}
                                              className="no-margin-bottom"
                                            >
                                              {info.contactText}
                                            </p>
                                          );
                                        })}
                                      <p className="details-text">
                                        {reference.details}
                                      </p>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    resumes: { selectedResume: resume },
    user: { resumeId, viewImages },
  } = state;
  return { resume, resumeId, viewImages };
}

export default connect(mapStateToProps)(Resume);
