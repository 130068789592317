import React from "react";
import { connect } from "react-redux";
import { convertToString } from "../../common/propsConverter";

class ShadchanInfo extends React.Component {
  typesList = type => <li key={type.id}>{type.text}</li>;
  specialtiesList = specialty => <li key={specialty.id}>{specialty.text}</li>;

  render() {
    const {
      constants,
      shadchan: {
        ageStart,
        ageEnd,
        about,
        gender,
        location,
        shadchanEthnicityId,
        shadchanTypeId,
        languages = [],
        types = [],
        specialties = [],
      } = {},
    } = this.props;

    return (
      <div className="mui-row">
        <div className="mui-col-md-7">
          <div className="content-main-section">
            <h3 className="title">About Shadchan</h3>
            <p className="group-description">{about}</p>
            <p>
              <span className="section-infoTitle">Gender:</span>{" "}
              {convertToString(constants.gender, gender)}
            </p>
            <p>
              <span className="section-infoTitle">Location:</span> {location}
            </p>
            <p>
              <span className="section-infoTitle">Ethnicity:</span>{" "}
              {convertToString(constants.ethnicity, shadchanEthnicityId)}
            </p>
            <p>
              <span className="section-infoTitle">Type:</span>{" "}
              {convertToString(constants.types, shadchanTypeId)}
            </p>
          </div>

          {languages.length > 0 && (
            <div className="content-main-section">
              <h3 className="title">Languages</h3>
              <ul className="comma">
                {languages.map(l => (
                  <li key={l.id}>{l.text}</li>
                ))}
              </ul>
            </div>
          )}
          {specialties.length > 0 && (
            <div className="content-main-section">
              <h3 className="title">Specialties</h3>
              <ul className="comma">
                {specialties.map(s => (
                  <li key={s.id}>{s.text}</li>
                ))}
              </ul>
            </div>
          )}

          {types.length > 0 && (
            <div className="content-main-section">
              <h3 className="title">Type Specialties</h3>
              <ul className="comma">
                {types.map(t => (
                  <li key={t.id}>{t.text}</li>
                ))}
              </ul>
            </div>
          )}

          {ageStart && ageEnd ? (
            <div className="content-main-section">
              <h3 className="title">Age Specialty</h3>
              <div>{`${ageStart} - ${ageEnd}`}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    constants,
    shadchan: { selectedShadchan },
  } = state;
  return {
    shadchan: selectedShadchan,
    constants,
  };
}

export default connect(mapStateToProps)(ShadchanInfo);
